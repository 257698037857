import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { Alert, Snackbar, useMediaQuery } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { fetchContactUs } from "../../data/contact-us/contactUsSlice";
import ReCAPTCHA from "react-google-recaptcha";
import { Spinner } from "react-bootstrap";

SwiperCore.use([Navigation]);
const ContactUs = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const [captchaValue, setCaptchaValue] = useState("");

  const dispatch = useDispatch();

  const language = useSelector((state) => state.language.language);
  const { contactUs, loading, error } = useSelector((state) => state.contactUs);

  useEffect(() => {
    dispatch(fetchContactUs(language));
  }, [dispatch, language]);
  const [formData, setFormData] = useState({
    customerName: "",
    customerEmail: "",
    customerPhone: "",
    customermessage: "",
    namehoney: "", // Clear honey pot fields after submission
    emailhoney: "",
  });
  const [loadingFormData, setLoadingFormData] = useState(false);
  const [errorFormDate, setErrorFormDate] = useState("");
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!recaptchaToken) {
      setErrorFormDate("Please complete the reCAPTCHA.");
      setOpen(true);
      return;
    }

    setLoadingFormData(true);
    setErrorFormDate("");
    setSuccess(false);

    try {
      const response = await fetch("https://api.travco.com/OpContactUsBranch", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerName: formData.customerName,
          emailAddress: formData.customerEmail,
          phoneNumber: formData.customerPhone,
          message: formData.customermessage,
          branchID: contactUs.contactDetails?.officeId,
          namehoney: formData.namehoney, // Add if necessary
          emailhoney: formData.emailhoney, // Add if necessary
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // const responseText = await response.text();
      // let result;
      // try {
      //   result = JSON.parse(responseText);
      // } catch (e) {
      //   throw new Error("Failed to parse response as JSON");
      // }

      setSuccess(true);
      setOpen(true);
    } catch (error) {
      console.error("Error:", error);
      setErrorFormDate(error.message); // Ensure the error is a string
      setOpen(true);
      setSuccess(false);
    } finally {
      setLoadingFormData(false);
      setFormData({
        customerName: "",
        customerEmail: "",
        customerPhone: "",
        customermessage: "",
        namehoney: "", // Clear honey pot fields after submission
        emailhoney: "",
      });
    }
  };
  return (
    <>
      <Helmet>
        <title>{contactUs.pageDetails?.pageMetatagTitle}</title>
        <link
          rel="canonical"
          href={`https://travco.com/${language}/contact-us`}
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content={contactUs.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={contactUs.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={contactUs.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={contactUs.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={contactUs.pageDetails?.metatagDescriptionTwitter}
        />
      </Helmet>{" "}
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>Loading...</div>}>
                    {" "}
                    <img
                      src={
                        isMobile
                          ? contactUs.pageDetails?.pageBannerMobile
                          : isTablet
                          ? contactUs.pageDetails?.pageBannerTablet
                          : contactUs.pageDetails?.pageBannerPc
                      }
                      alt={contactUs.pageDetails?.altBanner}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className="rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {contactUs.pageDetails?.pageTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="rlr-search-results-page__breadcrumb-section">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb rlr-breadcrumb__items">
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/">Home </Link>
              </li>
              <li
                className="breadcrumb-item rlr-breadcrumb__item active"
                aria-current="page"
              >
                {contactUs.pageDetails?.pageTitle}
              </li>
            </ol>
          </nav>
          <div className="rlr-icon-text">
            <i className="rlr-icon-font flaticon-phone-receiver-silhouette">
              {" "}
            </i>{" "}
            <Link
              to="tel:(+202) 3854 1010"
              className="rlr-search-results-page__phone"
            >
              Questions? (+202) 3854 1010{" "}
            </Link>
          </div>
        </div>
      </div>
      <section className="branches">
        <div className="container">
          <aside className="row rlr-search-results-page__dynamic-filter-section px-5">
            <Swiper
              className="rlr-dynamic-filter-swiper rlr-js-dynamic-filter-swiper w-auto"
              slidesPerView={6}
              spaceBetween={20}
              breakpoints={{
                // when window width is >= 320px (mobile screens)
                320: {
                  slidesPerView: 1,
                  spaceBetween: 50,
                },
                // when window width is >= 768px (tablet screens)
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                // when window width is >= 1024px (desktop screens)
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 60,
                },
                1200: {
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
              }}
              loop={false}
              navigation={{
                prevEl: ".splide__arrow--prev",
                nextEl: ".splide__arrow--next",
              }}
            >
              {contactUs.contactUsList?.map((branch) => (
                <SwiperSlide
                  className="rlr-search-results-page__dynamic-filter-item text-center w-lg-auto"
                  key={branch.officePageUrl}
                >
                  <Link
                    className="rlr-icon-text btn rlr-icon-text__card rlr-icon-text--anchor"
                    to={`/branches/${branch.officePageUrl}`}
                  >
                    <span className="rlr-icon-text__card-title">
                      {branch.officeName}
                    </span>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
            <button
              type="button"
              className="btn rlr-button splide__arrow splide__arrow--prev"
            >
              <i className="rlr-icon-font flaticon-left-chevron"></i>
            </button>
            <button
              type="button"
              className="btn rlr-button splide__arrow splide__arrow--next"
            >
              <i className="rlr-icon-font flaticon-chevron"></i>
            </button>
          </aside>
        </div>
      </section>
      <section
        className="contact-bg-section bg_cover pt-50 pb-50"
        style={{
          backgroundImage: "url(../../images/uploads/contact-bg-1.png)",
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-7 col-lg-12">
              <div className="contact-content-box mb-50">
                <div
                  className="section-title mb-45 wow fadeInUp"
                  style={{ visibility: "visible", animationName: "fadeInUp" }}
                >
                  <h2>Cairo Head Office</h2>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className="contact-icon-box mb-50 wow fadeInDown"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInDown",
                      }}
                    >
                      <div className="icon">
                        <i className="rlr-icon-font flaticon-carbon-location"></i>
                      </div>
                      <div className="text">
                        <h4 className="title">Location</h4>
                        <p>{contactUs.contactDetails?.officeAddress}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="contact-icon-box mb-50 wow fadeInDown"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInDown",
                      }}
                    >
                      <div className="icon">
                        <i className="rlr-icon-font flaticon-email"></i>
                      </div>
                      <div className="text">
                        <h4 className="title">Email Us</h4>
                        <p>
                          <Link
                            to={`mailto:${contactUs.contactDetails?.officeEmail}`}
                          >
                            {contactUs.contactDetails?.officeEmail}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="contact-icon-box mb-50 wow fadeInDown"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInDown",
                      }}
                    >
                      <div className="icon">
                        <i className="rlr-icon-font flaticon-telephone"></i>
                      </div>
                      <div className="text">
                        <h4 className="title">Phone</h4>
                        <p>
                          <Link
                            to={`tel:${contactUs.contactDetails?.officeTel}`}
                          >
                            {contactUs.contactDetails?.officeTel}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-12">
              <div className="contact-form-wrapper mb-50">
                <h3 className="title">Send Us Message</h3>
                <p>
                  Do you have anything your mind to tell? Please don't hesitate
                  to in touch to us our contact form.
                </p>
                <form onSubmit={handleSubmit} id="form_contactus">
                  <label className="ohnohoney" htmlFor="namehoney"></label>
                  <input
                    tabIndex="-1"
                    className="ohnohoney"
                    autoComplete="off"
                    type="text"
                    id="namehoney"
                    name="namehoney"
                    placeholder="Your name here"
                    value={formData.namehoney}
                    onChange={handleInputChange}
                  />
                  <label className="ohnohoney" htmlFor="emailhoney"></label>
                  <input
                    tabIndex="-1"
                    className="ohnohoney"
                    autoComplete="off"
                    type="email"
                    id="emailhoney"
                    name="emailhoney"
                    placeholder="Your e-mail here"
                    value={formData.emailhoney}
                    onChange={handleInputChange}
                  />
                  <div className="form_group">
                    <input
                      type="text"
                      className="form_control"
                      placeholder="Full Name"
                      name="customerName"
                      required
                      value={formData.customerName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form_group">
                    <input
                      type="email"
                      className="form_control"
                      placeholder="Email Address"
                      name="customerEmail"
                      required
                      value={formData.customerEmail}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form_group">
                    <input
                      type="text"
                      className="form_control"
                      placeholder="Mobile"
                      name="customerPhone"
                      required
                      value={formData.customerPhone}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form_group">
                    <textarea
                      className="form_control"
                      placeholder="Write Message"
                      name="customermessage"
                      value={formData.customermessage}
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                  <div
                    id="divcaptcha"
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      margin: "20px 0 20px 0",
                    }}
                  >
                    <ReCAPTCHA
                      sitekey="6LdVcwgqAAAAAJzoiaHWcn_dmaNoCCn3_7ActZXJ" // Use the correct site key here
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  <div className="form_group">
                    <button className="main-btn btn-green" type="submit">
                      {loadingFormData ? (
                        <>
                          <Spinner
                            animation="border"
                            variant="light"
                            size="sm"
                          />{" "}
                          Loading ...
                        </>
                      ) : (
                        "GET IN TOUCH"
                      )}
                    </button>
                  </div>
                </form>
                {!loadingFormData && success && (
                  <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                  >
                    <Alert
                      onClose={handleClose}
                      severity="success"
                      variant="filled"
                      sx={{ width: "100%" }}
                    >
                      Your Request Sent Successfully To{" "}
                      {contactUs.contactDetails?.officeName}
                    </Alert>
                  </Snackbar>
                )}{" "}
                {errorFormDate && (
                  <Snackbar
                    open={open}
                    autoHideDuration={4000}
                    onClose={handleClose}
                  >
                    <Alert
                      onClose={handleClose}
                      severity="danger"
                      variant="filled"
                      sx={{ width: "100%" }}
                    >
                      {errorFormDate}
                    </Alert>
                  </Snackbar>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="contact-page-map pb-0 wow fadeInUp"
        style={{ visibility: "visible", animationName: "fadeInUp" }}
      >
        <div className="container-fluid">
          <div className="map-box">
            <iframe
              src={contactUs.contactDetails?.officeGooglelocation}
              width="600"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title={`${contactUs.contactDetails?.destinationName} Map`}
            ></iframe>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
