import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop.jsx";
// import "react-image-lightbox/style.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { useEffect } from "react";
import Layout from "./components/Layout.jsx";
import Home from "./pages/Home.jsx";
import Hotels from "./pages/hotels/Hotels.jsx";
import AboutUs from "./pages/about-us/AboutUs.jsx";
import HotelAvailability from "./pages/hotels/HotelAvailability.jsx";
import RoomDetails from "./pages/hotels/RoomDetails.jsx";
import PageTracker from "./components/PageTracker.js";
import HotelBookingReview from "./pages/hotels/HotelBookingReview.jsx";
import News from "./pages/news/News.jsx";
import NewsDetails from "./pages/news/NewsDetails.jsx";
import InternationalOffices from "./pages/international-offices/InternationalOffices.jsx";
import AffiliatedCompanies from "./pages/affiliated-companies/AffiliatedCompanies.jsx";
import AffiliatedCompaniesDetails from "./pages/affiliated-companies/AffiliatedCompaniesDetails.jsx";
import Testimonials from "./pages/testimonials/Testimonials.jsx";
import Brochures from "./pages/brochures/Brochures.jsx";
import DestinationGuide from "./pages/destination-guide/DestinationGuide.jsx";
import DestinationGuideDetails from "./pages/destination-guide/DestinationGuideDetails.jsx";
import TermsAndConditions from "./pages/terms-and-conditions/TermsAndConditions.jsx";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy.jsx";
import DestinationHandbook from "./pages/destination-handbook/DestinationHandbook.jsx";
import DestinationHandbookDetails from "./pages/destination-handbook/DestinationHandbookDetails.jsx";
import Mice from "./pages/mice/Mice.jsx";
import MiceDetails from "./pages/mice/MiceDetails.jsx";
import ContactUs from "./pages/contact-us/ContactUs.jsx";
import Branches from "./pages/branches/Branches.jsx";
import BrancheDetails from "./pages/branches/BrancheDetails.jsx";
import Visa from "./pages/visa/Visa.jsx";
import BeOurPartner from "./pages/be-our-partner/BeOurPartner.jsx";
import Service from "./pages/about-us/Service.jsx";
import Destinations from "./pages/destinations/Destinations.jsx";
import TravelIdea from "./pages/travel-idea/TravelIdea.jsx";
import DestinationEgypt from "./pages/destinations/DestinationEgypt.jsx";
import Packages from "./pages/packages/Packages.jsx";
import PackageDetails from "./pages/packages/PackageDetails.jsx";
import BookingPackage from "./pages/packages/BookingPackage.jsx";
import Tours from "./pages/tours/Tours.jsx";
import TourDetails from "./pages/tours/TourDetails.jsx";
import BookingTour from "./pages/tours/BookingTour.jsx";
import Transfers from "./pages/transfers/Transfers.jsx";
import TransferDetails from "./pages/transfers/TransferDetails.jsx";
import BookingTransfer from "./pages/transfers/BookingTransfer.jsx";
import Cruises from "./pages/cruises/Cruises.jsx";
import CruisesBooking from "./pages/cruises/CruisesBooking.jsx";
import CruiseDetails from "./pages/cruises/CruiseDetails.jsx";
import CabinsBooking from "./pages/cruises/CabinsBooking.jsx";
import Checkout from "./pages/cruises/Checkout.jsx";
import TravelIdeaExplore from "./pages/travel-idea/TravelIdeaExplore.jsx";
import TransfersSearchWidget from "./pages/transfers/TransfersSearchWidget.jsx";
import PackagesSearchWidget from "./pages/packages/PackagesSearchWidget.jsx";
import ToursSearchWidget from "./pages/tours/ToursSearchWidget.jsx";
import EntryVisa from "./pages/visa/EntryVisa.jsx";
import Login from "./pages/Login/Login.jsx";
import SignUp from "./pages/SignUp/SignUp.jsx";
import ResetPassword from "./pages/ResetPassword/ResetPassword.jsx";
import Profile from "./pages/Profile/Profile.jsx";
import Orders from "./pages/orders/Orders.jsx";
import ThanksTour from "./pages/tours/ThanksTour.jsx";
import ThanksPackage from "./pages/packages/ThanksPackage.jsx";
import ThanksHotel from "./pages/hotels/ThanksHotel.jsx";
import ThanksTransfer from "./pages/transfers/ThanksTransfer.jsx";
import ThanksCruise from "./pages/cruises/ThanksCruise.jsx";
import ThanksVisa from "./pages/visa/ThanksVisa.jsx";
import PackagesOrders from "./pages/orders/PackagesOrders.jsx";
import CruisesOrders from "./pages/orders/CruisesOrders.jsx";
import NotFound from "./pages/NotFound.jsx";
import ToursOrders from "./pages/orders/ToursOrders.jsx";
import TransfersOrders from "./pages/orders/TransfersOrders.jsx";
import VisaOrders from "./pages/orders/VisaOrders.jsx";
import HotelsOrders from "./pages/orders/HotelsOrders.jsx";
import { setLanguage } from "./data/languageSlice.js";
import { useDispatch, useSelector } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
// import TemporaryLinks from "./components/TemporaryLinks";
function App() {
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   const currency = localStorage.getItem("currency");
  //   if (!currency) {
  //     localStorage.setItem("currency", "USD");
  //   }

  //   const currentUrl = new URL(window.location.href);

  //   if (!currentUrl.pathname.includes("/en")) {
  //     window.location.href = `${currentUrl.origin}/en${currentUrl.pathname}${currentUrl.search}`;
  //   }
  // }, []);
  const language = useSelector((state) => state.language.language);
  const [basename, setBasename] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const pathSegments = currentUrl.pathname.split("/").filter(Boolean); // Split and remove empty segments

    // if (
    //   pathSegments.length > 0 &&
    //   (pathSegments[0] === "en" ||
    //     pathSegments[0] === "ar" ||
    //     pathSegments[0] === "es" ||
    //     pathSegments[0] === "pt" ||
    //     pathSegments[0] === "ru")
    // ) {
    //   dispatch(setLanguage(pathSegments[0]));
    //   setBasename(pathSegments[0]);
    // }
    if (
      pathSegments.length > 0 &&
      ["en", "ar", "es", "pt", "ru"].includes(pathSegments[0])
    ) {
      dispatch(setLanguage(pathSegments[0]));
      setBasename(pathSegments[0]);
    } else {
      window.location.href = `${currentUrl.origin}/${language}${currentUrl.pathname}${currentUrl.search}`;
    }
    const head = document.head;
    const existingLink = document.getElementById("rtl-stylesheet");

    if (language === "ar") {
      if (!existingLink) {
        const link = document.createElement("link");
        link.id = "rtl-stylesheet"; // Assign an ID for easy access
        link.type = "text/css";
        link.rel = "stylesheet";
        link.href = "bootstrap-rtl.min.css";
        head.appendChild(link);
      }
    } else if (language === "ru") {
      if (!existingLink) {
        const link = document.createElement("link");
        link.id = "rtl-stylesheet"; // Assign an ID for easy access
        link.type = "text/css";
        link.rel = "stylesheet";
        link.href = "ru.css";
        head.appendChild(link);
      }
    } else {
      if (existingLink) {
        head.removeChild(existingLink);
      }
    }
  }, [language, dispatch]);
  return (
    <HelmetProvider>
      <Router basename={basename}>
        {/* <TemporaryLinks /> */}
        <PageTracker />
        <ScrollToTop />

        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="hotels-destinations" element={<Hotels />} />
            <Route path="about-travco-travel" element={<AboutUs />} />
            <Route path="services/:sevicesUrl" element={<Service />} />
            <Route path="destination/egypt" element={<Destinations />} />
            <Route
              path="destination/egypt/:DestinationURL"
              element={<DestinationEgypt />}
            />
            <Route path="travel-idea" element={<TravelIdea />} />
            <Route
              path="travel-idea/:StyleURL"
              element={<TravelIdeaExplore />}
            />
            <Route path="travco-news" element={<News />} />
            <Route path="travco-news/:newsUrl" element={<NewsDetails />} />
            <Route
              path="international-offices"
              element={<InternationalOffices />}
            />
            <Route
              path="affiliated-companies"
              element={<AffiliatedCompanies />}
            />
            <Route
              path="affiliated-companies/:affiliatedCompaniesUrl"
              element={<AffiliatedCompaniesDetails />}
            />
            <Route path="brochures" element={<Brochures />} />
            <Route path="testimonials" element={<Testimonials />} />
            <Route path="destination-guide" element={<DestinationGuide />} />
            <Route
              path="destination-guide/:destinationUrl"
              element={<DestinationGuideDetails />}
            />
            <Route
              path="destination-handbook"
              element={<DestinationHandbook />}
            />
            <Route
              path="destination-handbook/:itemUrl"
              element={<DestinationHandbookDetails />}
            />
            <Route path="mice" element={<Mice />} />
            <Route path="mice/:serviceUrl" element={<MiceDetails />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="branches" element={<Branches />} />
            <Route
              path="branches/:OfficePageUrl"
              element={<BrancheDetails />}
            />
            <Route path="visa" element={<Visa />} />
            <Route path="entry-visa" element={<EntryVisa />} />
            <Route path="Visaconfirmation/:orderId" element={<ThanksVisa />} />
            <Route path="be-our-partner" element={<BeOurPartner />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="website-terms" element={<TermsAndConditions />} />
            <Route path="nile-cruises" element={<Cruises />} />
            <Route path="nile-cruises/:cruiseUrl" element={<CruiseDetails />} />
            <Route path="cruisesbooking" element={<CruisesBooking />} />
            <Route
              path=":cruiseUrl/cabinsbooking/:opid"
              element={<CabinsBooking />}
            />
            <Route
              path=":cruiseUrl/booking/:OPPriceID"
              element={<Checkout />}
            />
            <Route
              path="ThanksCruisesConfirmation/:orderId"
              element={<ThanksCruise />}
            />
            <Route
              path="orderNileCruisesDetails/:orderId"
              element={<CruisesOrders />}
            />
            <Route path="packages" element={<Packages />} />
            <Route path="search/packages" element={<PackagesSearchWidget />} />
            <Route path="packages/:productUrl" element={<PackageDetails />} />
            <Route
              path="packages/:productUrl/bookingPackage"
              element={<BookingPackage />}
            />
            <Route
              path="ThanksPackageConfirmation/:orderId"
              element={<ThanksPackage />}
            />
            <Route
              path="orderPackageDetails/:orderId"
              element={<PackagesOrders />}
            />
            <Route path="tours-activities" element={<Tours />} />
            <Route
              path="search/tours-activities"
              element={<ToursSearchWidget />}
            />
            <Route path="tour/:productUrl" element={<TourDetails />} />
            <Route
              path="tour/:productUrl/bookingTour"
              element={<BookingTour />}
            />
            <Route
              path="ThanksTourConfirmation/:orderId"
              element={<ThanksTour />}
            />
            <Route
              path="transfers-and-airport-services"
              element={<Transfers />}
            />
            <Route
              path="search/transfers-and-airport-services"
              element={<TransfersSearchWidget />}
            />
            <Route path="transfers/:productUrl" element={<TransferDetails />} />
            <Route
              path="transfers/:productUrl/bookingTransfer"
              element={<BookingTransfer />}
            />
            <Route
              path="ThanksTransferConfirmation/:orderId"
              element={<ThanksTransfer />}
            />
            <Route path="hotels-availability" element={<HotelAvailability />} />
            <Route
              path="hotels-availability/:txt_var_HotelName/:var_HotelAddress/:var_HotelJPCode"
              element={<RoomDetails />}
            />
            <Route
              path="HotelBookingReview/:BookingId"
              element={<HotelBookingReview />}
            />
            <Route
              path="hotelconfirmation/:orderId"
              element={<ThanksHotel />}
            />
            <Route path="myorders" element={<Orders />} />
            <Route path="orderTourDetails/:orderId" element={<ToursOrders />} />
            <Route
              path="orderTransferDetails/:orderId"
              element={<TransfersOrders />}
            />
            <Route path="orderVisaDetails/:orderId" element={<VisaOrders />} />
            <Route
              path="orderHotelDetails/:orderId"
              element={<HotelsOrders />}
            />
            <Route path="login" element={<Login />} />
            <Route path="sign-up" element={<SignUp />} />
            <Route path="EditProfilePage" element={<Profile />} />
            <Route path="reset-password" element={<ResetPassword />} />{" "}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
