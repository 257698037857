import React, { Suspense, useEffect } from "react";
// import styles from "./css/terms-and-conditions.module.css";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { fetchPrivacy } from "../../data/privacy-policy/privacySlice";

const PrivacyPolicy = () => {
  const dispatch = useDispatch();

  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const language = useSelector((state) => state.language.language);

  const { privacy, loading, error } = useSelector((state) => state.privacy);
  useEffect(() => {
    dispatch(fetchPrivacy(language));
  }, [dispatch, language]);
  return (
    // <>
    //   {loading ? (
    //     <Loader />
    //   ) : error ? (
    //     <section
    //       style={{ backgroundColor: "#e60000", padding: "200px 0 100px" }}
    //     >
    //       <h1 className="text-center text-light">{error}</h1>
    //     </section>
    //   ) : (
    <>
      <Helmet>
        <title>{privacy?.pageMetatagTitle || "privacy policy"}</title>
        <link
          rel="canonical"
          href={`https://travco.com/${language}/privacy-policy`}
        />{" "}
        <meta name="robots" content="index, follow" />
        <meta name="description" content={privacy?.pageMetatagDescription} />
        <meta property="og:title" content={privacy?.metatagTitleFacebook} />
        <meta
          property="og:description"
          content={privacy?.metatagDescriptionFacebook}
        />
        <meta name="twitter:title" content={privacy?.metatagTitleTwitter} />
        <meta
          name="twitter:description"
          content={privacy?.metatagDescriptionTwitter}
        />
        <link rel="stylesheet" href="css/news.css" />
      </Helmet>
      <section>
        {" "}
        <div className="outter">
          <div
            id="rlr_banner_slider"
            className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
          >
            <div className="splide__track rlr-banner-splide__track">
              <ul className="splide__list height-internal-pages">
                <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                  <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                    <div className="overlay-banner"></div>
                    <Suspense fallback={<div>Loading...</div>}>
                      {" "}
                      <img
                        className="rlr-banner-splide__banner-img"
                        src={
                          isMobile
                            ? privacy?.pageBannerMobile
                            : isTablet
                            ? privacy?.pageBannerTablet
                            : privacy?.pageBannerPc
                        }
                        alt={privacy?.pageTitle}
                        loading="lazy"
                      />
                    </Suspense>
                  </div>
                  <article className="rlr-banner-splide__content-wrapper container">
                    <div className="row justify-content-start">
                      <div className="col-xl-9">
                        <div className="rlr-banner-splide__header">
                          <h2
                            className="rlr-banner-splide__slogan rlr-section__title--main"
                            style={{ textShadow: "1px 1px 2px #000" }}
                          >
                            {privacy?.pageTitle}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </article>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="rlr-search-results-page__breadcrumb-section">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb rlr-breadcrumb__items">
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/">Home </Link>
              </li>
              <li
                className="breadcrumb-item rlr-breadcrumb__item active"
                aria-current="page"
              >
                {privacy?.pageTitle}
              </li>
            </ol>
          </nav>
          <div className="rlr-icon-text">
            <i className="rlr-icon-font flaticon-phone-receiver-silhouette">
              {" "}
            </i>{" "}
            <Link
              to="tel:(+202) 3854 1010"
              className="rlr-search-results-page__phone"
            >
              Questions? (+202) 3854 1010{" "}
            </Link>
          </div>
        </div>
      </div>
      <section className="first-section   " id="terms">
        <Container>
          <div className="page_content_wrap scheme_default">
            <div
              className="content_wrap   "
              dangerouslySetInnerHTML={{
                __html: `${privacy?.pageText}`,
              }}
            />
          </div>
        </Container>
      </section>
      {/* </>
      )} */}
    </>
  );
};
export default PrivacyPolicy;
