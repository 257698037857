import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery } from "@mui/material";
import React from "react";

const BookingStepForProducts = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  return (
    <section className="payment-area pt-5 pt-lg-10 mt-5  my-lg-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="form-box payment-received-wrap mb-0">
              <div className="form-title-wrap">
                <div className="step-bar-wrap text-center">
                  <ul
                    className="step-bar-list d-flex align-items-center justify-content-around"
                    style={{ liststyle: "none" }}
                  >
                    <li className="step-bar flex-grow-1 step-bar-active ">
                      <span className="icon-element">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                      <p className="pt-2 color-text-2">Choose Package</p>
                    </li>

                    <li className="step-bar flex-grow-1 step-bar-active">
                      <span className="icon-element">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                      <p className="pt-2 color-text-2">Personal Information </p>
                    </li>

                    <li className="step-bar flex-grow-1">
                      <span className="icon-element">3 </span>
                      <p className="pt-2 color-text-2">Request Completed! </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BookingStepForProducts;
