import React, { useEffect, useState } from "react";
// import { Container, ProgressBar } from "react-bootstrap";

const LoaderHotels = () => {
  // const [now, setNow] = useState(0);

  // useEffect(() => {
  //   now <= 95 && setTimeout(() => setNow(now + 10), [100]);
  // }, [now]);

  return (
    <>
      {/* <div style={{ height: "100vh" }} className="d-flex align-items-center ">
        <div className="container mt-5 text-center">
    
          <div
            className="loader d-flex justify-content-center align-items-center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ zIndex: "9999999", position: "fixed" }}>
              {" "}
              <img
                alt="loader"
                src="Spin.gif"
                width="100"
               
                style={{ filter: "brightness(0.7)" }}
              />
               
            </div>
          </div>
           
        </div>
          </div>*/}
      {/* <div id="preloader">
        <div id="preloader-status">
          <div className="preloader-position loader">
            <img src="images/uploads/loader.gif" alt="loader" />
          </div>
        </div>
      </div> */}

      <div
        className="loader d-flex justify-content-center align-items-center"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ zIndex: "9", position: "relative", top: "0vh" }}>
          {" "}
          <img
            alt="loader"
            src="loading.gif"

            // style={{ filter: "brightness(0.7)" }}
          />
        </div>
      </div>
      <p className="fs-5 mt-3 text-center text-secondary">
        We are searching for the best price for Hotels
      </p>
    </>
  );
};

export default LoaderHotels;
