import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
import { fetchCruisesOrders } from "../../data/orders/cruisesOrdersSlice";

const CruisesOrders = () => {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const language = useSelector((state) => state.language.language);
  const { cruisesOrders, loading, error } = useSelector(
    (state) => state.cruisesOrders
  );

  useEffect(() => {
    dispatch(fetchCruisesOrders({ orderId, language }));
  }, [orderId, dispatch, language]);

  return (
    <>
      <Helmet>
        <title>{cruisesOrders.pageDetails?.pageTitle || "Cruise Order"}</title>
        <link
          rel="canonical"
          href={`https://travco.com/${language}/orderNileCruisesDetails/${orderId}`}
        />
        <meta
          name="description"
          content={cruisesOrders.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={cruisesOrders.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={cruisesOrders.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={cruisesOrders.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={cruisesOrders.pageDetails?.metatagDescriptionTwitter}
        />
        <style type="text/css">
          {`

          .form-control{
          height:50px;
          border-color:#0000003b;
          }
             .form-v8-content .form-row .form-row-inner .label {
        position: absolute;
        top: -25px;
      
    }
    .form-v8-content .form-detail .form-row .input-text:focus + .label, .form-v8-content .form-detail .form-row .input-text:valid + .label {
        -webkit-transform: translateY(-10px) scale(1);
    }
    #register, #login {
        display: flex;
        justify-content: space-between !important;
        flex-wrap: wrap !important;
    }
        .react-datepicker-wrapper{
        position:relative;
        width:100%;
        }
        .input-group-prepend span{
        position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
        }
    .react-datepicker-popper{
    z-index: 100}
   @media screen and (min-width:767px){
    .w-45 {
        width: 45% !important;
    }}
        .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
        border-color:#e60000 !important;
        }

   

          `}
        </style>
        <link
          href="TITSolutions/WS/assets/login-booking/css/sourcesanspro-font.css"
          rel="stylesheet"
        />
        <link
          href="TITSolutions/WS/assets/login-booking/css/style.css"
          rel="stylesheet"
        />
        <link
          href="TITSolutions/WS/assets/line-awesome-3.css"
          rel="stylesheet"
        ></link>
      </Helmet>
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>Loading...</div>}>
                    <img
                      src={
                        isMobile
                          ? cruisesOrders?.pageDetails?.pageBannerMobile
                          : isTablet
                          ? cruisesOrders?.pageDetails?.pageBannerTablet
                          : cruisesOrders?.pageDetails?.pageBannerPc
                      }
                      alt={cruisesOrders?.pageDetails?.pageTitle}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="mt-5 pt-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="mb-5 shadow-soft bg-white rounded-sm">
                <div className="pt-4 pb-5 px-5 border-bottom">
                  <h5
                    id="scroll-description"
                    className="font-size-21 font-weight-bold text-dark mb-2"
                  >
                    Flight Information
                  </h5>

                  <ul className="list-unstyled font-size-1 mb-0 font-size-16">
                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">Origin </span>
                      <span className="text-secondary text-right">
                        {cruisesOrders.cruiseOrder?.originName}
                      </span>
                    </li>

                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">
                        Arrival Airline{" "}
                      </span>
                      <span className="text-secondary text-right">
                        {cruisesOrders.cruiseOrder?.airlineName}
                      </span>
                    </li>

                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">
                        Arrival Flight No
                      </span>
                      <span className="text-secondary text-right">
                        {cruisesOrders.cruiseOrder?.arrivalFlightNumber}
                      </span>
                    </li>

                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">Arrival Date</span>
                      <span className="text-secondary text-right">
                        {cruisesOrders.cruiseOrder?.arrivalDate}
                      </span>
                    </li>
                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">Arrival Time</span>
                      <span className="text-secondary text-right">
                        {cruisesOrders.cruiseOrder?.arrivalTime}
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="pt-4 pb-5 px-5 border-bottom">
                  <h5
                    id="scroll-description"
                    className="font-size-21 font-weight-bold text-dark mb-2"
                  >
                    Traveler Information
                  </h5>

                  <ul className="list-unstyled font-size-1 mb-0 font-size-16">
                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">
                        Booking number{" "}
                      </span>
                      <span className="text-secondary text-right">
                        {cruisesOrders.cruiseOrder?.payTabCartId}
                      </span>
                    </li>

                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">First name </span>
                      <span className="text-secondary text-right">
                        {cruisesOrders.cruiseOrder?.customerFirstName}
                      </span>
                    </li>

                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">Last name </span>
                      <span className="text-secondary text-right">
                        {cruisesOrders.cruiseOrder?.customerLastName}
                      </span>
                    </li>

                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">
                        E-mail address{" "}
                      </span>
                      <span className="text-secondary text-right">
                        {cruisesOrders.cruiseOrder?.customerEmailAddress}
                      </span>
                    </li>
                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">Mobile </span>
                      <span className="text-secondary text-right">
                        {cruisesOrders.cruiseOrder?.customerMobile}
                      </span>
                    </li>

                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">Nationality </span>
                      <span className="text-secondary text-right">
                        {cruisesOrders.cruiseOrder?.nationalityName}
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="pt-4 pb-5 px-5 border-bottom">
                  <h5
                    id="scroll-description"
                    className="font-size-21 font-weight-bold text-dark mb-2"
                  >
                    Booking Information
                  </h5>

                  <ul className="list-unstyled font-size-1 mb-0 font-size-16">
                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">Cruise Name </span>
                      <span className="text-secondary text-right">
                        {cruisesOrders.cruiseOrder?.productName}
                      </span>
                    </li>

                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">Cabin </span>
                      <span className="text-secondary text-right">
                        {cruisesOrders.cruiseOrder?.cabinName}
                      </span>
                    </li>

                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">
                        Itinarary Route{" "}
                      </span>
                      <span className="text-secondary text-right">
                        {cruisesOrders.cruiseOrder?.itinararyRoute}
                      </span>
                    </li>
                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">
                        Itinarary Duration{" "}
                      </span>
                      <span className="text-secondary text-right">
                        {cruisesOrders.cruiseOrder?.itinararyDuration}
                      </span>
                    </li>
                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">Check In </span>
                      <span className="text-secondary text-right">
                        {cruisesOrders.cruiseOrder?.orderTripDate}
                      </span>
                    </li>
                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">Check Out</span>
                      <span className="text-secondary text-right">
                        {cruisesOrders.cruiseOrder?.checkOut}
                      </span>
                    </li>
                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">
                        Number of Adults
                      </span>
                      <span className="text-secondary text-right">
                        {cruisesOrders.cruiseOrder?.adultNo}
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="pt-4 pb-5 px-5 border-bottom">
                  <h5
                    id="scroll-description"
                    className="font-size-21 font-weight-bold text-dark mb-2"
                  >
                    Payment Information
                  </h5>

                  <ul className="list-unstyled font-size-1 mb-0 font-size-16">
                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">
                        Transaction Refrence{" "}
                      </span>
                      <span className="text-secondary text-right">
                        {cruisesOrders.cruiseOrder?.payTabTranRef}
                      </span>
                    </li>

                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">Total Amount: </span>

                      <span className="text-secondary text-right">
                        {" "}
                        {cruisesOrders.cruiseOrder?.payTabCartAmount}{" "}
                        {cruisesOrders.cruiseOrder?.payTabCartCurrency}
                      </span>
                    </li>

                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">Card </span>
                      <span className="text-secondary text-right">
                        {cruisesOrders.cruiseOrder?.payTabPaymentInfoCardScheme}{" "}
                        (
                        {
                          cruisesOrders.cruiseOrder
                            ?.payTabPaymentInfoPaymentDescription
                        }
                        )
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CruisesOrders;
