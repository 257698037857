import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faClose,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Button, Spinner } from "react-bootstrap";

import { Link, useNavigate, useParams } from "react-router-dom";

import { Airports_Options as countries } from "../../components/airports";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { VariableSizeList } from "react-window";
import { useTheme } from "@mui/material/styles";

import ScrollToTop from "../../ScrollToTop";
import LoginAndRegisterForm from "../../components/LoginAndRegisterForm";
import { fetchEntryVisa } from "../../data/visa/entryVisaSlice";
SwiperCore.use([Navigation, Pagination, Autoplay]);
const EntryVisa = () => {
  const userToken = localStorage.getItem("userToken");
  const googleAccessToken = localStorage.getItem("googleAccessToken");
  const loginToken = localStorage.getItem("accessToken");
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const currency = useSelector((state) => state.currency.currency);
  const pageLanguage = useSelector((state) => state.language.language);

  const { entryVisa, loadingBooking, errorBooking } = useSelector(
    (state) => state.entryVisa
  );

  useEffect(() => {
    dispatch(fetchEntryVisa(pageLanguage));
  }, [dispatch, pageLanguage]);

  const LISTBOX_PADDING = 8; // px

  function renderRow(props) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
      style: {
        ...style,
        top: style.top + LISTBOX_PADDING,
      },
    });
  }

  const OuterElementContext = React.createContext({});

  const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
  });

  function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
      }
    }, [data]);
    return ref;
  }

  const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref
  ) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const itemCount = itemData.length;
    const itemSize = isMobile || isTablet ? "100%" : 36;
    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.length * itemSize;
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight()}
            style={{
              width: `${isMobile || isTablet ? `100%` : `30vw`}`,
              position: "absolute",
              backgroundColor: "#FFF",
              boxShadow: "0 0 1px 2px #eee",
              borderRadius: "0 0 10px 10px",
            }}
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={() => itemSize}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  });
  const { profile } = useSelector((state) => state.profile);

  const [customerFirstName, setCustomerFirstName] = useState(
    profile.userInfo?.customerFirstName || ""
  );
  const [customerLastName, setCustomerLastName] = useState(
    profile.userInfo?.customerLastName || ""
  );
  const [customerEmailAddress, setCustomerEmailAddress] = useState(
    profile.userInfo?.customerEmailAddress || ""
  );
  const [customerMobile, setCustomerMobile] = useState(
    profile.userInfo?.customerMobile || ""
  );
  const [customerNationalityCode, setCustomerNationalityCode] = useState("");
  const [originName, setOriginName] = useState("Select");
  const handleCountryChange = (event, newValue) => {
    setOriginName(newValue);
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };
  const [arrivalFlightNumber, setArrivalFlightNumber] = useState("");
  const [arrivalAirline, setArrivalAirline] = useState("");
  const [startArrivalDate, setStartArrivalDate] = useState();
  const [arrivalTime, setArrivalTime] = useState("");
  const [airport, setAirport] = useState("");

  const [customerSpecialRequest, setCustomerSpecialRequest] = useState("");
  const [passengers, setPassengers] = useState([]);
  const [name, setName] = useState("");
  const [nationality, setNationality] = useState("");
  const [language, setLanguage] = useState(0);
  const [passportNumber, setPassportNumber] = useState("");
  const [birthOfDate, setBirthDate] = useState(new Date());
  const [paymentUrl, setPaymentUrl] = useState("");
  const [success, setSuccess] = useState(false);
  const [loadingFormData, setLoadingFormData] = useState(false);
  const [errorFormDate, setErrorFormDate] = useState("");

  const handleAddPassenger = () => {
    if (name && nationality && passportNumber && birthOfDate) {
      const newPassenger = {
        name,
        nationality,
        passportNumber,
        birthOfDate: formatDate(birthOfDate),
      };
      setPassengers([...passengers, newPassenger]);
    } else {
      alert("Please fill  all Passenger fields");
    }
  };
  const handleDeletePassenger = (index) => {
    const updatedPassengers = passengers.filter((_, i) => i !== index);
    setPassengers(updatedPassengers);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingFormData(true);
    if (passengers.length > 0) {
      const data = {
        arrivalFlightNumber,
        arrivalAirline,
        arrivalDate: formatDate(startArrivalDate), // Format as YYYY-MM-DD
        arrivalTime,
        originName: originName?.label,
        guestName: customerFirstName,
        lastName: customerLastName,
        email: customerEmailAddress,
        nationality: String(customerNationalityCode),
        language, // Assuming you will set this properly

        mobile: customerMobile,
        specialRequirement: customerSpecialRequest,
        airport, // Set the correct value
        currency: currency, // Set the correct value
        entryVisaDetails: passengers,
      };

      try {
        const response = await fetch(
          `https://api.travco.com/apiTITSol/TTG24_Visa/${pageLanguage}/opInsertOrderVisa`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        setLoadingFormData(false);
        setSuccess(result.success);
        setPaymentUrl(result.paymentUrl);

        // Reset form fields and state if needed
      } catch (error) {
        console.error("Error:", error);
        setSuccess(false);
      }
    } else if (passengers.length <= 0) {
      alert("Please Add Passenger");
      setLoadingFormData(false);
    }
  };
  useEffect(() => {
    if (success) {
      window.location.href = paymentUrl;
    }
  }, [paymentUrl, success]);

  return (
    <>
      <Helmet>
        <title>{entryVisa.pageDetails?.pageMetatagTitle || "entry visa"}</title>
        <link
          rel="canonical"
          href={`https://travco.com/${pageLanguage}/entry-visa`}
        />
        <meta name="robots" content="index, follow" />

        <meta
          name="description"
          content={entryVisa.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={entryVisa.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={entryVisa.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={entryVisa.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={entryVisa.pageDetails?.metatagDescriptionTwitter}
        />
        <style type="text/css">
          {`

          .form-control{
          height:60px;
          border-color:#0000003b;
          margin-bottom:20px;
          }
          label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    margin-top: 15px;
    font-weight: 400;
}
             .form-v8-content .form-row .form-row-inner .label {
        position: absolute;
        top: -25px;
      
    }
    .form-v8-content .form-detail .form-row .input-text:focus + .label, .form-v8-content .form-detail .form-row .input-text:valid + .label {
        -webkit-transform: translateY(-10px) scale(1);
    }
    #register, #login {
        display: flex;
        justify-content: space-between !important;
        flex-wrap: wrap !important;
    }
        .react-datepicker-wrapper{
        position:relative;
        width:100%;
        }
        .input-group-prepend span{
        position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
        }
    .react-datepicker-popper{
    z-index: 100}
   @media screen and (min-width:767px){
    .w-45 {
        width: 45% !important;
    }}
        .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
        border-color:#e60000 !important;
        }
   .css-ka7ti6-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 10px;
}
       #orderItems {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

        #orderItems td, #orderItems th {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: center;
        }

        #orderItems tr:nth-child(even) {
            background-color: #f2f2f2;
        }

        #orderItems tr:hover {
            background-color: #ddd;
        }

        #orderItems th {
            padding-top: 12px;
            padding-bottom: 12px;
            text-align: left;
            background-color: #e60000;
            color: white;
            text-align: center;
        }

          `}
        </style>
        <link
          href="TITSolutions/WS/assets/login-booking/css/sourcesanspro-font.css"
          rel="stylesheet"
        />
        <link
          href="TITSolutions/WS/assets/login-booking/css/style.css"
          rel="stylesheet"
        />
        <link
          href="TITSolutions/WS/assets/line-awesome-3.css"
          rel="stylesheet"
        ></link>
      </Helmet>
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>Loading...</div>}>
                    <img
                      src={
                        isMobile
                          ? entryVisa.pageDetails?.pageBannerMobile
                          : isTablet
                          ? entryVisa.pageDetails?.pageBannerTablet
                          : entryVisa.pageDetails?.pageBannerPc
                      }
                      alt={entryVisa.pageDetails?.pageTitle}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className="rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main col-lg-9  "
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {entryVisa.pageDetails?.pageTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="rlr-search-results-page__breadcrumb-section">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb rlr-breadcrumb__items">
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/">Home </Link>
              </li>
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/visa">Meet & Assist + Visa </Link>
              </li>
              <li
                className="breadcrumb-item rlr-breadcrumb__item active"
                aria-current="page"
              >
                Request visa
              </li>
            </ol>
          </nav>
          <div className="rlr-icon-text">
            <i className="rlr-icon-font flaticon-phone-receiver-silhouette">
              {" "}
            </i>{" "}
            <Link
              to="tel:(+202) 3854 1010"
              className="rlr-search-results-page__phone"
            >
              Questions? (+202) 3854 1010{" "}
            </Link>
          </div>
        </div>
      </div>
      {!show && !userToken && !loginToken && !googleAccessToken ? (
        <LoginAndRegisterForm setShow={setShow} />
      ) : (
        <>
          <ScrollToTop />
          <section
            class="contact-bg-section bg_cover pt-50 pb-50 visa"
            style={{ backgroundImage: `url(images/uploads/contact-bg-1.png)` }}
          >
            <div class="container">
              <div class="row align-items-center">
                <form class="contact-form col-lg-12" onSubmit={handleSubmit}>
                  {" "}
                  <div class="contact-form-wrapper mb-50">
                    <h3 class="title">Enter Flight Details</h3>

                    <div class="row">
                      <div class="col-lg-4 mb-2">
                        <label htmlFor="country-select-demo"> Origin </label>
                        <div
                          style={{
                            marginTop: "10px",
                          }}
                        >
                          <Autocomplete
                            required
                            onChange={handleCountryChange}
                            id="country-select-demo"
                            className="col-lg-12 "
                            options={countries}
                            autoHighlight
                            getOptionLabel={(option) => option.label}
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{
                                  "& > img": {
                                    mr: 2,
                                    flexShrink: 0,
                                  },
                                }}
                                {...props}
                              >
                                {option.label}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                label=""
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password", // disable autocomplete and autofill
                                }}
                              />
                            )}
                            ListboxComponent={ListboxComponent}
                          />
                        </div>
                      </div>

                      <div class="form_group col-lg-4 pe-2 mb-2">
                        <label>Airline</label>
                        <select
                          class="form_control"
                          value={arrivalAirline}
                          onChange={(e) => setArrivalAirline(e.target.value)}
                          required
                        >
                          <option value="" hidden>
                            Select
                          </option>
                          {entryVisa.airlines?.map((airline) => (
                            <option
                              value={airline.airlineId}
                              key={airline.airlineId}
                            >
                              {airline.airlineName}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div class="form_group col-lg-4 mb-2">
                        <label htmlFor="ArrivalFlightNumber">
                          Arrival Flight No.
                        </label>
                        <input
                          id="ArrivalFlightNumber"
                          type="text"
                          class="form_control"
                          title="Enter Number"
                          value={arrivalFlightNumber}
                          onChange={(e) =>
                            setArrivalFlightNumber(e.target.value)
                          }
                          required
                        />
                      </div>

                      <div class="form_group  col-lg-4 ">
                        <label htmlFor="DateValues">Arrival Date</label>
                        <div className="u-datepicker input-group">
                          <DatePicker
                            selected={startArrivalDate}
                            onChange={(date) => setStartArrivalDate(date)}
                            className="form-control my-2"
                            id="DateValues"
                            placeholder="Select Arrival Date"
                            dateFormat="dd/MM/yyyy"
                            minDate={addDays(new Date(), entryVisa?.cutOfDays)}
                            required
                          />{" "}
                          <div className="input-group-prepend">
                            <span className="d-flex align-items-center mr-2">
                              <i className="flaticon-calendar text-dark font-weight-semi-bold"></i>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="form_group col-lg-4 col-md-12">
                        <label htmlFor="ArrivalTime">Arrival Time</label>
                        <input
                          type="time"
                          class="form_control"
                          placeholder="Arrival_Time"
                          name="Arrival_Time"
                          id="ArrivalTime"
                          onChange={(e) => setArrivalTime(e.target.value)}
                          value={arrivalTime}
                          required
                        />
                      </div>
                      <div class="form_group col-lg-4 pe-2">
                        <label htmlFor="ArrivalAirport">Arrival Airport</label>
                        <select
                          id="ArrivalAirport"
                          class="form_control"
                          value={airport}
                          onChange={(e) => setAirport(e.target.value)}
                        >
                          <option value="" hidden>
                            Select
                          </option>
                          {entryVisa.egyptAirports?.map((airport) => (
                            <option
                              value={airport.egyptAirportId}
                              key={airport.egyptAirportId}
                            >
                              {airport.egyptAirport}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <hr />
                    <h3 class="title pt-3">Enter Passenger Information</h3>
                    <p></p>
                    <div class="row">
                      <div class="form_group col-lg-3 col-md-12   pe-2">
                        <label htmlFor="GuestName">First Name</label>
                        <input
                          type="text"
                          class="form_control"
                          placeholder=" "
                          name="GuestName"
                          id="GuestName"
                          required
                          onChange={(e) => setCustomerFirstName(e.target.value)}
                          value={customerFirstName}
                        />
                      </div>
                      <div class="form_group col-lg-3 col-md-12   pe-2">
                        <label htmlFor="LastName">Last Name</label>
                        <input
                          type="text"
                          class="form_control"
                          placeholder=""
                          name="Guest_Name"
                          id="LastName"
                          onChange={(e) => setCustomerLastName(e.target.value)}
                          value={customerLastName}
                          required
                        />
                      </div>
                      <div class="form_group col-lg-3 col-md-12  pe-2  ">
                        <label htmlFor="customerEmailAddress">
                          Email Address
                        </label>
                        <input
                          type="email"
                          class="form_control"
                          placeholder=" "
                          name="Email"
                          id="customerEmailAddress"
                          onChange={(e) =>
                            setCustomerEmailAddress(e.target.value)
                          }
                          value={customerEmailAddress}
                          required
                        />
                      </div>
                      <div class="form_group col-lg-3 col-md-12  pe-2  ">
                        <label htmlFor="Mobile">Mobile</label>
                        <input
                          type="text"
                          class="form_control"
                          placeholder=" "
                          name="Mobile"
                          id="Mobile"
                          onChange={(e) => setCustomerMobile(e.target.value)}
                          value={customerMobile}
                          required
                        />
                      </div>

                      <div class="form_group col-lg-3 col-md-12  pe-2  ">
                        <label htmlFor="ddl_Nationality">Nationality</label>
                        <select
                          id="ddl_Nationality"
                          class="form_control"
                          onChange={(e) =>
                            setCustomerNationalityCode(e.target.value)
                          }
                          value={customerNationalityCode}
                        >
                          <option value="" hidden>
                            Select
                          </option>

                          {entryVisa?.nationalities?.map((nationality) => (
                            <option
                              key={nationality.nationalityId}
                              value={nationality.nationalityId}
                            >
                              {" "}
                              {nationality.nationalityName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div class="form_group col-lg-3 col-md-12  pe-2  ">
                        <label htmlFor="ddl_Language">Preferred Language</label>
                        <select
                          id="ddl_Language"
                          class="form_control"
                          required
                          value={language}
                          onChange={(e) => setLanguage(e.target.value)}
                        >
                          <option value="" hidden>
                            Select
                          </option>
                          {entryVisa?.preferredLanguages?.map((language) => (
                            <option
                              key={language.languageId}
                              value={language.languageId}
                            >
                              {" "}
                              {language.languageName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div class="form_group ">
                      <label htmlFor="SpecialRequirement">
                        Special Requirement
                      </label>
                      <textarea
                        class="form_control"
                        placeholder=""
                        name="Special_Requirement"
                        id="SpecialRequirement"
                        onChange={(e) =>
                          setCustomerSpecialRequest(e.target.value)
                        }
                        value={customerSpecialRequest}
                      ></textarea>
                    </div>
                  </div>
                  <div className="contact-form-wrapper">
                    <h3 className="title">Passengers Details</h3>
                    <table className="table">
                      <thead>
                        <tr>
                          <td>
                            <label>Full Name</label>
                            <input
                              type="text"
                              id="itemName"
                              placeholder=" "
                              className="px-1 py-2 form_control"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </td>
                          <td>
                            <label>Nationality</label>
                            <select
                              id="quantity"
                              className="px-1 py-2 form_control"
                              value={nationality}
                              onChange={(e) => setNationality(e.target.value)}
                            >
                              <option value="" hidden>
                                Select
                              </option>
                              <option value="Albanian">Albanian</option>
                              <option value="Argentine">Argentine</option>
                              <option value="Australian">Australian</option>
                              <option value="Austrian">Austrian</option>
                              <option value="Azerbaijani">Azerbaijani</option>
                              <option value="Belarusian">Belarusian</option>
                              <option value="Belgian">Belgian</option>
                              <option value="Bolivian">Bolivian</option>
                              <option value="Brazilian">Brazilian</option>
                              <option value="British">British</option>
                              <option value="Bulgarian">Bulgarian</option>
                              <option value="Canadian">Canadian</option>
                              <option value="Chilean">Chilean</option>
                              <option value="Chinese">Chinese</option>
                              <option value="Colombian">Colombian</option>
                              <option value="Croatian">Croatian</option>
                              <option value="Cypriot">Cypriot</option>
                              <option value="Czech">Czech</option>
                              <option value="Danish">Danish</option>
                              <option value="Dutch">Dutch</option>
                              <option value="Ecuadorean">Ecuadorean</option>
                              <option value="Estonian">Estonian</option>
                              <option value="Finnish">Finnish</option>
                              <option value="French">French</option>
                              <option value="Georgian">Georgian</option>
                              <option value="German">German</option>
                              <option value="Greek">Greek</option>
                              <option value="Hungarian">Hungarian</option>
                              <option value="Icelandic">Icelandic</option>
                              <option
                                value="India (WITH GCC RESIDENT VISA)
"
                              >
                                India (WITH GCC RESIDENT VISA)
                              </option>
                              <option value="Irish">Irish</option>
                              <option value="Italian">Italian</option>
                              <option value="Kazakh">Kazakh</option>
                              <option value="Latvian">Latvian</option>
                              <option value="Luxembourger">Luxembourger</option>
                              <option value="Malaysian">Malaysian</option>
                              <option value="Mexican">Mexican</option>
                              <option value="Norwegian">Norwegian</option>
                              <option value="Paraguayan">Paraguayan</option>
                              <option value="Peruvian">Peruvian</option>
                              <option value="Polish">Polish</option>
                              <option value="Qatari">Qatari</option>
                              <option value="Saudi Arabian">
                                Saudi Arabian
                              </option>
                              <option value="Slovak">Slovak</option>
                              <option value="Slovenian">Slovenian</option>
                              <option value="South African">
                                South African
                              </option>
                              <option value="Taiwanese">Taiwanese</option>
                              <option value="Togolese">Togolese</option>
                              <option value="Turkish">Turkish</option>
                              <option value="Ukrainian">Ukrainian</option>
                              <option value="Uruguayan">Uruguayan</option>
                              <option value="Venezuelan">Venezuelan</option>
                            </select>
                          </td>
                          <td>
                            <label>Passport No</label>
                            <input
                              type="text"
                              id="rate"
                              placeholder=" "
                              className="px-1 py-2 form_control"
                              value={passportNumber}
                              onChange={(e) =>
                                setPassportNumber(e.target.value)
                              }
                            />
                          </td>
                          <td>
                            <label>Birthdate</label>

                            <DatePicker
                              selected={birthOfDate}
                              onChange={(date) => setBirthDate(date)}
                              id="birthOfDate"
                              placeholder=" "
                              className="px-1 py-2 form_control"
                              name="DateValues"
                              dateFormat="dd/MM/yyyy"
                              maxDate={new Date()}
                              required
                            />
                          </td>
                          <td style={{ lineHeight: "5" }}>
                            <input
                              id="addpassenger"
                              className="btn rlr-button w-auto mx-auto"
                              type="button"
                              value="Add"
                              style={{
                                padding: "10px 20px",
                                backgroundColor: "#E60000",
                                color: "#fff",
                                borderRadius: "5px",
                              }}
                              onClick={handleAddPassenger}
                            />
                          </td>
                        </tr>
                      </thead>
                    </table>
                    {passengers?.length > 0 && (
                      <div id="orderItems" className="tablecontainer">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Nationality</th>
                              <th>Passport Number</th>
                              <th>Birth Date</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {passengers.map((passenger, index) => (
                              <tr key={index}>
                                <td>{passenger.name}</td>
                                <td>{passenger.nationality}</td>
                                <td>{passenger.passportNumber}</td>
                                <td>{formatDate(passenger?.birthOfDate)}</td>
                                <td className="mx-auto text-center">
                                  <Link
                                    to="#."
                                    onClick={() => handleDeletePassenger(index)}
                                  >
                                    <i className="fa-mytravel-close text-center mx-auto text-primary ms-5"></i>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                    <div style={{ padding: "10px 0px", textAlign: "center" }}>
                      <button
                        className="btn rlr-button w-auto mx-auto"
                        type="submit"
                        style={{
                          padding: "5px 20px",
                          backgroundColor: "#E60000",
                          color: "#fff",
                          borderRadius: "5px",
                        }}
                      >
                        {loadingFormData ? (
                          <>
                            <Spinner
                              animation="border"
                              variant="light"
                              size="sm"
                            />{" "}
                            Loading ...
                          </>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default EntryVisa;
