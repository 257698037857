import { Alert, Snackbar, useMediaQuery } from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchInternationalOffices } from "../../data/international-offices/internationalOfficesSlice";
import { sendFormData } from "../../data/international-offices/formSlice";
import ReCAPTCHA from "react-google-recaptcha";
import { Spinner } from "react-bootstrap";

const InternationalOffices = () => {
  const [itemIndex, setItemIndex] = useState(1);
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const language = useSelector((state) => state.language.language);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const [txtOfficeName, setTxtOfficeName] = useState("");
  const [txtOfficeEmail, setTxtOfficeEmail] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerMessage, setCustomerMessage] = useState("");
  const [namehoney, setNamehoney] = useState("");
  const [emailhoney, setEmailhoney] = useState("");

  const { internationalOffices, loading, error } = useSelector(
    (state) => state.internationalOffices
  );
  const { loadingCstData, errorCstData } = useSelector((state) => state.form);
  useEffect(() => {
    dispatch(fetchInternationalOffices(language));
  }, [dispatch, language]);
  //   const formStatus = useSelector((state) => state.form.status);
  const [captchaValue, setCaptchaValue] = useState("");
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare the form data object
    const formData = {
      txtOfficeName,
      txtOfficeEmail,
      customerName,
      customerEmail,
      customerPhone,
      customerMessage,
      namehoney,
      emailhoney,
    };
    if (!captchaValue) {
      alert("Please complete the reCAPTCHA!");
      return;
    }
    // Dispatch the action to send form data
    dispatch(sendFormData(formData));
    if (captchaValue && !loadingCstData && !errorCstData) {
      setOpen(true);
    }
    // Optionally, reset the form fields
    setTxtOfficeName("");
    setTxtOfficeEmail("");
    setCustomerName("");
    setCustomerEmail("");
    setCustomerPhone("");
    setCustomerMessage("");
    setNamehoney("");
    setEmailhoney("");
  };
  return (
    <>
      {" "}
      <Helmet>
        <title>{internationalOffices.pageDetails?.pageMetatagTitle}</title>
        <link
          rel="canonical"
          href={`https://travco.com/${language}/international-offices`}
        />
        <meta
          name="description"
          content={internationalOffices.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={internationalOffices.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={internationalOffices.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={internationalOffices.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={internationalOffices.pageDetails?.metatagDescriptionTwitter}
        />
        <link rel="stylesheet" href="css/international-offices.css" />
      </Helmet>{" "}
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>Loading...</div>}>
                    {" "}
                    <img
                      className="rlr-banner-splide__banner-img"
                      src={
                        isMobile
                          ? internationalOffices.pageDetails?.pageBannerMobile
                          : isTablet
                          ? internationalOffices.pageDetails?.pageBannerTablet
                          : internationalOffices.pageDetails?.pageBannerPc
                      }
                      alt={internationalOffices.pageDetails?.pageTitle}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className="rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {internationalOffices.pageDetails?.pageTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="rlr-search-results-page__breadcrumb-section">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb rlr-breadcrumb__items">
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/">Home </Link>
              </li>
              <li
                className="breadcrumb-item rlr-breadcrumb__item active"
                aria-current="page"
              >
                {internationalOffices.pageDetails?.pageTitle}
              </li>
            </ol>
          </nav>
          <div className="rlr-icon-text">
            <i className="rlr-icon-font flaticon-phone-receiver-silhouette">
              {" "}
            </i>{" "}
            <Link
              to="tel:(+202) 3854 1010"
              className="rlr-search-results-page__phone"
            >
              Questions? (+202) 3854 1010{" "}
            </Link>
          </div>
        </div>
      </div>
      <section className="faq-section pt-lg-5 pt-0 pb-0 container">
        <div>
          <div className="row">
            <div className="col-lg-12">
              <div className="faqs-tab mb-40">
                <ul className="nav d-flex justify-content-between align-items-center filter-ul">
                  {internationalOffices.internationalOffices?.map(
                    (item, index) => (
                      <li className="nav-item" role="presentation" key={index}>
                        <button
                          className={`nav-link ${
                            index + 1 === itemIndex ? "active" : ""
                          }`}
                          data-bs-toggle="tab"
                          data-bs-target={`item-${index + 1}`}
                          onClick={() => setItemIndex(index + 1)}
                        >
                          <img
                            src={item.officeFlag}
                            style={{
                              display: "inline-block",
                              height: "50px",
                              width: "50px",
                              marginRight: "10px",
                            }}
                            alt="Flag"
                          />
                          {item.officeCountryName}
                        </button>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              {" "}
              <div className="tab-content">
                {internationalOffices.internationalOffices?.map(
                  (item, index) => (
                    <div
                      key={index}
                      className={`tab-pane fade ${
                        index + 1 === itemIndex ? "show   active" : ""
                      }`}
                      id={`item-${index + 1}`}
                    >
                      <section className="features-section pt-100 pb-50 container-fluid">
                        <div>
                          <div className="row align-items-top">
                            <div className="col-12 col-lg-6">
                              <div
                                className="features-content-box mb-50 mb-lg-0  "
                                style={{ visibility: "visible" }}
                              >
                                <div className="section-title mb-30">
                                  <img
                                    alt={item.officeCountryName}
                                    src={item.officeLogo}
                                  />

                                  <h2 className="rlr-section__title--main">
                                    {item.officeCountryName}
                                  </h2>
                                </div>
                                <div
                                  className="SiteFontP"
                                  dangerouslySetInnerHTML={{
                                    __html: item.officeDetails,
                                  }}
                                />
                              </div>
                            </div>
                            <Suspense fallback={<div>Loading...</div>}>
                              {" "}
                              <div className="col-12 col-lg-6">
                                <div
                                  className="features-img-box mb-50  "
                                  style={{ visibility: "visible" }}
                                >
                                  <img
                                    src={item.officeMainPhoto}
                                    alt={item.officeCountryName}
                                    loading="lazy"
                                  />
                                </div>
                              </div>
                            </Suspense>
                          </div>
                        </div>
                      </section>
                      <section
                        className="contact-bg-section bg_cover  pb-50"
                        style={{
                          backgroundImage: `url(../images/uploads/contact-bg-1.png)`,
                        }}
                      >
                        <div className="container-fluid">
                          <div className="row align-items-center">
                            <div className="col-xl-7 col-lg-12">
                              <div className="contact-content-box mb-50">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div
                                      className="contact-icon-box mb-50 wow fadeInDown"
                                      style={{
                                        visibility: "visible",
                                        animationName: "fadeInDown",
                                      }}
                                    >
                                      <div className="icon">
                                        <i className="rlr-icon-font flaticon-carbon-location"></i>
                                      </div>
                                      <div className="text">
                                        <h4 className="title rlr-section__title--main">
                                          Locations
                                        </h4>
                                        <p>{item.officeAddress}</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div
                                      className="contact-icon-box mb-50 wow fadeInDown"
                                      style={{
                                        visibility: "visible",
                                        animationName: "fadeInDown",
                                      }}
                                    >
                                      <div className="icon">
                                        <i className="rlr-icon-font flaticon-email"></i>
                                      </div>
                                      <div className="text">
                                        <h4 className="title rlr-section__title--main">
                                          Email Us
                                        </h4>
                                        <p>
                                          <Link
                                            to={`mailto:${item.officeEmail}`}
                                          >
                                            {item.officeEmail}
                                          </Link>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div
                                      className="contact-icon-box mb-50 wow fadeInDown"
                                      style={{
                                        visibility: "visible",
                                        animationName: "fadeInDown",
                                      }}
                                    >
                                      <div className="icon">
                                        <i className="rlr-icon-font flaticon-telephone"></i>
                                      </div>
                                      <div className="text">
                                        <h4 className="title rlr-section__title--main">
                                          Phone
                                        </h4>
                                        <p>
                                          <Link to={`tel:${item.officePhone}`}>
                                            {item.officePhone}
                                          </Link>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div
                                      className="contact-icon-box mb-50 wow fadeInDown"
                                      style={{
                                        visibility: "visible",
                                        animationName: "fadeInDown",
                                      }}
                                    >
                                      <div className="icon">
                                        <i className="rlr-icon-font flaticon-globe"></i>
                                      </div>
                                      <div className="text">
                                        <h4 className="title rlr-section__title--main">
                                          Website
                                        </h4>
                                        <Link
                                          to={item.officeWebsite}
                                          target="_blank"
                                        >
                                          {item.officeWebsite}
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-5 col-lg-12">
                              <div className="contact-form-wrapper mb-50">
                                <h3 className="title rlr-section__title--main">
                                  Send Us Message
                                </h3>
                                <p>
                                  Do you have anything your mind to tell? Please
                                  don't hesitate to in touch to us our contact
                                  form.
                                </p>
                                <form id="contact-form" onSubmit={handleSubmit}>
                                  {/* Honey pot fields */}
                                  <label
                                    className="ohnohoney"
                                    htmlFor="namehoney"
                                  ></label>
                                  <input
                                    tabIndex="-1"
                                    className="ohnohoney"
                                    autoComplete="off"
                                    type="text"
                                    id="namehoney"
                                    name="namehoney"
                                    placeholder="Your name here"
                                    value={namehoney}
                                    onChange={(e) =>
                                      setNamehoney(e.target.value)
                                    }
                                  />
                                  <label
                                    className="ohnohoney"
                                    htmlFor="emailhoney"
                                  ></label>
                                  <input
                                    tabIndex="-1"
                                    className="ohnohoney"
                                    autoComplete="off"
                                    type="email"
                                    id="emailhoney"
                                    name="emailhoney"
                                    placeholder="Your e-mail here"
                                    value={emailhoney}
                                    onChange={(e) =>
                                      setEmailhoney(e.target.value)
                                    }
                                  />

                                  {/* Real form fields */}
                                  <div className="form_group">
                                    <input
                                      type="text"
                                      className="form_control"
                                      placeholder="Full Name"
                                      name="customerName"
                                      value={customerName}
                                      onChange={(e) => {
                                        setCustomerName(e.target.value);
                                        setTxtOfficeName(
                                          item.officeCountryName
                                        );
                                        setTxtOfficeEmail(item.officeEmail);
                                      }}
                                      required
                                    />
                                  </div>
                                  <div className="form_group">
                                    <input
                                      type="email"
                                      className="form_control"
                                      placeholder="Email Address"
                                      name="customerEmail"
                                      value={customerEmail}
                                      onChange={(e) =>
                                        setCustomerEmail(e.target.value)
                                      }
                                      required
                                    />
                                  </div>
                                  <div className="form_group">
                                    <input
                                      type="text"
                                      className="form_control"
                                      placeholder="Mobile"
                                      name="customerPhone"
                                      value={customerPhone}
                                      onChange={(e) =>
                                        setCustomerPhone(e.target.value)
                                      }
                                      required
                                    />
                                  </div>
                                  <div className="form_group">
                                    <textarea
                                      className="form_control"
                                      placeholder="Write Message"
                                      name="customermessage"
                                      value={customerMessage}
                                      onChange={(e) =>
                                        setCustomerMessage(e.target.value)
                                      }
                                    ></textarea>
                                  </div>

                                  <div
                                    id="divcaptcha"
                                    style={{
                                      display: "flex",
                                      justifyContent: "left",
                                      margin: "20px 0 0 0",
                                    }}
                                  >
                                    <ReCAPTCHA
                                      sitekey="6LdVcwgqAAAAAJzoiaHWcn_dmaNoCCn3_7ActZXJ"
                                      onChange={handleCaptchaChange}
                                    />
                                  </div>

                                  <input
                                    id="txtcaptcha"
                                    type="hidden"
                                    name="g-recaptcha-response"
                                    value={captchaValue}
                                    required
                                  />

                                  <div className="form_group">
                                    <button
                                      className="main-btn btn-green"
                                      type="submit"
                                    >
                                      {loadingCstData ? (
                                        <>
                                          <Spinner
                                            animation="border"
                                            variant="light"
                                            size="sm"
                                          />{" "}
                                          Loading ...
                                        </>
                                      ) : (
                                        <>
                                          Send message{" "}
                                          <i className="fa fa-angle-double-right"></i>
                                        </>
                                      )}
                                    </button>
                                  </div>
                                </form>
                                {!loadingCstData && !errorCstData && (
                                  <Snackbar
                                    open={open}
                                    autoHideDuration={6000}
                                    onClose={handleClose}
                                  >
                                    <Alert
                                      onClose={handleClose}
                                      severity="success"
                                      variant="filled"
                                      sx={{ width: "100%" }}
                                    >
                                      Your Message Sent Successfully To{" "}
                                      {item.officeEmail}
                                    </Alert>
                                  </Snackbar>
                                )}
                                {errorCstData && (
                                  <Snackbar
                                    open={open}
                                    autoHideDuration={6000}
                                    onClose={handleClose}
                                  >
                                    <Alert
                                      onClose={handleClose}
                                      severity="danger"
                                      variant="filled"
                                      sx={{ width: "100%" }}
                                    >
                                      {errorCstData}
                                    </Alert>
                                  </Snackbar>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      {item.officeGoogleLocation && (
                        <section
                          className="contact-page-map pb-0 wow fadeInUp"
                          style={{
                            visibility: "visible",
                            animationName: "fadeInUp",
                          }}
                        >
                          <div className="container-fluid">
                            <div className="map-box">
                              <iframe
                                src={item.officeGoogleLocation}
                                title={item.officeCountryName}
                              ></iframe>
                            </div>
                          </div>
                        </section>
                      )}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InternationalOffices;
