import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Alert, Snackbar, useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { fetchMice } from "../../data/mice/miceSlice";
import { Spinner } from "react-bootstrap";

const Mice = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const { mice, loading, error } = useSelector((state) => state.mice);
  useEffect(() => {
    dispatch(fetchMice(language));
  }, [dispatch, language]);
  const [formData, setFormData] = useState({
    name: "",
    subject: "",
    eventRequest: "",
    mobile: "",
    email: "",
    message: "",
    namehoney: "", // Clear honey pot fields after submission
    emailhoney: "",
  });

  const [loadingFormData, setLoadingFormData] = useState(false);
  const [errorFormDate, setErrorFormDate] = useState("");
  const [success, setSuccess] = useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoadingFormData(true);
    setErrorFormDate("");
    setSuccess(false);

    try {
      const response = await fetch("https://api.travco.com/MiceRequestSubmit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setSuccess(true);
      setOpen(true);
    } catch (error) {
      console.error("Error:", error);
      setErrorFormDate(error.message); // Ensure the error is a string
      setOpen(true);
      setSuccess(false);
    } finally {
      setLoadingFormData(false);
      setFormData({
        name: "",
        subject: "",
        eventRequest: "",
        mobile: "",
        email: "",
        message: "",
        namehoney: "", // Clear honey pot fields after submission
        emailhoney: "",
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>{mice.pageDetails?.pageMetatagTitle || "MICE"}</title>
        <link rel="canonical" href={`https://travco.com/${language}/mice`} />
        <meta
          name="description"
          content={mice.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={mice.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={mice.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={mice.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={mice.pageDetails?.metatagDescriptionTwitter}
        />
      </Helmet>{" "}
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>Loading...</div>}>
                    {" "}
                    <img
                      src={
                        isMobile
                          ? mice.pageDetails?.pageBannerMobile
                          : isTablet
                          ? mice.pageDetails?.pageBannerTablet
                          : mice.pageDetails?.pageBannerPc
                      }
                      alt={mice.pageDetails?.pageTitle}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className="rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {mice.pageDetails?.pageTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="rlr-search-results-page__breadcrumb-section">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb rlr-breadcrumb__items">
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/">Home </Link>
              </li>
              <li
                className="breadcrumb-item rlr-breadcrumb__item active"
                aria-current="page"
              >
                {mice.pageDetails?.pageTitle}
              </li>
            </ol>
          </nav>
          <div className="rlr-icon-text">
            <i className="rlr-icon-font flaticon-phone-receiver-silhouette">
              {" "}
            </i>{" "}
            <Link
              to="tel:(+202) 3854 1010"
              className="rlr-search-results-page__phone"
            >
              Questions? (+202) 3854 1010{" "}
            </Link>
          </div>
        </div>
      </div>
      <section className="about-section pt-100  container">
        <div className="">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-12">
              <div className="about-content-box mb-5 wow fadeInLeft">
                <div className="section-title mb-5">
                  <h2 className="trusted">
                    {mice.miceDetails?.mainTitle} <br />
                    <span className="thin">
                      {mice.miceDetails?.subMainTitle}
                    </span>
                  </h2>
                </div>

                <div
                  className="SiteFontP"
                  dangerouslySetInnerHTML={{
                    __html: mice.miceDetails?.mainText,
                  }}
                />
              </div>
            </div>
            <div className="col-xl-6 p-0">
              <div className="about-one_image-box   p-r z-1 wow fadeInRight">
                <img
                  src={mice.miceDetails?.micePhoto1}
                  className="about-img-one mb-30 "
                  alt={mice.miceDetails?.mainTitle}
                  loading="lazy"
                />
                <img
                  src={mice.miceDetails?.micePhoto2}
                  className="about-img-two mb-30 "
                  alt={mice.miceDetails?.mainTitle}
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Modal Button*/}
      <section className="features-section  py-5 container mx-auto text-center my-lg-5">
        <button
          type="button"
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
        >
          <FontAwesomeIcon icon={faEnvelope} /> Send To Us
        </button>

        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
          style={{ zIndex: "999999999999999" }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header border-0 ">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body  pt-0">
                <div className="modal-body text-left pt-0 pt-0">
                  {/* <h2 className="p-2 text-center col-12 ">Send To US </h2> */}
                  <form
                    className="col-12 d-flex flex-wrap justify-content-around"
                    onSubmit={handleSubmit}
                  >
                    <label className="ohnohoney" htmlFor="namehoney"></label>
                    <input
                      tabIndex="-1"
                      className="ohnohoney"
                      autoComplete="off"
                      type="text"
                      id="namehoney"
                      name="namehoney"
                      placeholder="Your name here"
                      value={formData.namehoney}
                      onChange={handleInputChange}
                    />
                    <label className="ohnohoney" htmlFor="emailhoney"></label>
                    <input
                      tabIndex="-1"
                      className="ohnohoney"
                      autoComplete="off"
                      type="email"
                      id="emailhoney"
                      name="emailhoney"
                      placeholder="Your e-mail here"
                      value={formData.emailhoney}
                      onChange={handleInputChange}
                    />
                    <div className="form_group mb-3 col-12 col-lg-5">
                      <label htmlFor="name" className="col-form-label">
                        Name:
                      </label>
                      <input
                        required
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Enter Your Name/Company Name..."
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form_group mb-3 col-12 col-lg-5">
                      <label htmlFor="subject" className="col-form-label">
                        Subject:
                      </label>
                      <input
                        required
                        type="text"
                        className="form-control"
                        id="subject"
                        name="subject"
                        placeholder="Subject..."
                        value={formData.subject}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form_group mb-3 col-12 col-lg-11">
                      <label htmlFor="event" className="col-form-label">
                        Event:
                      </label>
                      <input
                        required
                        type="text"
                        className="form-control"
                        id="event"
                        name="eventRequest"
                        placeholder="Purpose Of Business..."
                        value={formData.eventRequest}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form_group mb-3 col-12 col-lg-5">
                      <label htmlFor="mobile" className="col-form-label">
                        Mobile:
                      </label>
                      <input
                        required
                        type="text"
                        className="form-control"
                        id="mobile"
                        name="mobile"
                        placeholder="Enter Your Phone Number"
                        value={formData.mobile}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form_group mb-3 col-12 col-lg-5">
                      <label htmlFor="email" className="col-form-label">
                        Email:
                      </label>
                      <input
                        required
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Enter Your Email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form_group mb-3 col-12 col-lg-11">
                      <label htmlFor="message-text" className="col-form-label">
                        Message:
                      </label>
                      <textarea
                        className="form-control py-2 pb-0"
                        id="message-text"
                        rows="5"
                        name="message"
                        style={{ minHeight: "100px" }}
                        value={formData.message}
                        onChange={handleInputChange}
                      ></textarea>
                    </div>
                    <div className="form_group modal-footer">
                      <button type="submit" className="btn btn-primary">
                        {loadingFormData ? (
                          <>
                            <Spinner
                              animation="border"
                              variant="light"
                              size="sm"
                            />{" "}
                            Loading ...
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faEnvelope} /> Send To Us
                          </>
                        )}
                      </button>
                    </div>
                  </form>
                  {!loadingFormData && success && (
                    <Snackbar
                      open={open}
                      autoHideDuration={6000}
                      onClose={handleClose}
                    >
                      <Alert
                        onClose={handleClose}
                        severity="success"
                        variant="filled"
                        sx={{ width: "100%" }}
                      >
                        Your Request Sent Successfully
                      </Alert>
                    </Snackbar>
                  )}{" "}
                  {errorFormDate && (
                    <Snackbar
                      open={open}
                      autoHideDuration={4000}
                      onClose={handleClose}
                    >
                      <Alert
                        onClose={handleClose}
                        severity="danger"
                        variant="filled"
                        sx={{ width: "100%" }}
                      >
                        {errorFormDate}
                      </Alert>
                    </Snackbar>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="features-section  py-5 container">
        <div className="">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-10">
              <div className="section-title text-center mb-50 wow fadeInUp">
                <h2>
                  {mice.miceDetails?.offersMainTitle}
                  <br />{" "}
                  <span className="thin">{mice.miceDetails?.offersText}</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            {mice.mices?.map((offer) => (
              <div
                className="col-xl-3 col-lg-4 col-md-6 col-sm-6"
                key={offer.miceservicesUrl}
              >
                <div className="fancy-icon-box-three mb-40 wow fadeInDown">
                  <div className="icon">
                    <img
                      src={offer.miceservicesIcon}
                      width="50"
                      className="img-fluid"
                      loading="lazy"
                      alt={offer.miceservicesName}
                    />
                  </div>
                  <div className="text">
                    <h5 className="title cottages-title rlr-section__title--main">
                      {offer.miceservicesName}
                    </h5>
                    <p
                      className="SiteFontP line-clamp4"
                      style={{ paddingBottom: "0", borderBottom: "0" }}
                    >
                      {offer.miceservicesIntro}
                    </p>
                    <Link
                      to={`/mice/${offer.miceservicesUrl}`}
                      className="btn-link"
                    >
                      Read More <i className="fa fa-angle-double-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="features-section py-5 container">
        <div>
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-10 wow fadeInDown">
              <div className="section-title text-center mb-50">
                <h2 className="rlr-section__title--main">
                  <b>{mice.miceDetails?.servicesMainTitle}</b>
                  <br />
                  <span className="thin rlr-section__title--main">
                    <small>{mice.miceDetails?.servicesText}</small>
                  </span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            {mice.miceServices?.map((sevice, index) => (
              <div className="col-lg-6 col-md-12" key={index}>
                <div
                  className="features-left-icon-box-two mb-30 wow fadeInUp"
                  style={{ visibility: "visible", animationName: "fadeInUp" }}
                >
                  <div className="icon">
                    <img
                      src={sevice.miceservicesIcon}
                      width="50"
                      className="img-fluid"
                      alt={sevice.miceservicesName}
                    />
                  </div>
                  <div className="text">
                    <h6 className="title rlr-section__title--main">
                      {sevice.miceservicesName}
                    </h6>
                    <p className="SiteFontP">{sevice.miceservicesText}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="about-section py-5 container pt-90">
        <div className="container">
          <div className="row align-items-xl-center">
            <div className="col-xl-6 col-lg-6">
              <div className="about-two_image-box mb-50">
                <img
                  src={mice.transportation?.transportationPhoto}
                  className="wow fadeInUp"
                  alt={mice.transportation?.mainTransportationTitle}
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 p-0">
              <div className="about-content-box pl-lg-50 mb-50 wow fadeInRight">
                <div className="section-title mb-5">
                  <h2 className="rlr-section__title--main">
                    {mice.transportation?.mainTransportationTitle}
                  </h2>
                </div>
                <p
                  className="mb-30 SiteFontP"
                  dangerouslySetInnerHTML={{
                    __html: mice.transportation?.mainTransportationText,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Mice;
