import React, { useEffect, useState, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";

import { styled, useMediaQuery } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleUp,
  faClose,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";

import ScrollToTop from "../../ScrollToTop";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchCruises, setPageIndex } from "../../data/cruises/cruisesSlice";
const Cruises = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const [view, setView] = useState(false);
  const language = useSelector((state) => state.language.language);
  const dispatch = useDispatch();
  const [scroll, setScroll] = useState(false);
  // cruises
  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };
  const [destinationCruises, setDestinationCruises] = useState("");
  const [availableDates, setAvailableDates] = useState([]);

  const [ItineraryId, setItineraryId] = useState("");
  const [startDateCruises, setStartDateCruises] = useState();
  const [startDateCruisesMenu, setStartDateCruisesMenu] = useState();
  const [endDateCruisesMenu, setEndDateCruisesMenu] = useState();
  const [adultsCruises, setAdultsCruises] = useState(2);
  const [childrenCruises, setChildrenCruises] = useState(0);

  const [AdultNo, setIAdultNo] = useState("");
  const [ChildNo, setIChildNo] = useState("");
  const handleItineraryChange = async (e) => {
    const itineraryTypeId = e.target.value;
    setItineraryId(itineraryTypeId);

    try {
      const response = await axios.get(
        `https://api.travco.com/apiTITSol/TTG24_NileCruise/GetOperationDatesByItineraryType?ItineraryTypeId=${itineraryTypeId}`
      );
      const dates = response.data.formatedDate.map((date) => new Date(date));
      setAvailableDates(dates);
      setStartDateCruises(addDays(dates[0], 7) || null);
      setStartDateCruisesMenu(addDays(dates[0], 7) || null);
      setEndDateCruisesMenu(dates[dates.length - 1] || null);
    } catch (error) {
      console.error("Error fetching dates: ", error);
    }
  };

  const isDateAvailable = (date) => {
    return availableDates.some((d) => d.toDateString() === date.toDateString());
  };
  // search
  const [searchTerm, setSearchTerm] = useState("");
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const currency = useSelector((state) => state.currency.currency);
  const { cruises, pageIndex, loading, error } = useSelector(
    (state) => state.cruises
  );

  const handleNextPage = () => {
    if (pageIndex < cruises?.totalPages) {
      dispatch(setPageIndex(pageIndex + 1));
    }
    setScroll(true);
  };
  const handlePreviousPage = () => {
    if (pageIndex > 1) {
      dispatch(setPageIndex(pageIndex - 1));
    }
    setScroll(true);
  };
  const handlePageChange = (page) => {
    dispatch(setPageIndex(page));
    setScroll(true);
  };
  const [Sort, setSort] = useState(1);
  const [selectedStyle, setSelectedStyle] = useState([]);
  const [selectedDestination, setSelectedDestination] = useState([]);
  const [groupSize, setGroupSize] = useState([]);
  const [durations, setDurations] = useState([]);
  const handleCheckboxChange = (event) => {
    const { checked, value } = event.target;
    if (checked) {
      setSelectedStyle((prevSelected) => [...prevSelected, value]);
    } else {
      setSelectedStyle((prevSelected) =>
        prevSelected.filter((item) => item !== value)
      );
    }
  };
  const handleDestinationsCheckboxChange = (event) => {
    const { checked, value } = event.target;
    if (checked) {
      setSelectedDestination((prevSelected) => [...prevSelected, value]);
    } else {
      setSelectedDestination((prevSelected) =>
        prevSelected.filter((item) => item !== value)
      );
    }
  };

  useEffect(() => {
    dispatch(
      fetchCruises({
        pageIndex,
        searchTerm,
        language,
        currency,
      })
    );

    setScroll(false);
  }, [dispatch, searchTerm, pageIndex, currency, language]);
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));
  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // dispatch(
    //   fetchBookingPackage({
    //     productId,
    //     numberOfchildrens1,
    //     currency,
    //     numberOfchildrens2,
    //     roomType,
    //     roomNumber,
    //     startDate: formatDate(startDate),
    //   })
    // );
    localStorage.setItem("cruises_ItineraryId", ItineraryId);
    localStorage.setItem(
      "cruises_startDateCruises",
      formatDate(startDateCruises)
    );
    localStorage.setItem("cruises_adultsCruises", adultsCruises);
    localStorage.setItem("cruises_childrenCruises", childrenCruises);

    navigate(`/cruisesbooking`);
  };
  return (
    <>
      <Helmet>
        <title>{cruises.pageDetails?.pageMetatagTitle || "Cruises"}</title>
        <link rel="canonical" href="https://travco.com/en/nile-cruises" />
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content={cruises.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={cruises.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={cruises.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={cruises.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={cruises.pageDetails?.metatagDescriptionTwitter}
        />
        <link rel="stylesheet" href="css/news.css" />
        <style type="text/css">{`
        
        .react-datepicker-wrapper {
        flex: 1;
        }
        
        @media screen and  (max-width: 768px) {
    .rlr-product-filters__filters-wrapper .rlr-checkboxes {
        display: flex;
        justify-content: space-between;
        align-items: start;
        margin-top: 10px;
    }
}`}</style>
      </Helmet>{" "}
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>Loading...</div>}>
                    <img
                      placeholder={"Loading..."}
                      src={
                        isMobile
                          ? cruises.pageDetails?.pageBannerMobile
                          : isTablet
                          ? cruises.pageDetails?.pageBannerTablet
                          : cruises.pageDetails?.pageBannerPc
                      }
                      alt={cruises.pageDetails?.altBanner}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className="rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {cruises.pageDetails?.pageTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="rlr-search-results-page__breadcrumb-section">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb rlr-breadcrumb__items">
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/">Home </Link>
              </li>

              <li
                className="breadcrumb-item rlr-breadcrumb__item active"
                aria-current="page"
              >
                {cruises.pageDetails?.pageTitle}
              </li>
            </ol>
          </nav>
          <div className="rlr-icon-text">
            <i className="rlr-icon-font flaticon-phone-receiver-silhouette">
              {" "}
            </i>{" "}
            <Link
              to="tel:(+202) 3854 1010"
              className="rlr-search-results-page__phone"
            >
              Questions? (+202) 3854 1010{" "}
            </Link>
          </div>
        </div>
      </div>
      <section>
        <div className="container">
          <div className="row rlr-search-results-page__product-details">
            {" "}
            <aside
              className={`col-xl-3 rlr-search-results-page__sidebar ${
                isMobile && view && "view"
              }`}
              id="filter-mobile"
            >
              <div className="rlr-product-filters__filters-wrapper pb-5">
                {isMobile && (
                  <Button
                    onClick={() => setView(false)}
                    className="btn btn-danger d-flex justify-content-end mt-0 ms-auto"
                  >
                    <FontAwesomeIcon icon={faClose} />
                  </Button>
                )}
                <div className="border-bottom pb-4">
                  <div className="   mb-0 packages col-lg-5 w-100">
                    <span className="font-size-22 fs-3">Search</span>
                    <div
                      className="search-wrapper w-100 mt-3 "
                      style={{ position: "relative" }}
                    >
                      <SearchIconWrapper>
                        <SearchIcon sx={{ color: "#e60000" }} />
                      </SearchIconWrapper>
                      <input
                        className="form-control ps-5"
                        style={{
                          border: 0,
                          boxShadow: "0 0 1px 2px #dddddd94",
                        }}
                        placeholder="Search…"
                        value={searchTerm}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="row d-block nav-select d-lg-flex mb-lg-3   align-items-end">
                    <div className="col-sm-12 col-lg-3dot6 col-xl-3gdot5 mb-3  ">
                      <span className="d-block text-gray-1 font-weight-normal text-left mb-0">
                        Itinerary
                      </span>
                      <div className="d-flex align-items-center">
                        <i
                          className="icon flaticon-map-marker"
                          style={{ color: "#e60000" }}
                        >
                          {" "}
                        </i>
                        <select
                          id="TripTypeID"
                          name="TripTypeID"
                          className="form_control ps-1"
                          style={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                            borderTop: "0px",
                            borderRight: "0px",
                            borderLeft: "0px",
                          }}
                          title="Itinerary"
                          value={ItineraryId}
                          onChange={handleItineraryChange}
                          required
                        >
                          <option hidden>Select Itinerary</option>
                          <optgroup label="Nile Cruises">
                            {cruises.itineraryTypes?.map((itinerary) => (
                              <option
                                value={itinerary.itinerariesTypeId}
                                key={itinerary.itinerariesTypeId}
                              >
                                {itinerary.itinerariesName}
                              </option>
                            ))}
                          </optgroup>
                        </select>
                      </div>
                    </div>

                    {/* Date Picker */}
                    <div className="col-sm-12 col-lg-3dot6 col-xl-3gdot5 my-3 ">
                      <span className="d-block text-gray-1 font-weight-normal text-left mb-0">
                        Date
                      </span>
                      <div className="border-bottom border-width-2 border-color-1">
                        <div
                          id="datepickerWrapperFromThree"
                          className="u-datepicker input-group d-flex align-items-center"
                        >
                          <div className="input-group-prepend">
                            <span className="d-flex align-items-center mr-2">
                              <i className="flaticon-calendar text-primary font-weight-semi-bold"></i>
                            </span>
                          </div>
                          <DatePicker
                            selected={startDateCruises}
                            onChange={(date) => setStartDateCruises(date)}
                            className=" border-0 ps-1 py-3"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/YYYY"
                            minDate={startDateCruisesMenu}
                            maxDate={endDateCruisesMenu}
                            filterDate={isDateAvailable}
                            title={
                              destinationCruises
                                ? ""
                                : "Select Your Itinerary Type And View Available Dates"
                            }
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-3 dot6 col-xl-3 gdot5 my-3  ">
                      <span className="d-block text-gray-1 font-weight-normal text-left mb-0">
                        Adults
                      </span>
                      {/* Select */}
                      <div className="d-flex align-items-center">
                        <i className="rlr-icon-font flaticon-add-user text-primary "></i>
                        <select
                          id="AdultNo"
                          name="AdultNo"
                          className="form_control"
                          style={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                            borderTop: "0px",
                            borderRight: "0px",
                            borderLeft: "0px",
                          }}
                          title="Adult"
                          data-style=""
                          data-live-search="true"
                          data-searchbox-classes="input-group-sm"
                          value={adultsCruises}
                          onChange={(e) => setAdultsCruises(e.target.value)}
                        >
                          <option value="1"> 1 Adult</option>
                          <option value="2" selected>
                            {" "}
                            2 Adults
                          </option>
                        </select>
                      </div>
                      {/* End Select */}
                    </div>
                    <div className="col-sm-12 col-lg-3dot6 col-xl-3gdot5 mb-3">
                      <span className="d-block text-gray-1 font-weight-normal text-left mb-0">
                        Child
                      </span>
                      {/* Select */}
                      <div className="d-flex align-items-center">
                        <i className="rlr-icon-font flaticon-add-user text-primary "></i>
                        <select
                          id="ChildNo"
                          name="ChildNo"
                          className="form_control"
                          style={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                            borderTop: "0px",
                            borderRight: "0px",
                            borderLeft: "0px",
                          }}
                          title="Child"
                          data-style=""
                          data-live-search="true"
                          data-searchbox-classes="input-group-sm"
                          value={childrenCruises}
                          onChange={(e) => setChildrenCruises(e.target.value)}
                        >
                          <option value="0" selected>
                            {" "}
                            0
                          </option>
                          <option value="1"> 1 Child</option>
                          <option value="2"> 2 Child</option>
                        </select>
                      </div>
                      {/* End Select */}
                    </div>
                    <div className="col-sm-12 ">
                      <button
                        type="submit"
                        className="btn btn-primary w-100 border-radius-3 mb-xl-0 mb-lg-1 transition-3d-hover"
                      >
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                          className="mr-2"
                        />
                        Search
                      </button>
                    </div>
                  </div>
                  {/* End Checkbox */}
                </form>

                {isMobile && (
                  <Button
                    onClick={() => setView(false)}
                    className="btn btn-danger d-flex justify-content-end mt-5 mx-auto"
                  >
                    <FontAwesomeIcon icon={faAngleDoubleUp} />
                  </Button>
                )}
              </div>
            </aside>
            <div className="rlr-search-results-page__product-list col-lg-9">
              <div className="row rlr-search-results-page__card-wrapper">
                {loading && scroll ? (
                  <>
                    <ScrollToTop />
                  </>
                ) : (
                  <Suspense fallback={<div>Loading...</div>}>
                    {cruises.cruises?.length > 0 ? (
                      <>
                        {" "}
                        {cruises.cruises?.map((cruise) => (
                          <div
                            className="col-md-6 col-lg-4"
                            key={cruise.cruiseUrl}
                          >
                            <article
                              className="rlr-product-card rlr-product-card--v3"
                              itemScope=""
                              itemType="en/dive-in-colors"
                            >
                              <figure className="rlr-product-card__image-wrapper">
                                <div className="swiper rlr-js-product-multi-image-swiper">
                                  <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                      <Link
                                        to={`/nile-cruises/${cruise.cruiseUrl}`}
                                      >
                                        <img
                                          itemProp="image"
                                          data-sizes="auto"
                                          src={cruise.cruisePhoto}
                                          data-srcset={cruise.cruisePhoto}
                                          placeholder={cruise.cruisePhoto}
                                          className="lazyload"
                                          alt={cruise.cruiseName}
                                        />
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </figure>
                              <div className="rlr-product-card__detail-wrapper rlr-js-detail-wrapper">
                                <header className="rlr-product-card__header">
                                  <div>
                                    <Link
                                      className="rlr-product-card__anchor-title"
                                      to={`/nile-cruises/${cruise.cruiseUrl}`}
                                    >
                                      <h2
                                        className="rlr-product-card__title"
                                        itemProp="name"
                                      >
                                        {cruise.cruiseName}
                                      </h2>
                                    </Link>
                                    <div className="d-flex ">
                                      <Link
                                        className="rlr-product-card__anchor-cat d-flex"
                                        style={{
                                          color: "#000",
                                          paddingRight: "8px",
                                        }}
                                        to={`/nile-cruises/${cruise.cruiseUrl}`}
                                      >
                                        <i className="rlr-icon-font flaticon-carbon-location me-1">
                                          {" "}
                                        </i>
                                        <span
                                          className="rlr-product-card__sub-title"
                                          style={{ color: "#000" }}
                                        >
                                          {" "}
                                          {cruise.cruiseDestination}
                                        </span>
                                      </Link>

                                      {/* <Link
                                        className="rlr-product-card__anchor-cat d-flex"
                                        style={{ color: "#000" }}
                                        to={`/nile-cruises/${cruise.cruiseUrl}`}
                                      >
                                        <i className="rlr-icon-font flaticon-three-o-clock-clock me-1">
                                          {" "}
                                        </i>
                                        <span
                                          className="rlr-product-card__sub-title"
                                          style={{ color: "#000" }}
                                        >
                                          {cruise.fullDuration}
                                        </span>
                                      </Link> */}
                                    </div>
                                  </div>
                                </header>

                                <div className="rlr-product-card__details">
                                  <div
                                    className="rlr-product-card__prices"
                                    itemProp="offers"
                                    itemScope=""
                                    itemType="https://schema.org/Offer"
                                  >
                                    <span className="rlr-product-card__from">
                                      from{" "}
                                    </span>

                                    <span className="rlr-product-card__price">
                                      {" "}
                                      <mark itemProp="priceCurrency"> </mark>
                                      <mark itemProp="price">
                                        {cruise.priceCurrency}{" "}
                                        {cruise.priceDouble}{" "}
                                      </mark>{" "}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </article>
                          </div>
                        ))}
                        <div id="PaggingContainer" className="text-center">
                          <div className="pagination-container">
                            <ul className="pagination">
                              <li
                                className="PagedList-skipToPrevious"
                                onClick={handlePreviousPage}
                                style={{
                                  visibility:
                                    pageIndex <= cruises.totalPages &&
                                    pageIndex > 1
                                      ? "visible"
                                      : "hidden",
                                }}
                              >
                                {" "}
                                <Link rel="prev">«</Link>
                              </li>
                              {Array.from(
                                { length: cruises.totalPages },
                                (_, index) => (
                                  <li
                                    className={
                                      index + 1 === pageIndex ? "active" : ""
                                    }
                                    key={index}
                                    onClick={() => {
                                      handlePageChange(index + 1);
                                    }}
                                  >
                                    <Link>{index + 1}</Link>
                                  </li>
                                )
                              )}

                              <li
                                className="PagedList-skipToNext"
                                onClick={handleNextPage}
                                style={{
                                  visibility:
                                    pageIndex === cruises.totalPages
                                      ? "hidden"
                                      : "visible",
                                }}
                              >
                                <Link rel="next">»</Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </>
                    ) : (
                      <p className="SiteFontP text-travco">
                        No Products Matches Your Search!
                      </p>
                    )}
                  </Suspense>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cruises;
