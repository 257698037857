import { useMediaQuery } from "@mui/material";
import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchAffiliatedCompanies } from "../../data/affiliated-companies/affiliatedCompaniesSlice";
import { Helmet } from "react-helmet";

const AffiliatedCompanies = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const { affiliatedCompanies, loading, error } = useSelector(
    (state) => state.affiliatedCompanies
  );
  useEffect(() => {
    dispatch(fetchAffiliatedCompanies(language));
  }, [dispatch, language]);
  return (
    <>
      <Helmet>
        <title>{affiliatedCompanies.pageDetails?.pageMetatagTitle}</title>
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href={`https://travco.com/${language}/affiliated-companies`}
        />
        <meta
          name="description"
          content={affiliatedCompanies.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={affiliatedCompanies.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={affiliatedCompanies.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={affiliatedCompanies.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={affiliatedCompanies.pageDetails?.metatagDescriptionTwitter}
        />
      </Helmet>{" "}
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>Loading...</div>}>
                    <img
                      src={
                        isMobile
                          ? affiliatedCompanies.pageDetails?.pageBannerMobile
                          : isTablet
                          ? affiliatedCompanies.pageDetails?.pageBannerTablet
                          : affiliatedCompanies.pageDetails?.pageBannerPc
                      }
                      alt={affiliatedCompanies.pageDetails?.altBanner}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className="rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {affiliatedCompanies.pageDetails?.pageTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="rlr-search-results-page__breadcrumb-section">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb rlr-breadcrumb__items">
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/">Home </Link>
              </li>
              <li
                className="breadcrumb-item rlr-breadcrumb__item active"
                aria-current="page"
              >
                {affiliatedCompanies.pageDetails?.pageTitle}
              </li>
            </ol>
          </nav>
          <div className="rlr-icon-text">
            <i className="rlr-icon-font flaticon-phone-receiver-silhouette">
              {" "}
            </i>{" "}
            <Link
              to="tel:(+202) 3854 1010"
              className="rlr-search-results-page__phone"
            >
              Questions? (+202) 3854 1010{" "}
            </Link>
          </div>
        </div>
      </div>
      <section className="accommodation-section pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {affiliatedCompanies.affiliatedCompanies?.map((item, index) =>
                index % 2 === 0 ? (
                  <div
                    key={index}
                    className="single-service-item-three mb-50 wow fadeInLeft"
                    style={{
                      visibility: "visible",
                      animationName: "fadeInLeft",
                    }}
                  >
                    <div className="img-holder">
                      <img
                        src={item.affiliatedCompaniesPhoto}
                        alt={item.affiliatedCompaniesName}
                        style={{ borderRadius: "188px 0 0 0" }}
                      />
                    </div>
                    <div className="text" style={{ textAlign: "center" }}>
                      <h3 className="rlr-section__title--main">
                        <Link
                          to={`/affiliated-companies/${item.affiliatedCompaniesUrl}`}
                        >
                          {item.affiliatedCompaniesName}
                        </Link>
                      </h3>
                      <p style={{ paddingTop: "20px" }}>
                        {item.affiliatedCompaniesIntro}
                      </p>
                      <div className="meta">
                        <Link
                          to={`/affiliated-companies/${item.affiliatedCompaniesUrl}`}
                          className="icon"
                        >
                          <img
                            src={item.affiliatedCompaniesLogo}
                            style={{ maxWidth: "200px" }}
                            className="img-fluid  d-block"
                            alt=""
                          />
                        </Link>
                        <Link
                          to={`/affiliated-companies/${item.affiliatedCompaniesUrl}`}
                          className="main-btn btn-green"
                        >
                          Learn More{" "}
                          <i className="rlr-icon-font flaticon-chevron"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    key={index}
                    className="single-service-item-three mb-50 wow fadeInRight"
                    style={{
                      visibility: "visible",
                      animationName: "fadeInLeft",
                    }}
                  >
                    <div className="img-holder">
                      <img
                        src={item.affiliatedCompaniesPhoto}
                        alt={item.affiliatedCompaniesName}
                        style={{ borderRadius: "0 188px 0 0" }}
                      />
                    </div>
                    <div className="text" style={{ textAlign: "center" }}>
                      <h3 className="rlr-section__title--main">
                        <Link
                          to={`/affiliated-companies/${item.affiliatedCompaniesUrl}`}
                        >
                          {item.affiliatedCompaniesName}
                        </Link>
                      </h3>
                      <p style={{ paddingTop: "20px" }}>
                        {item.affiliatedCompaniesIntro}
                      </p>
                      <div className="meta">
                        <Link
                          to={`/affiliated-companies/${item.affiliatedCompaniesUrl}`}
                          className="icon"
                        >
                          <img
                            src={item.affiliatedCompaniesLogo}
                            style={{ maxWidth: "200px" }}
                            className="img-fluid  d-block"
                            alt=""
                          />
                        </Link>
                        <Link
                          to={`/affiliated-companies/${item.affiliatedCompaniesUrl}`}
                          className="main-btn btn-green"
                        >
                          Learn More{" "}
                          <i className="rlr-icon-font flaticon-chevron"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AffiliatedCompanies;
