import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Link, useNavigate, useParams } from "react-router-dom";
import { fetchBookingTour } from "../../data/tours/bookingTourSlice";
import BookingStepForProducts from "../../components/booking-steps/BookingStepForProducts";
import { postCstData } from "../../data/tours/postCstDataSlice";
import LoginAndRegisterForm from "./../../components/LoginAndRegisterForm";
const BookingTour = () => {
  const { productUrl } = useParams();
  const [show, setShow] = useState(false);
  const userToken = localStorage.getItem("userToken");
  const googleAccessToken = localStorage.getItem("googleAccessToken");
  const loginToken = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const currency = useSelector((state) => state.currency.currency);
  const language = useSelector((state) => state.language.language);
  const { bookingTour } = useSelector((state) => state.bookingTour);
  const productId = localStorage.getItem("tour_productId");
  const numberOfchildrens1 = localStorage.getItem("tour_numberOfchildrens1");
  const numberOfchildrens2 = localStorage.getItem("tour_numberOfchildrens2");
  const numberOfAdults = localStorage.getItem("tour_adultNumber");
  const startDate = localStorage.getItem("tour_startDate");
  useEffect(() => {
    dispatch(
      fetchBookingTour({
        productId,
        numberOfchildrens1,
        currency,
        numberOfchildrens2,
        numberOfAdults,
        startDate,
        language,
      })
    );
  }, [
    dispatch,
    currency,
    numberOfchildrens1,
    numberOfchildrens2,
    productId,
    numberOfAdults,
    startDate,
    language,
  ]);
  const [activeTab, setActiveTab] = useState("login");
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [registerFirstName, setRegisterFirstName] = useState("");
  const [registerLastName, setRegisterLastName] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [registerConfirmPassword, setRegisterConfirmPassword] = useState("");

  const openCity = (event, tabName) => {
    setActiveTab(tabName);
  };

  const { profile } = useSelector((state) => state.profile);
  const [customerFirstName, setCustomerFirstName] = useState(
    profile.userInfo?.customerFirstName || ""
  );
  const [customerLastName, setCustomerLastName] = useState(
    profile.userInfo?.customerLastName || ""
  );
  const [customerEmailAddress, setCustomerEmailAddress] = useState(
    profile.userInfo?.customerEmailAddress || ""
  );
  const [customerMobile, setCustomerMobile] = useState(
    profile.userInfo?.customerMobile || ""
  );
  const [customerNationalityCode, setCustomerNationalityCode] = useState("");
  const [customerSpecialRequest, setCustomerSpecialRequest] = useState("");
  const [accommodationName, setAccommodationName] = useState("");
  const [languageGuide, setLanguageGuide] = useState("");

  const { cstDataForTour, loadingCstData, errorCstData } = useSelector(
    (state) => state.cstDataForTour
  );
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      productId: bookingTour.productDetails?.productId,
      numberOfAdults: bookingTour.priceModel?.var_AdultsNumbers,
      numberOfchildrens2: bookingTour.priceModel?.var_ChildNumbers2,
      numberOfChildrens6: bookingTour.priceModel?.var_ChildNumbers6,
      orderTripDate: startDate,
      customerFirstName,
      customerLastName,
      customerMobile,
      customerEmailAddress,
      accomdationHotelName: accommodationName,
      customerNationalityCode,
      productLanguageGuide: languageGuide,
      orderSpecialRequest: customerSpecialRequest,
      currency,
    };

    dispatch(postCstData({ cstData: formData, language }));

    // After successful submission, reset form fields

    setCustomerNationalityCode("");
    setCustomerSpecialRequest("");
    setLanguageGuide("");
    setAccommodationName("");
  };
  useEffect(() => {
    if (
      !loadingCstData &&
      cstDataForTour.paymentUrl &&
      cstDataForTour.success
    ) {
      window.location.href = cstDataForTour.paymentUrl;
    }
  }, [cstDataForTour.paymentUrl, loadingCstData, cstDataForTour.success]);
  return (
    <>
      <Helmet>
        <title>
          {bookingTour.pageDetails?.pageMetatagTitle || "tours activities"}
        </title>

        <link
          rel="canonical"
          href={`https://travco.com/${language}/tour/${productUrl}/bookingTour`}
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content={bookingTour.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={bookingTour.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={bookingTour.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={bookingTour.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={bookingTour.pageDetails?.metatagDescriptionTwitter}
        />
        <style type="text/css">
          {`

          .form-control{
          height:50px;
          border-color:#0000003b;
          }
             .form-v8-content .form-row .form-row-inner .label {
        position: absolute;
        top: -25px;
      
    }
    .form-v8-content .form-detail .form-row .input-text:focus + .label, .form-v8-content .form-detail .form-row .input-text:valid + .label {
        -webkit-transform: translateY(-10px) scale(1);
    }
    #register, #login {
        display: flex;
        justify-content: space-between !important;
        flex-wrap: wrap !important;
    }
        .react-datepicker-wrapper{
        position:relative;
        width:100%;
        }
        .input-group-prepend span{
        position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
        }
    .react-datepicker-popper{
    z-index: 100}
   @media screen and (min-width:767px){
    .w-45 {
        width: 45% !important;
    }}
        .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
        border-color:#e60000 !important;
        }

   

          `}
        </style>
        <link
          href="TITSolutions/WS/assets/login-booking/css/sourcesanspro-font.css"
          rel="stylesheet"
        />
        <link
          href="TITSolutions/WS/assets/login-booking/css/style.css"
          rel="stylesheet"
        />
        <link
          href="TITSolutions/WS/assets/line-awesome-3.css"
          rel="stylesheet"
        ></link>
      </Helmet>
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>Loading...</div>}>
                    <img
                      src={
                        isMobile
                          ? bookingTour.pageDetails?.pageBannerMobile
                          : isTablet
                          ? bookingTour.pageDetails?.pageBannerTablet
                          : bookingTour.pageDetails?.pageBannerPc
                      }
                      alt={bookingTour.pageDetails?.pageTitle}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <BookingStepForProducts />
      {!show && !userToken && !loginToken && !googleAccessToken ? (
        <LoginAndRegisterForm setShow={setShow} />
      ) : (
        <section className="mt-lg-5 pt-lg-5">
          {" "}
          <div className="rlr-section__content--md-top mt-5 pt-lg-5">
            <div className="rlr-checkout">
              <article
                id="post-7"
                className="post-7 page type-page status-publish hentry"
              >
                <div className="container-xxl">
                  <div className="entry-content">
                    <div className="woocommerce">
                      <div className="woocommerce-notices-wrapper"></div>
                      <form
                        className="checkout woocommerce-checkout d-flex justify-content-between flex-column-reverse flex-lg-row"
                        onSubmit={handleSubmit}
                      >
                        <div className="col2-set">
                          <div className="col-1">
                            <div className="woocommerce-billing-fields">
                              <div className="woocommerce-billing-fields__field-wrapper">
                                <h6 className="checkout__section-title">
                                  <span> Personal Information </span>
                                </h6>
                                <div className="checkout__personal-details">
                                  <div
                                    className="form-row form-row-first  "
                                    id="billing_first_name_field"
                                    data-priority="10"
                                  >
                                    <label
                                      htmlFor="billing_first_name"
                                      className=""
                                    >
                                      {" "}
                                      First name{" "}
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <input
                                        type="text"
                                        className="form-control border-1"
                                        name="CustomerFirstName"
                                        id="billing_first_name"
                                        required
                                        onChange={(e) =>
                                          setCustomerFirstName(e.target.value)
                                        }
                                        value={customerFirstName}
                                      />
                                    </span>
                                  </div>
                                  <div
                                    className="form-row form-row-last  "
                                    id="billing_last_name_field"
                                    data-priority="20"
                                  >
                                    <label
                                      htmlFor="billing_last_name"
                                      className=""
                                    >
                                      {" "}
                                      Last name{" "}
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <input
                                        type="text"
                                        className="form-control border-1"
                                        name="CustomerLastName"
                                        id="billing_last_name"
                                        onChange={(e) =>
                                          setCustomerLastName(e.target.value)
                                        }
                                        value={customerLastName}
                                        required
                                      />
                                    </span>
                                  </div>
                                  <div
                                    className="form-row form-row-wide   validate-phone"
                                    id="billing_phone_field"
                                    data-priority="100"
                                  >
                                    <label htmlFor="billing_phone" className="">
                                      {" "}
                                      Phone{" "}
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <input
                                        type="tel"
                                        className="form-control border-1"
                                        name="CustomerMobile"
                                        id="billing_phone"
                                        onChange={(e) =>
                                          setCustomerMobile(e.target.value)
                                        }
                                        value={customerMobile}
                                        required
                                      />
                                    </span>
                                  </div>
                                  <div
                                    className="form-row form-row-wide   validate-email"
                                    id="billing_email_field"
                                    data-priority="110"
                                  >
                                    <label htmlFor="billing_email" className="">
                                      {" "}
                                      Email{" "}
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <input
                                        type="email"
                                        className="form-control border-1"
                                        name="CustomerEmailAddress"
                                        id="billing_email"
                                        onChange={(e) =>
                                          setCustomerEmailAddress(
                                            e.target.value
                                          )
                                        }
                                        value={customerEmailAddress}
                                        required
                                      />
                                    </span>
                                  </div>
                                  <div
                                    className="form-row form-row-wide   validate-email"
                                    id="billing_email_field"
                                    data-priority="110"
                                  >
                                    <label htmlFor="billing_email" className="">
                                      {" "}
                                      Accommodation Hotel Name{" "}
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <input
                                        type="text"
                                        className="form-control border-1"
                                        name="CustomerEmailAddress"
                                        id="billing_email"
                                        onChange={(e) =>
                                          setAccommodationName(e.target.value)
                                        }
                                        value={accommodationName}
                                        required
                                      />
                                    </span>
                                  </div>

                                  <div className="form-row form-row-wide address-field update_totals_on_change  ">
                                    <label htmlFor="nationality">
                                      {" "}
                                      Customer Nationality{" "}
                                    </label>
                                    <div className="woocommerce-input-wrapper">
                                      <select
                                        name="CustomerNationality"
                                        id="nationality"
                                        required
                                        className="form-control border-1"
                                        onChange={(e) =>
                                          setCustomerNationalityCode(
                                            e.target.value
                                          )
                                        }
                                        value={customerNationalityCode}
                                      >
                                        <option value="" hidden>
                                          Select country
                                        </option>
                                        {bookingTour.nationalities?.map(
                                          (nationality) => (
                                            <option
                                              value={nationality.nationalityId}
                                              key={nationality.nationalityId}
                                            >
                                              {nationality.nationalityName}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="form-row form-row-last  ">
                                    <label htmlFor="airline" className="">
                                      {" "}
                                      Guide Language
                                    </label>
                                    <select
                                      id="airline"
                                      className="form-control border-1"
                                      onChange={(e) =>
                                        setLanguageGuide(e.target.value)
                                      }
                                      value={languageGuide}
                                      required
                                    >
                                      <option hidden value="">
                                        Select
                                      </option>
                                      {bookingTour.languageGuides?.map(
                                        (item) => (
                                          <option
                                            value={item.languageSpokenId}
                                            key={item.languageSpokenId}
                                          >
                                            {item.languageSpokenName}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                  <div
                                    className="form-row address-field street-address-1   form-row-wide"
                                    id="billing_address_1_field"
                                    data-priority="50"
                                  >
                                    <label
                                      htmlFor="billing_address_1"
                                      className=""
                                    >
                                      {" "}
                                      Special Requirements{" "}
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <textarea
                                        className="form-control border-1 pt-3"
                                        rows="4"
                                        id="billing_SpeciaOrder"
                                        name="OrderSpecialRequest"
                                        onChange={(e) =>
                                          setCustomerSpecialRequest(
                                            e.target.value
                                          )
                                        }
                                        value={customerSpecialRequest}
                                        style={{
                                          marginTop: "10px",
                                          height: "235px",
                                        }}
                                      ></textarea>
                                      <button
                                        type="submit"
                                        className="button alt mt-5"
                                        name="woocommerce_checkout_place_order"
                                        id="place_order"
                                        value="Place order"
                                        data-value="Place order"
                                      >
                                        {loadingCstData
                                          ? "Loading"
                                          : " Place Order"}
                                      </button>
                                    </span>
                                    <p className="text-danger text-center mt-3 fw-bold">
                                      {errorCstData && errorCstData}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id="order_review"
                          className="woocommerce-checkout-review-order mb-5 mb-lg-0"
                        >
                          <table className="shop_table woocommerce-checkout-review-order-table">
                            <tbody>
                              <tr className="cart_item">
                                <td className="product-name">
                                  <h6 className="cart-item-title">
                                    Your Order
                                  </h6>
                                  <ul className="cart-item-card">
                                    <li className="cart-item-card__header">
                                      <div>
                                        <img
                                          className="cart-item-card__img"
                                          src={
                                            bookingTour.productDetails
                                              ?.productPhoto
                                          }
                                          alt={
                                            bookingTour.productDetails
                                              ?.productName
                                          }
                                        />
                                      </div>
                                      <div>
                                        <p className="cart-item-card__title">
                                          {
                                            bookingTour.productDetails
                                              ?.productName
                                          }
                                        </p>
                                        <p className="cart-item-card__date">
                                          <span>Date </span>
                                          <span>
                                            {" "}
                                            {bookingTour.priceModel?.dateValues}
                                          </span>
                                        </p>
                                      </div>
                                    </li>

                                    <li className="cart-item-card__item">
                                      <div className="cart-item-card__iconcontainer">
                                        <span>
                                          <i className="rlr-icon-font flaticon-carbon-user"></i>
                                        </span>
                                      </div>
                                      <div className="cart-item-card__item-title">
                                        <p>Adult</p>
                                        <p>
                                          <b>
                                            {
                                              bookingTour.priceModel
                                                ?.priceCurrency
                                            }{" "}
                                            {
                                              bookingTour.priceModel
                                                ?.var_AdultPrice
                                            }{" "}
                                          </b>
                                        </p>
                                      </div>
                                      <p className="cart-item-card__item-number">
                                        <span className="times"> x </span>
                                        {
                                          bookingTour.priceModel
                                            ?.var_AdultsNumbers
                                        }
                                      </p>
                                      <p className="cart-item-card__item-price">
                                        {bookingTour.priceModel?.priceCurrency}{" "}
                                        {
                                          bookingTour.priceModel
                                            ?.var_AdultPriceTotal
                                        }
                                      </p>
                                    </li>
                                    {bookingTour.priceModel?.var_ChildNumbers2 >
                                      0 && (
                                      <li className="cart-item-card__item">
                                        <div className="cart-item-card__iconcontainer">
                                          <span>
                                            <i className="rlr-icon-font flaticon-carbon-user"></i>
                                          </span>
                                        </div>
                                        <div className="cart-item-card__item-title">
                                          <p>Children Age 2-5.99</p>
                                          <p>
                                            <b>
                                              {
                                                bookingTour.priceModel
                                                  ?.priceCurrency
                                              }{" "}
                                              {
                                                bookingTour.priceModel
                                                  ?.var_ChildPrice2
                                              }{" "}
                                            </b>
                                          </p>
                                        </div>
                                        <p className="cart-item-card__item-number">
                                          <span className="times"> x </span>
                                          {
                                            bookingTour.priceModel
                                              ?.var_ChildNumbers2
                                          }
                                        </p>
                                        <p className="cart-item-card__item-price">
                                          {
                                            bookingTour.priceModel
                                              ?.priceCurrency
                                          }{" "}
                                          {
                                            bookingTour.priceModel
                                              ?.var_ChildPriceTotal2
                                          }
                                        </p>
                                      </li>
                                    )}
                                    {bookingTour.priceModel?.var_ChildNumbers6 >
                                      0 && (
                                      <li className="cart-item-card__item">
                                        <div className="cart-item-card__iconcontainer">
                                          <span>
                                            <i className="rlr-icon-font flaticon-carbon-user"></i>
                                          </span>
                                        </div>
                                        <div className="cart-item-card__item-title">
                                          <p>Children Age 6-11.99</p>
                                          <p>
                                            <b>
                                              {
                                                bookingTour.priceModel
                                                  ?.priceCurrency
                                              }{" "}
                                              {
                                                bookingTour.priceModel
                                                  ?.var_ChildPrice6
                                              }
                                            </b>
                                          </p>
                                        </div>
                                        <p className="cart-item-card__item-number">
                                          <span className="times"> x </span>
                                          {
                                            bookingTour.priceModel
                                              ?.var_ChildNumbers6
                                          }
                                        </p>
                                        <p className="cart-item-card__item-price">
                                          {
                                            bookingTour.priceModel
                                              ?.priceCurrency
                                          }{" "}
                                          {
                                            bookingTour.priceModel
                                              ?.var_ChildPriceTotal6
                                          }
                                        </p>
                                      </li>
                                    )}
                                  </ul>
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr className="order-total">
                                <th>Total payment</th>
                                <td>
                                  <strong>
                                    <span className="woocommerce-Price-amount amount">
                                      <bdi>
                                        <span className="woocommerce-Price-currencySymbol"></span>
                                        {bookingTour.priceModel?.priceCurrency}{" "}
                                        {bookingTour.priceModel?.totalPrice}
                                      </bdi>
                                    </span>
                                  </strong>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default BookingTour;
