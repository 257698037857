import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
import { fetchHotelsOrders } from "../../data/orders/hotelsOrdersSlice";

const HotelsOrders = () => {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const language = useSelector((state) => state.language.language);

  const { hotelsOrders, loading, error } = useSelector(
    (state) => state.hotelsOrders
  );

  useEffect(() => {
    dispatch(fetchHotelsOrders({ orderId, language }));
  }, [orderId, dispatch, language]);

  return (
    <>
      <Helmet>
        <title>{hotelsOrders?.pageDetails?.pageTitle || "Hotel Order"}</title>
        <link
          rel="canonical"
          href={`https://travco.com/${language}/orderHotelDetails/${orderId}`}
        />
        <meta
          name="description"
          content={hotelsOrders.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={hotelsOrders.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={hotelsOrders.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={hotelsOrders.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={hotelsOrders.pageDetails?.metatagDescriptionTwitter}
        />
        <style type="text/css">
          {`

          .form-control{
          height:50px;
          border-color:#0000003b;
          }
             .form-v8-content .form-row .form-row-inner .label {
        position: absolute;
        top: -25px;
      
    }
    .form-v8-content .form-detail .form-row .input-text:focus + .label, .form-v8-content .form-detail .form-row .input-text:valid + .label {
        -webkit-transform: translateY(-10px) scale(1);
    }
    #register, #login {
        display: flex;
        justify-content: space-between !important;
        flex-wrap: wrap !important;
    }
        .react-datepicker-wrapper{
        position:relative;
        width:100%;
        }
        .input-group-prepend span{
        position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
        }
    .react-datepicker-popper{
    z-index: 100}
   @media screen and (min-width:767px){
    .w-45 {
        width: 45% !important;
    }}
        .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
        border-color:#e60000 !important;
        }

   

          `}
        </style>
        <link
          href="TITSolutions/WS/assets/login-booking/css/sourcesanspro-font.css"
          rel="stylesheet"
        />
        <link
          href="TITSolutions/WS/assets/login-booking/css/style.css"
          rel="stylesheet"
        />
        <link
          href="TITSolutions/WS/assets/line-awesome-3.css"
          rel="stylesheet"
        ></link>
      </Helmet>
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>Loading...</div>}>
                    <img
                      src={
                        isMobile
                          ? hotelsOrders?.pageDetails?.pageBannerMobile
                          : isTablet
                          ? hotelsOrders?.pageDetails?.pageBannerTablet
                          : hotelsOrders?.pageDetails?.pageBannerPc
                      }
                      alt={hotelsOrders?.pageDetails?.pageTitle}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="mt-5 pt-2">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12">
              <div class="mb-5 shadow-soft bg-white rounded-sm ">
                <div className="col-lg-9 mx-auto">
                  {" "}
                  <div class="pt-4 pb-5 px-0 border-bottom">
                    <h5
                      id="scroll-description"
                      class="font-size-21 font-weight-bold text-dark mb-2"
                    >
                      Traveler Information
                    </h5>

                    <ul class="list-unstyled font-size-1 mb-0 font-size-16">
                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">Booking number </span>
                        <span class="text-secondary text-right">
                          {hotelsOrders?.orderDetails?.bookingTitrefrence}
                        </span>
                      </li>

                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">First name </span>
                        <span class="text-secondary text-right">
                          {hotelsOrders?.orderDetails?.customerFirstName}{" "}
                        </span>
                      </li>

                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">Last name </span>
                        <span class="text-secondary text-right">
                          {hotelsOrders?.orderDetails?.customerLastName}{" "}
                        </span>
                      </li>

                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">E-mail address </span>
                        <span class="text-secondary text-right">
                          {hotelsOrders?.orderDetails?.customerEmailAddress}
                        </span>
                      </li>
                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">Mobile </span>
                        <span class="text-secondary text-right">
                          {hotelsOrders?.orderDetails?.customerMobile}{" "}
                        </span>
                      </li>

                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">Nationality </span>
                        <span class="text-secondary text-right">
                          {hotelsOrders?.orderDetails?.customerNationality}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div class="pt-4 pb-5 px-0 border-bottom">
                    <h5
                      id="scroll-description"
                      class="font-size-21 font-weight-bold text-dark mb-2"
                    >
                      Booking Information
                    </h5>

                    <ul class="list-unstyled font-size-1 mb-0 font-size-16">
                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">Hotel Name </span>
                        <span class="text-secondary text-right">
                          {hotelsOrders?.hotelDetails?.hotelName}
                        </span>
                      </li>

                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">Hotel Address </span>
                        <span class="text-secondary text-right">
                          {hotelsOrders?.hotelDetails?.hotelAddress}
                        </span>
                      </li>

                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">Check In </span>
                        <span class="text-secondary text-right">
                          {hotelsOrders?.orderDetails?.bookingStartDate}
                        </span>
                      </li>

                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">Check Out </span>
                        <span class="text-secondary text-right">
                          {hotelsOrders?.orderDetails?.bookingEndDate}
                        </span>
                      </li>

                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">Number of Rooms </span>
                        <span class="text-secondary text-right">
                          {" "}
                          {hotelsOrders?.orderDetails?.numberofRooms}
                        </span>
                      </li>

                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">
                          Number of Adults{" "}
                        </span>
                        <span class="text-secondary text-right">
                          {hotelsOrders?.orderDetails?.numberOfAdults}
                        </span>
                      </li>
                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">Number of Child </span>
                        <span class="text-secondary text-right">
                          {hotelsOrders?.orderDetails?.numberofChild}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div class="pt-4 pb-5 px-0 border-bottom">
                    <h5
                      id="scroll-description"
                      class="font-size-21 font-weight-bold text-dark mb-2"
                    >
                      Room(s) Details
                    </h5>
                    <table class="table table-bordered">
                      <tr>
                        <td>Room</td>
                        <td align="center">Adults No</td>
                        <td align="center">Child No</td>
                      </tr>
                      {hotelsOrders?.orderJuniperRooms?.map((room, index) => (
                        <tr key={index}>
                          <td>{room.roomName}</td>
                          <td align="center">{room.adultNo}</td>
                          <td align="center">{room.childNo}</td>
                        </tr>
                      ))}
                    </table>
                  </div>
                  <div class="pt-4 pb-5 px-0 border-bottom">
                    <h5
                      id="scroll-description"
                      class="font-size-21 font-weight-bold text-dark mb-2"
                    >
                      Payment Information
                    </h5>

                    <ul class="list-unstyled font-size-1 mb-0 font-size-16">
                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">
                          Transaction Refrence{" "}
                        </span>
                        <span class="text-secondary text-right">
                          {hotelsOrders?.orderDetails?.payTabTranRef}
                        </span>
                      </li>

                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">Total Amount: </span>
                        <span class="text-secondary text-right">
                          {hotelsOrders?.orderDetails?.payTabCartAmount}{" "}
                          {hotelsOrders?.orderDetails?.payTabCartCurrency}
                        </span>
                      </li>

                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">Card </span>
                        <span class="text-secondary text-right">
                          {
                            hotelsOrders?.orderDetails
                              ?.payTabPaymentInfoCardScheme
                          }{" "}
                          (
                          {
                            hotelsOrders?.orderDetails
                              ?.payTabPaymentInfoPaymentDescription
                          }
                          )
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HotelsOrders;
