import { useMediaQuery } from "@mui/material";
import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNewsDetails } from "../../data/news/newsDetailsSlice";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const NewsDetails = () => {
  const { newsUrl } = useParams();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const { newsDetails, loading, error } = useSelector(
    (state) => state.newsDetails
  );
  useEffect(() => {
    dispatch(fetchNewsDetails({ newsUrl, language }));
  }, [dispatch, newsUrl, language]);
  return (
    <>
      <Helmet>
        <title>{newsDetails.pageDetails?.pageMetatagTitle || "News"}</title>
        <link
          rel="canonical"
          href={`https://travco.com/${language}/mice/travco-news/${newsUrl}`}
        />
        <meta
          name="description"
          content={newsDetails.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={newsDetails.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={newsDetails.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={newsDetails.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={newsDetails.pageDetails?.metatagDescriptionTwitter}
        />
      </Helmet>
      <section>
        {" "}
        <div className="outter">
          <div
            id="rlr_banner_slider"
            className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
          >
            <div className="splide__track rlr-banner-splide__track">
              <ul className="splide__list height-internal-pages">
                <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                  <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                    <div className="overlay-banner"></div>
                    <Suspense fallback={<div>Loading...</div>}>
                      {" "}
                      <img
                        className="rlr-banner-splide__banner-img"
                        src={
                          isMobile
                            ? newsDetails.pageDetails?.pageBannerMobile
                            : isTablet
                            ? newsDetails.pageDetails?.pageBannerTablet
                            : newsDetails.pageDetails?.pageBannerPc
                        }
                        alt={newsDetails.pageDetails?.pageTitle}
                        loading="lazy"
                      />
                    </Suspense>
                  </div>
                  <article className="rlr-banner-splide__content-wrapper container">
                    <div className="row justify-content-start">
                      <div className="col-xl-9">
                        <div className="rlr-banner-splide__header">
                          <h2
                            className="rlr-banner-splide__slogan rlr-section__title--main "
                            style={{ textShadow: "1px 1px 2px #000" }}
                          >
                            Travco News
                          </h2>
                        </div>
                      </div>
                    </div>
                  </article>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="rlr-search-results-page__breadcrumb-section">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb rlr-breadcrumb__items">
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/">Home </Link>
              </li>
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/travco-news">Travco News </Link>
              </li>
              <li
                className="breadcrumb-item rlr-breadcrumb__item active"
                aria-current="page"
              >
                {" "}
                {newsDetails.pageDetails?.pageTitle}{" "}
              </li>
            </ol>
          </nav>
          <div className="rlr-icon-text">
            <i className="rlr-icon-font flaticon-phone-receiver-silhouette">
              {" "}
            </i>{" "}
            <Link
              to="tel:(+202) 3854 1010"
              className="rlr-search-results-page__phone"
            >
              Questions? (+202) 3854 1010{" "}
            </Link>
          </div>
        </div>
      </div>
      <section className="blog-details-section pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7">
              <div className="blog-details-wrapper">
                <div className="blog-post mb-60 wow fadeInUp">
                  <div className="post-thumbnail">
                    <img
                      src={newsDetails.newsDetails?.newsPhoto}
                      alt={newsDetails.newsDetails?.newsTitleSys}
                      loading="lazy"
                    />
                  </div>
                  <div className="post-meta">
                    <ul>
                      <li>
                        <span>
                          <Link to="#.">
                            <i className="rlr-icon-font flaticon-calendar"></i>{" "}
                            {newsDetails.newsDetails?.newsDateTime}
                          </Link>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="main-post" style={{ borderBottom: "0" }}>
                    <div className="entry-content">
                      <h3 className="title">
                        {newsDetails.newsDetails?.newsTitleSys}
                      </h3>
                      <div
                        className="SiteFontP"
                        dangerouslySetInnerHTML={{
                          __html: newsDetails.newsDetails?.newsDetails,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="sidebar-widget-area pl-lg-40">
                <div className="sidebar-widget recent-post-widget mb-40 wow fadeInUp">
                  <h5 className="widget-title">Recent News </h5>
                  <ul className="recent-post-list">
                    {newsDetails.recentNews?.map((news) => (
                      <li className="post-thumbnail-content" key={news.newsUrl}>
                        <img
                          src={news.newsPhoto}
                          alt={news.newsTitleSys}
                          loading="lazy"
                        />
                        <div className="post-title-date">
                          <h5>
                            <Link to={`/travco-news/${news.newsUrl}`}>
                              {news.newsTitleSys}
                            </Link>
                          </h5>
                          <span className="posted-on">
                            <i className="rlr-icon-font flaticon-calendar"></i>
                            <Link to={`/travco-news/${news.newsUrl}`}>
                              {news.newsDateTime}
                            </Link>
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewsDetails;
