import React, { useEffect, useState, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";

import { styled, useMediaQuery } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleUp,
  faClose,
  faMagnifyingGlass,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
} from "@fortawesome/free-solid-svg-icons";

import ScrollToTop from "../../ScrollToTop";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import BookingStepTwo from "../../components/booking-steps/cruises/BookingStepTwo";

import { fetchBookingCruises } from "../../data/cruises/bookingCruisesSlice";
const CruisesBooking = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const [view, setView] = useState(false);
  const dispatch = useDispatch();
  const [scroll, setScroll] = useState(false);
  const language = useSelector((state) => state.language.language);
  // cruises
  const cruises_ItineraryId = localStorage.getItem("cruises_ItineraryId");
  const cruises_startDateCruises = localStorage.getItem(
    "cruises_startDateCruises"
  );
  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };
  const cruises_adultsCruises = localStorage.getItem("cruises_adultsCruises");
  const cruises_childrenCruises = localStorage.getItem(
    "cruises_childrenCruises"
  );
  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [destinationCruises, setDestinationCruises] = useState("");
  const [availableDates, setAvailableDates] = useState([]);

  const [ItineraryId, setItineraryId] = useState(cruises_ItineraryId);
  const [startDateCruises, setStartDateCruises] = useState(
    cruises_startDateCruises
  );
  const [startDateCruisesMenu, setStartDateCruisesMenu] = useState(
    cruises_startDateCruises
  );
  const [endDateCruisesMenu, setEndDateCruisesMenu] = useState();
  const [adultsCruises, setAdultsCruises] = useState(cruises_adultsCruises);
  const [childrenCruises, setChildrenCruises] = useState(
    cruises_childrenCruises
  );

  const [AdultNo, setIAdultNo] = useState("");
  const [ChildNo, setIChildNo] = useState("");
  const handleItineraryChange = async (e) => {
    const itineraryTypeId = e.target.value;
    setItineraryId(itineraryTypeId);
    try {
      const response = await axios.get(
        `https://api.travco.com/apiTITSol/TTG24_NileCruise/GetOperationDatesByItineraryType?ItineraryTypeId=${itineraryTypeId}`
      );
      const dates = response.data.formatedDate.map((date) => new Date(date));
      setAvailableDates(dates);
      setStartDateCruises(addDays(dates[0], 7) || null);
      setStartDateCruisesMenu(addDays(dates[0], 7));
      setEndDateCruisesMenu(dates[dates.length - 1]);
    } catch (error) {
      console.error("Error fetching dates: ", error);
    }
  };

  const isDateAvailable = (date) => {
    return availableDates.some((d) => d.toDateString() === date.toDateString());
  };
  // search
  const [searchTerm, setSearchTerm] = useState("");
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const currency = useSelector((state) => state.currency.currency);

  const [Sort, setSort] = useState(1);
  const [selectedStyle, setSelectedStyle] = useState([]);
  const [selectedDestination, setSelectedDestination] = useState([]);
  const [groupSize, setGroupSize] = useState([]);
  const [durations, setDurations] = useState([]);
  const handleCheckboxChange = (event) => {
    const { checked, value } = event.target;
    if (checked) {
      setSelectedStyle((prevSelected) => [...prevSelected, value]);
    } else {
      setSelectedStyle((prevSelected) =>
        prevSelected.filter((item) => item !== value)
      );
    }
  };
  const handleDestinationsCheckboxChange = (event) => {
    const { checked, value } = event.target;
    if (checked) {
      setSelectedDestination((prevSelected) => [...prevSelected, value]);
    } else {
      setSelectedDestination((prevSelected) =>
        prevSelected.filter((item) => item !== value)
      );
    }
  };
  const { bookingCruises, loadingBooking } = useSelector(
    (state) => state.bookingCruises
  );
  useEffect(() => {
    dispatch(
      fetchBookingCruises({
        cruises_ItineraryId,
        cruises_startDateCruises,
        cruises_adultsCruises,
        cruises_childrenCruises,
        searchTerm,
        currency,
        language,
      })
    );
  }, [
    dispatch,
    searchTerm,
    language,
    cruises_ItineraryId,
    cruises_startDateCruises,
    cruises_adultsCruises,
    cruises_childrenCruises,
    currency,
  ]);
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      fetchBookingCruises({
        ItineraryId,
        cruises_startDateCruises,
        startDateCruises,
        adultsCruises,
        childrenCruises,
        searchTerm,
        currency,
        language,
      })
    );
    localStorage.setItem("cruises_ItineraryId", ItineraryId);
    localStorage.setItem(
      "cruises_startDateCruises",
      formatDate(startDateCruises)
    );
    localStorage.setItem("cruises_adultsCruises", adultsCruises);
    localStorage.setItem("cruises_childrenCruises", childrenCruises);

    // navigate(`cabins`);
  };
  return (
    <>
      <Helmet>
        <title>{bookingCruises.pageDetails?.pageMetatagTitle}</title>
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href={`https://travco.com/${language}/cruisesbooking`}
        />
        <meta
          name="description"
          content={bookingCruises.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={bookingCruises.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={bookingCruises.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={bookingCruises.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={bookingCruises.pageDetails?.metatagDescriptionTwitter}
        />
        <link rel="stylesheet" href="css/news.css" />
        <link
          href="TITSolutions/WS/assets/cruises-booking-left.css"
          rel="stylesheet"
        ></link>
        <link
          href="TITSolutions/WS/assets/BookingCruises.css"
          rel="stylesheet"
        />
        <style type="text/css">{`
        
        .react-datepicker-wrapper {
        flex: 1;
        }
        
        @media screen and  (max-width: 768px) {
    .rlr-product-filters__filters-wrapper .rlr-checkboxes {
        display: flex;
        justify-content: space-between;
        align-items: start;
        margin-top: 10px;
    }
}
 `}</style>
      </Helmet>{" "}
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>Loading...</div>}>
                    <img
                      placeholder={
                        isMobile
                          ? bookingCruises.pageDetails?.pageBannerMobile
                          : isTablet
                          ? bookingCruises.pageDetails?.pageBannerTablet
                          : bookingCruises.pageDetails?.pageBannerPc
                      }
                      src={
                        isMobile
                          ? bookingCruises.pageDetails?.pageBannerMobile
                          : isTablet
                          ? bookingCruises.pageDetails?.pageBannerTablet
                          : bookingCruises.pageDetails?.pageBannerPc
                      }
                      alt={bookingCruises.pageDetails?.altBanner}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className="rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {bookingCruises.pageDetails?.pageTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <BookingStepTwo />
      <section className="border-bottom container pt-5 pb-70" id="rooms">
        <div className="container">
          <div className="row rlr-search-results-page__product-details">
            {isMobile && (
              <Button
                className="btn btn-danger p-2 text-center w-100 d-flex justify-content-center align-items-baseline mb-5"
                onClick={() => setView(!view)}
              >
                <FontAwesomeIcon
                  icon={view ? faMagnifyingGlassMinus : faMagnifyingGlassPlus}
                  className="me-2 mb-2"
                />
                {view ? "" : "Adjust Your Search"}{" "}
              </Button>
            )}
            <aside
              className={`col-xl-3 rlr-search-results-page__sidebar ${
                isMobile && view && "view"
              }`}
              id="filter-mobile"
            >
              <div className="rlr-product-filters__filters-wrapper pb-5">
                {isMobile && (
                  <Button
                    onClick={() => setView(false)}
                    className="btn btn-danger d-flex justify-content-end mt-0 ms-auto"
                  >
                    <FontAwesomeIcon icon={faClose} />
                  </Button>
                )}
                <div className="border-bottom pb-4">
                  <div className="   mb-0 packages col-lg-5 w-100">
                    <span className="font-size-22 fs-3">Search</span>
                    <div
                      className="search-wrapper w-100 mt-3 "
                      style={{ position: "relative" }}
                    >
                      <SearchIconWrapper>
                        <SearchIcon sx={{ color: "#e60000" }} />
                      </SearchIconWrapper>
                      <input
                        className="form-control ps-5"
                        style={{
                          border: 0,
                          boxShadow: "0 0 1px 2px #dddddd94",
                        }}
                        placeholder="Search…"
                        value={searchTerm}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="row d-block nav-select d-lg-flex mb-lg-3   align-items-end">
                    <div className="col-sm-12 col-lg-3dot6 col-xl-3gdot5 mb-3  ">
                      <span className="d-block text-gray-1 font-weight-normal text-left mb-0">
                        Itinerary
                      </span>
                      <div className="d-flex align-items-center">
                        <i
                          className="icon flaticon-map-marker"
                          style={{ color: "#e60000" }}
                        >
                          {" "}
                        </i>
                        <select
                          required
                          id="TripTypeID"
                          name="TripTypeID"
                          className="form_control ps-1"
                          style={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                            borderTop: "0px",
                            borderRight: "0px",
                            borderLeft: "0px",
                          }}
                          title="Itinerary"
                          value={ItineraryId}
                          onChange={handleItineraryChange}
                        >
                          <option hidden value="">
                            Select Itinerary
                          </option>
                          <optgroup label="Nile Cruises">
                            {bookingCruises.itineraryTypes?.map((itinerary) => (
                              <option
                                value={itinerary.itinerariesTypeId}
                                key={itinerary.itinerariesTypeId}
                              >
                                {itinerary.itinerariesName}
                              </option>
                            ))}
                          </optgroup>
                        </select>
                      </div>
                    </div>

                    {/* Date Picker */}
                    <div className="col-sm-12 col-lg-3dot6 col-xl-3gdot5 my-3 ">
                      <span className="d-block text-gray-1 font-weight-normal text-left mb-0">
                        Date
                      </span>
                      <div className="border-bottom border-width-2 border-color-1">
                        <div
                          id="datepickerWrapperFromThree"
                          className="u-datepicker input-group d-flex align-items-center"
                        >
                          <div className="input-group-prepend">
                            <span className="d-flex align-items-center mr-2">
                              <i className="flaticon-calendar text-primary font-weight-semi-bold"></i>
                            </span>
                          </div>
                          <DatePicker
                            selected={startDateCruises}
                            onChange={(date) => setStartDateCruises(date)}
                            className=" border-0 ps-1 py-3"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/YYYY"
                            minDate={startDateCruisesMenu}
                            maxDate={endDateCruisesMenu}
                            filterDate={isDateAvailable}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-3 dot6 col-xl-3 gdot5 my-3  ">
                      <span className="d-block text-gray-1 font-weight-normal text-left mb-0">
                        Adults
                      </span>
                      {/* Select */}
                      <div className="d-flex align-items-center">
                        <i className="rlr-icon-font flaticon-add-user text-primary "></i>
                        <select
                          id="AdultNo"
                          name="AdultNo"
                          className="form_control"
                          style={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                            borderTop: "0px",
                            borderRight: "0px",
                            borderLeft: "0px",
                          }}
                          title="Adult"
                          data-style=""
                          data-live-search="true"
                          data-searchbox-classes="input-group-sm"
                          value={adultsCruises}
                          onChange={(e) => setAdultsCruises(e.target.value)}
                        >
                          <option value="1"> 1 Adult</option>
                          <option value="2" selected>
                            {" "}
                            2 Adults
                          </option>
                        </select>
                      </div>
                      {/* End Select */}
                    </div>
                    <div className="col-sm-12 col-lg-3dot6 col-xl-3gdot5 mb-3">
                      <span className="d-block text-gray-1 font-weight-normal text-left mb-0">
                        Child
                      </span>
                      {/* Select */}
                      <div className="d-flex align-items-center">
                        <i className="rlr-icon-font flaticon-add-user text-primary "></i>
                        <select
                          id="ChildNo"
                          name="ChildNo"
                          className="form_control"
                          style={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                            borderTop: "0px",
                            borderRight: "0px",
                            borderLeft: "0px",
                          }}
                          title="Child"
                          data-style=""
                          data-live-search="true"
                          data-searchbox-classes="input-group-sm"
                          value={childrenCruises}
                          onChange={(e) => setChildrenCruises(e.target.value)}
                        >
                          <option value="0" selected>
                            {" "}
                            0
                          </option>
                          <option value="1"> 1 Child</option>
                          <option value="2"> 2 Child</option>
                        </select>
                      </div>
                      {/* End Select */}
                    </div>
                    <div className="col-sm-12 ">
                      <button
                        type="submit"
                        className="btn btn-primary w-100 border-radius-3 mb-xl-0 mb-lg-1 transition-3d-hover"
                      >
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                          className="mr-2"
                        />
                        Search
                      </button>
                    </div>
                  </div>
                  {/* End Checkbox */}
                </form>

                {isMobile && (
                  <Button
                    onClick={() => setView(false)}
                    className="btn btn-danger d-flex justify-content-end mt-5 mx-auto"
                  >
                    <FontAwesomeIcon icon={faAngleDoubleUp} />
                  </Button>
                )}
              </div>
            </aside>
            <div className="rlr-search-results-page__product-list col-lg-9">
              <div className="row rlr-search-results-page__card-wrapper">
                {loadingBooking && scroll ? (
                  <>
                    <ScrollToTop />
                  </>
                ) : (
                  <Suspense fallback={<div>Loading...</div>}>
                    {bookingCruises.cruises?.length > 0 ? (
                      <>
                        {" "}
                        {bookingCruises.cruises?.map((cruise, index) => (
                          <div id="cruisesList" key={index}>
                            <div className="col-lg-12 col-xl-12 singleCruise">
                              <div className="card border-color-7 mb-5 overflow-hidden">
                                <div className="product-item__outer w-100">
                                  <div className="row">
                                    <article
                                      className="rlr-product-card rlr-product-card--v3  py-3"
                                      itemscope=""
                                      itemtype="https://schema.org/Product"
                                    >
                                      <figure className="rlr-product-card__image-wrapper col-4">
                                        <div className="swiper rlr-js-product-multi-image-swiper swiper-initialized swiper-horizontal">
                                          <Link
                                            to={cruise.cruisePhoto}
                                            data-fancybox={`cruise-view${index}`}
                                            className="swiper-slide"
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              marginRight: "30px",
                                            }}
                                          >
                                            <img
                                              itemprop="image-fluid"
                                              alt={cruise.cruiseNameSys}
                                              loading="lazy"
                                              src={cruise.cruisePhoto}
                                              className="img-fluid"
                                            />
                                          </Link>
                                        </div>
                                      </figure>
                                      <div
                                        className="rlr-product-card__detail-wrapper rlr-js-detail-wrapper col-7"
                                        style={{ borderLeft: "0" }}
                                      >
                                        <header className="rlr-product-card__header">
                                          <div>
                                            <Link
                                              className="rlr-product-card__anchor-title"
                                              to={`/${cruise.cruiseUrl}/cabinsbooking/${cruise.opid}`}
                                            >
                                              <h2
                                                className="rlr-product-card__title"
                                                itemprop="name"
                                              >
                                                {cruise.cruiseNameSys} -{" "}
                                                <small>
                                                  {cruise.itineraryNameSys}
                                                </small>
                                              </h2>
                                            </Link>
                                            <div>
                                              <span className="rlr-product-card__sub-title">
                                                {cruise.cabinNumbers} Cabins
                                              </span>
                                              <span className="rlr-product-card__sub-title">
                                                |
                                              </span>
                                              <span className="rlr-product-card__sub-title">
                                                {cruise.deckNumbers} Decks
                                              </span>
                                            </div>
                                          </div>
                                        </header>

                                        <div className="rlr-product-card__footer">
                                          {cruise.cruiseIntro}
                                        </div>
                                      </div>
                                      <div className="col-2 book-side">
                                        <div className="text-center  booking">
                                          <div className="mb-2 pb-1">
                                            <span className="font-size-13">
                                              From{" "}
                                            </span>
                                            <br />

                                            <span className="font-weight-bold font-size-22 ml-1">
                                              {" "}
                                              {cruise.priceCurrency}{" "}
                                              {cruise.cruisePrice}
                                            </span>
                                          </div>
                                          <button
                                            id="Submit1"
                                            type="button"
                                            className="btn btn-outline-primary border-radius-3 border-width-2 px-3 font-weight-bold min-width-200 py-2 text-lh-lg"
                                            onClick={() =>
                                              navigate(
                                                `/${cruise.cruiseUrl}/cabinsbooking/${cruise.opid}`
                                              )
                                            }
                                          >
                                            Select
                                          </button>
                                        </div>
                                      </div>
                                    </article>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <p className="SiteFontP text-travco">
                        No Cruises Matches Your Search!
                      </p>
                    )}
                  </Suspense>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CruisesBooking;
