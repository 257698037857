import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
// import Loader from "./../Loader";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faArrowRightFromBracket,
  faGlobeAsia,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { Alert, Snackbar, useMediaQuery } from "@mui/material";
import { fetchLayout } from "../data/layoutSlice";

import { setCurrency } from "../data/currencySlice";
// import { googleLogout } from "@react-oauth/google";
import { logout } from "./../data/sign-up/signUpSlice";
import { fetchProfile } from "../data/profile/profileSlice";
import { setLanguage } from "../data/languageSlice";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";

const Layout = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const language = useSelector((state) => state.language.language);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { layout, loading, error } = useSelector((state) => state.layout);
  const accessToken = localStorage.getItem("accessToken");
  const { profile } = useSelector((state) => state.profile);

  // const googleAccessToken = localStorage.getItem("googleAccessToken");
  // const loginToken = useSelector((state) => state.login.token);
  useEffect(() => {
    if (accessToken) {
      dispatch(fetchProfile(language));
    }
  }, [dispatch, accessToken, language]);
  const email = localStorage.getItem("email");
  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const userImage = localStorage.getItem("userImage");
  const handleLogout = () => {
    dispatch(logout())
      .then(() => {
        // Optional: Perform additional actions after successful logout
        navigate("/login"); // Redirect to login page
      })
      .catch((error) => {
        console.error("Logout error:", error);
        // Handle logout error
      });
  };

  useEffect(() => {
    dispatch(fetchLayout(language));
  }, [dispatch, language]);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [openFooterMenu1, setOpenFooterMenu1] = useState(false);
  const [openFooterMenu2, setOpenFooterMenu2] = useState(false);
  const [openFooterMenu3, setOpenFooterMenu3] = useState(false);
  useEffect(() => {
    const lazyLoadBackgrounds = () => {
      if ("IntersectionObserver" in window) {
        let lazyBackgrounds = document.querySelectorAll(".lazy-background");
        let options = {
          rootMargin: "0px",
          threshold: 0.1,
        };

        let observer = new IntersectionObserver((entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.style.backgroundImage = `url(${entry.target.getAttribute(
                "data-bg"
              )})`;
              observer.unobserve(entry.target);
            }
          });
        }, options);

        lazyBackgrounds.forEach((element) => {
          observer.observe(element);
        });
      } else {
        let lazyBackgrounds = document.querySelectorAll(".lazy-background");
        lazyBackgrounds.forEach((element) => {
          element.style.backgroundImage = `url(${element.getAttribute(
            "data-bg"
          )})`;
        });
      }
    };

    lazyLoadBackgrounds();
  }, []);

  const [isScrolled, setIsScrolled] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const handleMouseEnter = (index) => {
    setActiveSubMenu(index);
  };

  const handleMouseLeave = () => {
    setActiveSubMenu(null);
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  const currency = useSelector((state) => state.currency.currency);

  const handleCurrencyChange = (newCurrency) => {
    dispatch(setCurrency(newCurrency));
  };

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const heightToShow = window.innerHeight / 5;
    if (window.scrollY > heightToShow) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  /*__________________________News Letter______________________________________ */
  const getRecaptchaLanguageCode = () => {
    switch (i18n.language) {
      case "es":
        return "es";
      case "ru":
        return "ru";
      case "pt":
        return "pt";
      case "ar":
        return "ar";
      default:
        return "en"; // Default to English if no match
    }
  };

  const [errorNewsLetter, setErrorNewsLetter] = useState(null);
  const [loadingNewsLetter, setLoadingNewsLetter] = useState(false);

  const [loadingFormData, setLoadingFormData] = useState(false);
  const [errorFormDate, setErrorFormDate] = useState("");
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const handleCaptchaChange = (token) => {
    setRecaptchaToken(token);
  };
  const [formData, setFormData] = useState({
    emailAddress: "",
    namehoney: "",
    emailhoney: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!recaptchaToken) {
      setErrorFormDate("Please complete the reCAPTCHA.");
      setOpen(true);
      return;
    }
    setLoadingFormData(true);
    setErrorFormDate("");
    setSuccess(false);
    try {
      const response = await fetch(
        "https://api.travco.com/en/opsubmitnewsLetter",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            accept: "*/*",
          },
          body: JSON.stringify({
            emailAddress: formData.emailAddress,
            namehoney: formData.namehoney,
            emailhoney: formData.emailhoney,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
        setLoadingNewsLetter(false);
      }
      setSuccess(true);
      setOpen(true);
    } catch (error) {
      console.error("Error subscribing to newsletter:", error);
      setErrorFormDate(error.message); // Ensure the error is a string
      setOpen(true);
      setSuccess(false);
    } finally {
      setLoadingFormData(false);
      setFormData({
        emailAddress: "",
        namehoney: "",
        emailhoney: "",
      });
    }
  };
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const toggleLanguage = (selectedLanguage) => {
    const newLanguage = selectedLanguage.languageAbbreviation;
    dispatch(setLanguage(newLanguage));
    window.location.pathname = `/${newLanguage}${window.location.pathname.substring(
      3
    )}`;
  };
  return (
    <>
      {/*Header*/}
      <aside className="rlr-hero--half-mast">
        <header>
          <nav
            id="rlrTransNav"
            className={`navigation rlr-navigation fixed-top navigation-landscape ${
              isScrolled ? "navigation-animated" : "navigation-transparent"
            }`}
          >
            <div className="container">
              <div className="navigation-header">
                <div className="navigation-brand-text">
                  <div className="rlr-logo rlr-logo__navbar-brand rlr-logo--transparent-bg">
                    <Link to="/">
                      <img
                        src={"logo-white.png"}
                        alt="Travco Travel Logo"
                        className="rlr-logo__img"
                      />
                    </Link>
                  </div>
                  <div className="rlr-logo rlr-logo__navbar-brand rlr-logo--default">
                    <Link to="/">
                      <img
                        src={"logo-white.png"}
                        alt="Travco Travel Logo"
                        className="rlr-logo__img"
                      />
                    </Link>
                  </div>
                </div>
                <div className="navigation-button-toggler">
                  <span
                    className="rlr-sVGIcon"
                    onClick={() => setOpenMobileMenu(!openMobileMenu)}
                  >
                    <i className="rlr-icon-font rlr-icon-font--megamenu flaticon-menu"></i>
                  </span>
                </div>
              </div>
              <div
                className={`avigation-body rlr-navigation__body container-xxxl navigation-body rlr-navigation__body container-xxxl ${
                  isMobile && openMobileMenu
                    ? "scroll-momentum is-visible"
                    : isTablet && openMobileMenu
                    ? "scroll-momentum is-visible"
                    : "  "
                }`}
              >
                <div className="navigation-body-header rlr-navigation__body-header">
                  <div className="navigation-brand-text">
                    <div className="rlr-logo rlr-logo__navbar-brand rlr-logo--transparent-bg">
                      <Link to="/">
                        <img
                          src={"logo-white.png"}
                          alt="Travco Travel Logo"
                          className="rlr-logo__img"
                        />
                      </Link>
                    </div>
                    <div className="rlr-logo rlr-logo__navbar-brand rlr-logo--default">
                      <Link to="/">
                        <img
                          src={"logo-white.png"}
                          alt="Travco Travel Logo"
                          className="rlr-logo__img"
                        />
                      </Link>
                    </div>
                  </div>
                  <span
                    className="rlr-sVGIcon navigation-body-close-button"
                    onClick={() => setOpenMobileMenu(!openMobileMenu)}
                  >
                    <i className="rlr-icon-font rlr-icon-font--megamenu flaticon-close"></i>
                  </span>
                </div>

                <ul className="navigation-menu rlr-navigation__menu rlr-navigation__menu--main-links w-100 justify-content-center px-lg-3">
                  <li
                    className="navigation-item has-submenu"
                    onMouseEnter={() => handleMouseEnter(0)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Link
                      className="navigation-link"
                      to="#."
                      style={{ textShadow: "1px 1px 2px #000" }}
                    >
                      {t("Destinations")}
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        className="px-2 pt-1"
                        style={{ fontSize: "15px", opacity: "0.85" }}
                      />
                    </Link>
                    <ul
                      className={`navigation-dropdown lazy-background navigation-submenu ${
                        activeSubMenu === 0 ? "is-visible" : ""
                      }`}
                      // data-bg="/images/shapes/menu-bg-egypt.webp"
                      // style={{
                      //   backgroundSize: "cover",
                      //   backgroundRepeat: "no-repeat",
                      // }}
                    >
                      {layout.layoutHeader?.destinations?.map((destination) => (
                        <li
                          key={destination.destinationUrl}
                          className="navigation-dropdown-item"
                          onClick={() => setOpenMobileMenu(!openMobileMenu)}
                        >
                          <Link
                            className="navigation-dropdown-link"
                            to={`/destination/egypt/${destination.destinationUrl}`}
                          >
                            {destination.destinationNameSys}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>

                  <li
                    className="navigation-item has-submenu"
                    onMouseEnter={() => handleMouseEnter(1)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Link
                      to="#."
                      className="navigation-link"
                      style={{ textShadow: "1px 1px 2px #000" }}
                    >
                      {t("Services")}
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        className="px-2 pt-1"
                        style={{ fontSize: "15px", opacity: "0.85" }}
                      />
                    </Link>
                    <ul
                      className={`navigation-dropdown lazy-background navigation-submenu ${
                        activeSubMenu === 1 ? "is-visible" : ""
                      }`}
                      // data-bg="/images/shapes/menu-bg-egypt.webp"
                      // style={{
                      //   backgroundSize: "cover",
                      //   backgroundRepeat: "no-repeat",
                      // }}
                    >
                      <li
                        className="navigation-dropdown-item"
                        onClick={() => setOpenMobileMenu(!openMobileMenu)}
                      >
                        <Link
                          className="navigation-dropdown-link"
                          to="/packages"
                        >
                          {t("Packages")}
                        </Link>
                      </li>

                      <li
                        className="navigation-dropdown-item"
                        onClick={() => setOpenMobileMenu(!openMobileMenu)}
                      >
                        <Link
                          className="navigation-dropdown-link"
                          to="/hotels-destinations"
                        >
                          {t("Hotels")}
                        </Link>
                      </li>

                      <li
                        className="navigation-dropdown-item"
                        onClick={() => setOpenMobileMenu(!openMobileMenu)}
                      >
                        <Link
                          className="navigation-dropdown-link"
                          to="/tours-activities"
                        >
                          {t("Tours & Activities")}
                        </Link>
                      </li>

                      <li
                        className="navigation-dropdown-item"
                        onClick={() => setOpenMobileMenu(!openMobileMenu)}
                      >
                        <Link
                          className="navigation-dropdown-link"
                          to="/nile-cruises"
                        >
                          {t("Nile Cruises")}
                        </Link>
                      </li>

                      <li
                        className="navigation-dropdown-item"
                        onClick={() => setOpenMobileMenu(!openMobileMenu)}
                      >
                        <Link
                          className="navigation-dropdown-link"
                          to="/transfers-and-airport-services"
                        >
                          {t("Transfers and Airport Services")}
                        </Link>
                      </li>

                      <li
                        className="navigation-dropdown-item"
                        onClick={() => setOpenMobileMenu(!openMobileMenu)}
                      >
                        <Link className="navigation-dropdown-link" to="/visa">
                          {t("Meet & Assist + Visa")}
                        </Link>
                      </li>

                      <li
                        className="navigation-dropdown-item"
                        onClick={() => setOpenMobileMenu(!openMobileMenu)}
                      >
                        <Link className="navigation-dropdown-link" to="/mice">
                          {t("MICE")}
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li
                    className="navigation-item has-submenu"
                    onMouseEnter={() => handleMouseEnter(2)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Link
                      className="navigation-link"
                      to="#."
                      style={{ textShadow: "1px 1px 2px #000" }}
                    >
                      {t("Travco")}
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        className="px-2 pt-1"
                        style={{ fontSize: "15px", opacity: "0.85" }}
                      />
                    </Link>
                    <ul
                      className={`navigation-dropdown lazy-background navigation-submenu ${
                        activeSubMenu === 2 ? "is-visible" : ""
                      }`}
                      // data-bg="/images/shapes/menu-bg-egypt.webp"
                      // style={{
                      //   backgroundSize: "cover",
                      //   backgroundRepeat: "no-repeat",
                      // }}
                    >
                      <li
                        className="navigation-dropdown-item"
                        onClick={() => setOpenMobileMenu(!openMobileMenu)}
                      >
                        <Link
                          className="navigation-dropdown-link"
                          to="/about-travco-travel"
                        >
                          {t("About Travco Travel")}
                        </Link>
                      </li>
                      <li
                        className="navigation-dropdown-item"
                        onClick={() => setOpenMobileMenu(!openMobileMenu)}
                      >
                        <Link
                          className="navigation-dropdown-link"
                          to="/travco-news"
                        >
                          {t("Travco News")}
                        </Link>
                      </li>
                      <li
                        className="navigation-dropdown-item"
                        onClick={() => setOpenMobileMenu(!openMobileMenu)}
                      >
                        <Link
                          className="navigation-dropdown-link"
                          to="/international-offices"
                        >
                          {t("International Offices")}
                        </Link>
                      </li>
                      <li
                        className="navigation-dropdown-item"
                        onClick={() => setOpenMobileMenu(!openMobileMenu)}
                      >
                        <Link
                          className="navigation-dropdown-link"
                          to="/affiliated-companies"
                        >
                          {t("Affiliated Companies")}
                        </Link>
                      </li>
                      <li
                        className="navigation-dropdown-item"
                        onClick={() => setOpenMobileMenu(!openMobileMenu)}
                      >
                        <Link
                          className="navigation-dropdown-link"
                          to="/website-terms"
                        >
                          {t("Website Terms")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>

                <ul
                  className="navigation-menu rlr-navigation__menu align-to-right  flex-wrap flex-lg-nowrap justify-content-center gap-0 "
                  id="right-side"
                >
                  <li
                    className="navigation-item w-auto"
                    onClick={() => setOpenMobileMenu(!openMobileMenu)}
                  >
                    <Link
                      className="brochure navigation-link"
                      to="/brochures"
                      style={{ width: "52px" }}
                    >
                      <img
                        alt={t("brochures")}
                        className="brochure d-block"
                        src="images/uploads/proches.png"
                        style={{ width: "52px" }}
                        title={t("brochures")}
                      />
                    </Link>
                  </li>
                  <li
                    className="navigation-item w-auto"
                    title={t("Contact Us")}
                    onClick={() => setOpenMobileMenu(!openMobileMenu)}
                  >
                    <Link
                      className="brochure navigation-link"
                      to="/contact-us"
                      style={{ width: "45px" }}
                    >
                      <img
                        alt={t("Contact Us")}
                        className="d-block"
                        src="images/uploads/contact.gif"
                        style={{ width: "45px" }}
                      />
                    </Link>
                  </li>

                  <li
                    className={`navigation-item ${
                      isMobile || isTablet ? "w-100" : ""
                    }`}
                    onMouseEnter={() => handleMouseEnter(3)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Link className="navigation-link" to="#.">
                      <span
                        className="d-lg-none d-xxl-block"
                        style={{ textShadow: "1px 1px 2px #000" }}
                      >
                        {currency}
                        <FontAwesomeIcon
                          icon={faAngleDown}
                          className="usd-icon px-2 pt-1"
                          style={{ fontSize: "15px", opacity: "0.85" }}
                        />
                      </span>
                    </Link>
                    <ul
                      className={`navigation-dropdown rlr-language-dropdown navigation-submenu ${
                        activeSubMenu === 3 ? "is-visible" : ""
                      }`}
                      id="currency"
                    >
                      {layout.layoutHeader?.currencies
                        ?.filter(
                          (currency) =>
                            currency.currencyName !==
                              localStorage.getItem("currency") && currency
                        )
                        .map((currency, index) => (
                          <li
                            key={index}
                            className="navigation-dropdown-item"
                            onClick={() => {
                              setOpenMobileMenu(!openMobileMenu);
                              handleCurrencyChange(currency.currencyName);
                            }}
                          >
                            <Link className="navigation-dropdown-link" to="#.">
                              {currency.currencyName}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </li>
                  {language != "en" && (
                    <li
                      className={`navigation-item  me-lg-3 ${
                        isMobile || isTablet ? "w-100" : "w-auto"
                      }`}
                      onMouseEnter={() => handleMouseEnter(55)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <Link className="navigation-link" to="#.">
                        <FontAwesomeIcon
                          icon={faGlobeAsia}
                          className=" ps-2 "
                          style={{ fontSize: "15px", opacity: "1" }}
                        />
                        <span
                          className="d-lg-none d-xxl-block"
                          style={{
                            textShadow: "1px 1px 2px #000",
                            textTransform: "uppercase",
                          }}
                        >
                          {language}
                          <FontAwesomeIcon
                            icon={faAngleDown}
                            className="nation-icon px-1 "
                            style={{ fontSize: "15px", opacity: "0.85" }}
                          />
                        </span>
                      </Link>
                      <ul
                        className={`navigation-dropdown rlr-language-dropdown   navigation-submenu ${
                          activeSubMenu === 55 ? "is-visible" : ""
                        }`}
                        id="nations"
                      >
                        <li className="navigation-dropdown-item d-flex justify-content-start align-items-center ps-3  ">
                          <h5 className="mt-3 fs-5 pb-1">{t("Languages")} :</h5>
                        </li>
                        <hr
                          style={{
                            width: "86%",
                            border: "1px solid #999",
                            margin: "auto",
                          }}
                        />
                        {layout.languages
                          ?.filter(
                            (lang) => lang.languageAbbreviation !== language
                          )
                          .map((language) => (
                            <li
                              key={language.languageAbbreviation}
                              className="navigation-dropdown-item d-flex justify-content-start align-items-center ps-0  "
                              onClick={() => {
                                toggleLanguage(language);
                              }}
                            >
                              {/* <img alt="egypt flag" src={language.languageFlag} /> */}
                              <Link
                                to="#."
                                className="navigation-dropdown-link"
                                target="_blank"
                              >
                                {language.languageName}
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </li>
                  )}
                  <li
                    className={`navigation-item  me-lg-3 ${
                      isMobile || isTablet ? "w-100" : "w-auto"
                    }`}
                    onMouseEnter={() => handleMouseEnter(4)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Link className="navigation-link" to="#.">
                      <img
                        alt="egypt flag"
                        src="images/uploads/flag-egypt.jpg"
                      />
                      <span
                        className="d-lg-none d-xxl-block"
                        style={{ textShadow: "1px 1px 2px #000" }}
                      >
                        {t("Travco Egypt")}
                        <FontAwesomeIcon
                          icon={faAngleDown}
                          className="nation-icon px-2 "
                          style={{ fontSize: "15px", opacity: "0.85" }}
                        />
                      </span>
                    </Link>
                    <ul
                      className={`navigation-dropdown rlr-language-dropdown   navigation-submenu ${
                        activeSubMenu === 4 ? "is-visible" : ""
                      }`}
                      id="nations"
                    >
                      <li className="navigation-dropdown-item d-flex justify-content-start align-items-center ps-3  ">
                        <img
                          alt="egypt flag"
                          src="images/uploads/flag-uae.jpg"
                        />
                        <Link
                          to="https://www.travcotravel.ae/"
                          className="navigation-dropdown-link"
                          target="_blank"
                        >
                          {t("Travco UAE")}
                        </Link>
                      </li>
                      <hr
                        style={{
                          width: "86%",
                          border: "1px solid #999",
                          margin: "auto",
                        }}
                      />
                      <li className="navigation-dropdown-item d-flex justify-content-start align-items-center ps-3  ">
                        <img
                          alt="egypt flag"
                          src="images/uploads/flag-jordan.jpg"
                        />
                        <Link
                          to="https://www.travcotravel.jo/"
                          className="navigation-dropdown-link"
                          target="_blank"
                        >
                          {t("Travco Jordan")}
                        </Link>
                      </li>
                      <hr
                        style={{
                          width: "86%",
                          border: "1px solid #999",
                          margin: "auto",
                        }}
                      />
                      <li className="navigation-dropdown-item d-flex justify-content-start align-items-center ps-3  ">
                        <img
                          alt="egypt flag"
                          src="images/uploads/flag-oman.jpg"
                        />
                        <Link
                          to="https://travcotravel.om/"
                          className="navigation-dropdown-link"
                          target="_blank"
                        >
                          {t("Travco Oman")}
                        </Link>
                      </li>
                      <hr
                        style={{
                          width: "86%",
                          border: "1px solid #999",
                          margin: "auto",
                        }}
                      />
                      <li className="navigation-dropdown-item d-flex justify-content-start align-items-center ps-3  ">
                        <img
                          alt="egypt flag"
                          src="images/uploads/flag-morocco.jpg"
                        />
                        <Link
                          to="https://boardingpassdmc.ma/"
                          className="navigation-dropdown-link"
                          target="_blank"
                        >
                          {t("Travco Morocco")}
                        </Link>
                      </li>
                    </ul>
                  </li>

                  {accessToken ? (
                    <li
                      className={`navigation-item ${
                        isMobile || isTablet ? "w-100" : "w-50"
                      }`}
                      onMouseEnter={() => handleMouseEnter(5)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <Link className="navigation-link" to="#.">
                        <span
                          className="d-lg-none d-xxl-block"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {userImage ? (
                            <img
                              src={userImage}
                              alt="google email "
                              style={{ borderRadius: "50%", width: "30px" }}
                              className="mr-2"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faUser}
                              className="ps-1 pt-1 pe-1"
                              style={{ fontSize: "20px", opacity: "0.85" }}
                            />
                          )}

                          {profile?.userInfo
                            ? profile.userInfo?.customerFirstName +
                              " " +
                              profile.userInfo?.customerLastName
                            : firstName
                            ? firstName + " " + lastName
                            : email}
                          <FontAwesomeIcon
                            icon={faAngleDown}
                            className="usd-icon px-2 pt-1"
                            style={{ fontSize: "15px", opacity: "0.85" }}
                          />
                        </span>
                      </Link>
                      <ul
                        className={`navigation-dropdown rlr-language-dropdown navigation-submenu  ${
                          activeSubMenu === 5 ? "is-visible" : ""
                        }`}
                      >
                        <li
                          className="navigation-dropdown-item"
                          onClick={() => {
                            setOpenMobileMenu(!openMobileMenu);
                          }}
                        >
                          <Link
                            className="navigation-dropdown-link"
                            to="/EditProfilePage"
                          >
                            <i className="rlr-icon-font flaticon-user"> </i>{" "}
                            {t("Profile")}{" "}
                          </Link>
                        </li>
                        <li
                          className="navigation-dropdown-item"
                          onClick={() => {
                            setOpenMobileMenu(!openMobileMenu);
                          }}
                        >
                          <Link
                            className="navigation-dropdown-link"
                            to="/myorders"
                          >
                            <i className="rlr-icon-font flaticon-shopping"> </i>{" "}
                            {t("Your Orders")}{" "}
                          </Link>
                        </li>
                        {/* <li
                          className="navigation-dropdown-item"
                          onClick={() => {
                            setOpenMobileMenu(!openMobileMenu);
                          }}
                        >
                          <Link
                            className="navigation-dropdown-link"
                            to="/profile"
                          >
                            Profile Details
                          </Link>
                        </li> */}

                        <li
                          className="navigation-dropdown-item "
                          onClick={() => {
                            setOpenMobileMenu(!openMobileMenu);
                          }}
                        >
                          <Link
                            className="navigation-dropdown-link d-flex align-items-center"
                            onClick={handleLogout}
                          >
                            {t("Logout")}{" "}
                            <FontAwesomeIcon
                              icon={faArrowRightFromBracket}
                              className="ps-1 pt-1"
                              style={{ fontSize: "15px", opacity: "0.85" }}
                            />
                          </Link>
                        </li>
                      </ul>
                    </li>
                  ) : (
                    <li
                      className="navigation-item w-auto"
                      onClick={() => {
                        setOpenMobileMenu(!openMobileMenu);
                      }}
                    >
                      <Link
                        className="navigation-link"
                        to="/login"
                        style={{ textShadow: "1px 1px 2px #000" }}
                      >
                        {t("Login")}
                        <FontAwesomeIcon
                          icon={faArrowRightFromBracket}
                          className="ps-1 pt-1"
                          style={{ fontSize: "15px", opacity: "0.85" }}
                        />
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </aside>
      <main id="rlr-main" className="rlr-main w-100 overflow-x-hidden">
        <Link
          to="#rlrTransNav"
          className={`back-to-top ${isVisible ? "show" : ""}`}
          onClick={scrollToTop}
        >
          <i className="fa fa-angle-up"></i>
        </Link>
        <Outlet />
      </main>
      {/*Footer*/}
      <footer id="footer" className="footer">
        <div className="newsletter">
          <div
            style={{
              backgroundImage: `url(${layout.layoutFooter?.newsLetter?.newsletterBackground})`,
              height: "100%",
              width: "100%",
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              padding: "30px 0 ",
            }}
          >
            <div className="container col-lg-9 ps-lg-5 d-flex flex-column flex-lg-row justify-content-around align-items-center">
              <div className="col-12 col-lg-6 text-center text-lg-left ps-lg-2">
                <h2
                  className="rlr-section__title--main"
                  style={{ textShadow: "0 0 2px #ccc" }}
                >
                  {layout.layoutFooter?.newsLetter?.newsletterFirstText}
                </h2>
                <p>{layout.layoutFooter?.newsLetter?.newsletterSecondText}</p>
              </div>
              <form
                className="col-12 col-lg-6 text-center text-lg-right"
                onSubmit={handleSubmit}
              >
                <label className="ohnohoney" htmlFor="namehoney"></label>
                <input
                  tabIndex="-1"
                  className="ohnohoney"
                  autoComplete="off"
                  type="text"
                  id="namehoney"
                  name="namehoney"
                  placeholder="Your name here"
                  value={formData.namehoney}
                  onChange={handleInputChange}
                />
                <label className="ohnohoney" htmlFor="emailhoney"></label>
                <input
                  tabIndex="-1"
                  className="ohnohoney"
                  autoComplete="off"
                  type="email"
                  id="emailhoney"
                  name="emailhoney"
                  placeholder="Your e-mail here"
                  value={formData.emailhoney}
                  onChange={handleInputChange}
                />
                <input
                  type="email"
                  required
                  name="emailAddress"
                  id="emailAddress"
                  placeholder={t("Enter Your Email")}
                  style={{ width: "54%", fontSize: "14px" }}
                  value={formData.emailAddress}
                  onChange={handleInputChange}
                  className="px-2 py-3"
                />
                <input
                  type="submit"
                  value={
                    loadingFormData ? `${t("Loading")}...` : t("Subscribe")
                  }
                />
                <div
                  id="divcaptcha"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px 0 0px 0px",
                  }}
                >
                  <ReCAPTCHA
                    sitekey="6LdVcwgqAAAAAJzoiaHWcn_dmaNoCCn3_7ActZXJ"
                    onChange={handleCaptchaChange}
                    hl={getRecaptchaLanguageCode()} // Pass the language code to the hl parameter
                  />
                </div>
                {!loadingFormData && success && (
                  <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                  >
                    <Alert
                      onClose={handleClose}
                      severity="success"
                      variant="filled"
                      sx={{ width: "100%" }}
                    >
                      {t("Thank You")}
                    </Alert>
                  </Snackbar>
                )}{" "}
                {errorFormDate && (
                  <Snackbar
                    open={open}
                    autoHideDuration={4000}
                    onClose={handleClose}
                  >
                    <Alert
                      onClose={handleClose}
                      severity="danger"
                      variant="filled"
                      sx={{ width: "100%" }}
                    >
                      {errorFormDate}
                    </Alert>
                  </Snackbar>
                )}
              </form>
            </div>
          </div>
        </div>
        <div style={{ paddingTop: "50px" }}>
          <div className=" container ">
            <div className="wrap_float">
              <div className="footer_top">
                <div className="left">
                  <div className="col">
                    <div
                      className={`_title m_title ${
                        openFooterMenu1 ? "active" : ""
                      }`}
                      onClick={() => setOpenFooterMenu1(!openFooterMenu1)}
                    >
                      {t("Our Services")}
                    </div>

                    <ul
                      className="items-web"
                      style={{
                        listStyle: "none",
                        margin: "0px",
                        padding: "0px",
                        display: openFooterMenu1 && "block",
                      }}
                    >
                      <li>
                        <Link to="/destination-guide">
                          {t("Destination Guide")}
                        </Link>
                      </li>

                      <li>
                        <Link to="/packages"> {t("Packages")}</Link>
                      </li>
                      <li>
                        <Link to="/nile-cruises">{t("Nile Cruises")}</Link>
                      </li>
                      <li>
                        <Link to="/tours-activities">
                          {t("Tours & Activities")}
                        </Link>
                      </li>

                      <li>
                        <Link to="/transfers-and-airport-services">
                          {t("Transfers and Airport Services")}
                        </Link>
                      </li>

                      <li>
                        <Link to="/visa">{t("Meet & Assist + Visa")}</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col">
                    <div
                      className={`_title m_title ${
                        openFooterMenu2 ? "active" : ""
                      }`}
                      onClick={() => setOpenFooterMenu2(!openFooterMenu2)}
                    >
                      {t("Top Destinations")}
                    </div>
                    <ul
                      className="items-web"
                      style={{
                        listStyle: "none",
                        margin: "0px",
                        padding: "0px",
                        display: openFooterMenu2 && "block",
                      }}
                    >
                      {layout.layoutFooter?.destinations?.map((destination) => (
                        <li key={destination.destinationUrl}>
                          <Link
                            to={`/destination/egypt/${destination.destinationUrl}`}
                          >
                            {destination.destinationNameSys}{" "}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="col">
                    <div
                      className={`_title m_title ${
                        openFooterMenu3 ? "active" : ""
                      }`}
                      onClick={() => setOpenFooterMenu3(!openFooterMenu3)}
                    >
                      {t("About Us")}
                    </div>
                    <ul
                      className="items-web"
                      style={{
                        listStyle: "none",
                        margin: "0px",
                        padding: "0px",
                        display: openFooterMenu3 && "block",
                      }}
                    >
                      <li>
                        <Link to="/about-travco-travel">
                          {" "}
                          {t("About Travco")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/travco-news">{t("Travco News")}</Link>
                      </li>

                      <li>
                        <Link to="/international-offices">
                          {t("International Offices")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/affiliated-companies">
                          {t("Affiliated Companies")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact-us">{t("Contact Us")}</Link>
                      </li>
                      <li>
                        <Link to="/be-our-partner">{t("Be Our Partner")}</Link>
                      </li>
                      <li>
                        <Link to="/branches">{t("Branches")}</Link>
                      </li>
                      <li>
                        <Link to="/destination-handbook">
                          {t("Destination Handbook")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/testimonials"> {t("Testimonials")}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="right">
                  <div className="_title"> {t("Contacts")}</div>
                  <div className="contacts_info d-lg-flex align-items-lg-baseline justify-content-lg-start flex-wrap">
                    <div className="tel">
                      <Link
                        to={`tel:${layout.layoutFooter?.siteContacts?.siteTel}`}
                        className="float-md-none"
                      >
                        <FontAwesomeIcon icon={faPhone} className="me-2" />
                        {layout.layoutFooter?.siteContacts?.siteTel}
                      </Link>
                      <p>{t("Round the clock support")}</p>
                    </div>
                    <div className="email">
                      <Link
                        to={`mailto:${layout.layoutFooter?.siteContacts?.siteEmail}`}
                        className="float-md-none"
                      >
                        <i className="fa fa-envelope me-2"></i>
                        {layout.layoutFooter?.siteContacts?.siteEmail}
                      </Link>
                      <p>{t("For any inquiries")}</p>
                    </div>
                    <div className="address">
                      {layout.layoutFooter?.siteContacts?.siteAddress}
                    </div>
                  </div>
                  <div className="socials social-links">
                    {layout.layoutFooter?.siteSocials?.map((item) => (
                      <Link
                        key={item.socialName}
                        to={item.socialUrl}
                        target="_blank"
                        className={`link ${item.socialClass}`}
                        rel="noopener noreferrer"
                      >
                        <span></span>
                      </Link>
                    ))}
                    <Link
                      to="https://whatsapp.com/channel/0029VaFiN6lLikg6BHqn7f2b"
                      target="_blank"
                      className="link whatsapp"
                      rel="noopener noreferrer"
                    >
                      <span></span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="footer_bottom row d-lg-flex justify-content-between">
                <div
                  className="col-lg-4 text-right"
                  style={{ color: "rgba(255, 255, 255, 0.53)" }}
                >
                  <Link to="/" target="_blank">
                    {t("Copyright © 2024 Travco Travel Company of Egypt")}
                  </Link>
                </div>
                <div
                  className="col-lg-4 text-center"
                  style={{ color: "rgba(255, 255, 255, 0.53)" }}
                >
                  <Link to="/">{t("Home")} </Link> -{" "}
                  <Link to="/privacy-policy">{t("Privacy Policy")} </Link> -{" "}
                  <Link to="/website-terms">{t("Terms & Conditions")}</Link>
                </div>

                <div
                  className="col-lg-4 text-left"
                  style={{ color: "rgba(255, 255, 255, 0.53)" }}
                >
                  <Link to="https://titegypt.com" target="_blank">
                    {t("Powered By T.I.T Solutions")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Layout;
