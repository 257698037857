import React, { useEffect, useState, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";

import { styled, useMediaQuery } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleUp, faClose } from "@fortawesome/free-solid-svg-icons";
import {
  fetchTransfers,
  setPageIndex,
} from "../../data/transfers/transfersSlice";
import ScrollToTop from "../../ScrollToTop";

const TransfersSearchWidget = () => {
  const language = useSelector((state) => state.language.language);
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const [view, setView] = useState(false);
  const dispatch = useDispatch();
  const [scroll, setScroll] = useState(false);
  // search
  const [searchTerm, setSearchTerm] = useState("");
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const currency = useSelector((state) => state.currency.currency);
  const { transfers, pageIndex, loading, error } = useSelector(
    (state) => state.transfers
  );

  const handleNextPage = () => {
    if (pageIndex < transfers?.totalPages) {
      dispatch(setPageIndex(pageIndex + 1));
    }
    setScroll(true);
  };
  const handlePreviousPage = () => {
    if (pageIndex > 1) {
      dispatch(setPageIndex(pageIndex - 1));
    }
    setScroll(true);
  };
  const handlePageChange = (page) => {
    dispatch(setPageIndex(page));
    setScroll(true);
  };
  const [Sort, setSort] = useState(1);
  const [selectedStyle, setSelectedStyle] = useState([]);
  const DestinationId = localStorage.getItem("Transfers_destinationValue");
  const [selectedDestination, setSelectedDestination] = useState([]);
  const Transfers_participantNumber = localStorage.getItem(
    "Transfers_participantNumber"
  );
  const [participantNumber, setParticipantNumber] = useState(
    Transfers_participantNumber
  );
  const DateValues = localStorage.getItem("Transfers_DateValues");

  const handleCheckboxChange = (event) => {
    const { checked, value } = event.target;
    if (checked) {
      setSelectedStyle((prevSelected) => [...prevSelected, value]);
    } else {
      setSelectedStyle((prevSelected) =>
        prevSelected.filter((item) => item !== value)
      );
    }
  };
  const handleDestinationsCheckboxChange = (event) => {
    const { checked, value } = event.target;
    if (checked) {
      setSelectedDestination((prevSelected) => [...prevSelected, value]);
    } else {
      setSelectedDestination((prevSelected) =>
        prevSelected.filter((item) => item !== value)
      );
    }
  };

  useEffect(() => {
    dispatch(
      fetchTransfers({
        pageIndex,
        DateValues,
        DestinationId,
        searchTerm,
        Sort,
        currency,
        selectedStyle,
        selectedDestination,
        participantNumber,
        language,
      })
    );

    setScroll(false);
  }, [
    dispatch,
    searchTerm,
    pageIndex,
    Sort,
    currency,
    selectedStyle,
    selectedDestination,
    participantNumber,
    DestinationId,
    DateValues,
    language,
  ]);
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));
  return (
    <>
      <Helmet>
        <title>
          {transfers.pageDetails?.pageMetatagTitle ||
            "transfers and airport services"}
        </title>
        <link
          rel="canonical"
          href={`https://travco.com/${language}/search/transfers-and-airport-services`}
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content={transfers.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={transfers.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={transfers.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={transfers.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={transfers.pageDetails?.metatagDescriptionTwitter}
        />
        <link rel="stylesheet" href="css/news.css" />
        <style type="text/css">{`@media screen and  (max-width: 768px) {
    .rlr-product-filters__filters-wrapper .rlr-checkboxes {
        display: flex;
        justify-content: space-between;
        align-items: start;
        margin-top: 10px;
    }
}`}</style>
      </Helmet>{" "}
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>Loading...</div>}>
                    <img
                      placeholder={
                        isMobile
                          ? transfers.pageDetails?.pageBannerMobile
                          : isTablet
                          ? transfers.pageDetails?.pageBannerTablet
                          : transfers.pageDetails?.pageBannerPc
                      }
                      src={
                        isMobile
                          ? transfers.pageDetails?.pageBannerMobile
                          : isTablet
                          ? transfers.pageDetails?.pageBannerTablet
                          : transfers.pageDetails?.pageBannerPc
                      }
                      alt={transfers.pageDetails?.pageTitle}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className="rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {transfers.pageDetails?.pageTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="rlr-search-results-page__breadcrumb-section">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb rlr-breadcrumb__items">
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/">Home </Link>
              </li>

              <li
                className="breadcrumb-item rlr-breadcrumb__item active"
                aria-current="page"
              >
                {transfers.pageDetails?.pageTitle}
              </li>
            </ol>
          </nav>
          <div className="rlr-icon-text">
            <i className="rlr-icon-font flaticon-phone-receiver-silhouette">
              {" "}
            </i>{" "}
            <Link
              to="tel:(+202) 3854 1010"
              className="rlr-search-results-page__phone"
            >
              Questions? (+202) 3854 1010{" "}
            </Link>
          </div>
        </div>
      </div>
      <aside>
        <div className="container">
          {" "}
          <div className="rlr-search-results-header rlr-search-results-header__wrapper flex-mobile">
            <h1 className="rlr-search-results-header__value">
              Found <strong>{transfers.totalCount}</strong> Tours For{" "}
              <strong>{transfers.pageDetails?.pageTitle}</strong>
            </h1>

            <div className=" my-1 packages col-lg-4">
              <div className="search-wrapper" style={{ position: "relative" }}>
                <SearchIconWrapper>
                  <SearchIcon sx={{ color: "#e60000" }} />
                </SearchIconWrapper>
                <input
                  className="form-control ps-5"
                  placeholder="Search…"
                  value={searchTerm}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="rlr-search-results-header__sorting-wrapper">
              <span className="rlr-search-results-header__label">
                Sort by:{" "}
              </span>
              <div className="dropdown rlr-dropdown rlr-js-dropdown">
                <select
                  id="ddl_SortID"
                  name="ddl_SortID"
                  className="ddl_SortID form-control border-1"
                  onChange={(e) => {
                    setSort(e.target.value);
                  }}
                >
                  <option value="1">Price (Low to High)</option>
                  <option value="2">Price (High to Low)</option>
                  <option value="3">Duration (Short to Long)</option>
                  <option value="4">Duration (Long to Short)</option>
                  <option value="5">Newest</option>
                  <option value="6">Popularity</option>
                </select>
              </div>
            </div>

            <div
              className="rlr-search-results-header__sorting-wrapper "
              id="filter"
              onClick={() => setView(!view)}
            >
              <span className="rlr-search-results-header__label">
                filter By
              </span>{" "}
              <i
                className="rlr-icon-font flaticon-menu"
                style={{
                  color: "#e60000",
                  fontSize: "25px",
                  paddingTop: "6px,",
                }}
              >
                {" "}
              </i>
            </div>
          </div>
        </div>
      </aside>
      <section>
        <div className="container">
          <div className="row rlr-search-results-page__product-details">
            {" "}
            <aside
              className={`col-xl-3 rlr-search-results-page__sidebar ${
                isMobile && view && "view"
              }`}
              id="filter-mobile"
            >
              <div className="rlr-product-filters__filters-wrapper pb-3">
                {isMobile && (
                  <Button
                    onClick={() => setView(false)}
                    className="btn btn-danger d-flex justify-content-end mt-0 ms-auto"
                  >
                    <FontAwesomeIcon icon={faClose} />
                  </Button>
                )}
                {/* Product Type filter */}
                <div className="rlr-product-filters__filter  ">
                  <div className="d-flex align-items-center pb-2">
                    <i
                      className="rlr-icon-font flaticon-right"
                      style={{ color: "#ef0000" }}
                    ></i>
                    <label
                      className="rlr-form-label rlr-product-filters__label rlr-section__title--main"
                      style={{
                        marginBottom: 0,
                        paddingLeft: 10,
                        fontSize: 22,
                        fontWeight: "bold",
                      }}
                    >
                      Experiences
                    </label>
                  </div>
                  <ul className="rlr-checkboxes">
                    {transfers.styles?.map((style) => (
                      <li
                        className="form-check form-check-block"
                        key={style.productStyleId}
                      >
                        <input
                          className="form-check-input rlr-form-check-input rlr-product-filters__checkbox  "
                          id={style.productStyleId}
                          type="checkbox"
                          name="StyleID"
                          value={style.productStyleId}
                          onChange={handleCheckboxChange}
                        />
                        <label
                          className="rlr-form-label rlr-form-label--checkbox rlr-product-filters__checkbox-label"
                          htmlFor={style.productStyleId}
                          style={{ fontSize: 14 }}
                        >
                          {style.productStyleNameSys}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Destination tags filter */}
                <div className="rlr-product-filters__filter">
                  <div className="d-flex align-items-center pb-2">
                    <i
                      className="rlr-icon-font flaticon-carbon-location"
                      style={{ color: "#ef0000" }}
                    ></i>
                    <label
                      className="rlr-form-label rlr-product-filters__label rlr-section__title--main"
                      style={{
                        marginBottom: 0,
                        paddingLeft: 10,
                        fontSize: 22,
                        fontWeight: "bold",
                      }}
                    >
                      Destinations
                    </label>
                  </div>
                  <ul className="rlr-checkboxes" style={{ paddingTop: 10 }}>
                    {transfers.destinations?.map((destination) => (
                      <li
                        className="form-check form-check-block"
                        key={destination.destinationId}
                      >
                        <input
                          className="form-check-input rlr-form-check-input rlr-product-filters__checkbox  "
                          name="IN_DestinationID"
                          id={destination.destinationId}
                          type="checkbox"
                          value={destination.destinationId}
                          onChange={handleDestinationsCheckboxChange}
                        />
                        <label
                          className="rlr-form-label rlr-form-label--checkbox rlr-product-filters__checkbox-label"
                          htmlFor={destination.destinationId}
                          style={{ fontSize: 14 }}
                        >
                          {destination.destinationNameSys}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>

                {isMobile && (
                  <Button
                    onClick={() => setView(false)}
                    className="btn btn-danger d-flex justify-content-end mt-5 mx-auto"
                  >
                    <FontAwesomeIcon icon={faAngleDoubleUp} />
                  </Button>
                )}
              </div>
            </aside>
            <div className="rlr-search-results-page__product-list col-lg-9">
              <div className="row rlr-search-results-page__card-wrapper">
                {loading && scroll ? (
                  <>
                    <ScrollToTop />
                  </>
                ) : (
                  <Suspense fallback={<div>Loading...</div>}>
                    {transfers.transfers?.length > 0 ? (
                      <>
                        {" "}
                        {transfers.transfers?.map((transfersItem) => (
                          <div
                            className="col-md-6 col-lg-4"
                            key={transfersItem.productId}
                          >
                            <article
                              className="rlr-product-card rlr-product-card--v3"
                              itemScope=""
                              itemType="en/dive-in-colors"
                            >
                              <figure className="rlr-product-card__image-wrapper">
                                {transfersItem.isPopular ? (
                                  <span className="rlr-badge rlr-badge-- rlr-badge--accent-red rlr-product-card__badge">
                                    Popular
                                  </span>
                                ) : (
                                  ""
                                )}

                                <div className="swiper rlr-js-product-multi-image-swiper">
                                  <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                      <Link
                                        to={`/transfers/${transfersItem.productUrl}`}
                                      >
                                        <img
                                          itemProp="image"
                                          data-sizes="auto"
                                          src={transfersItem.productPhoto}
                                          data-srcset={
                                            transfersItem.productPhoto
                                          }
                                          placeholder={
                                            transfersItem.productPhoto
                                          }
                                          className="lazyload"
                                          alt={transfersItem.productName}
                                        />
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </figure>
                              <div className="rlr-product-card__detail-wrapper rlr-js-detail-wrapper">
                                <header className="rlr-product-card__header">
                                  <div>
                                    <Link
                                      className="rlr-product-card__anchor-title"
                                      to={`/transfers/${transfersItem.productUrl}`}
                                    >
                                      <h2
                                        className="rlr-product-card__title"
                                        itemProp="name"
                                      >
                                        {transfersItem.productName}
                                      </h2>
                                    </Link>
                                    <div className="d-flex ">
                                      <Link
                                        className="rlr-product-card__anchor-cat d-flex"
                                        style={{
                                          color: "#000",
                                          paddingRight: "8px",
                                        }}
                                        to={`/transfers/${transfersItem.productUrl}`}
                                      >
                                        <i className="rlr-icon-font flaticon-carbon-location me-1">
                                          {" "}
                                        </i>
                                        <span
                                          className="rlr-product-card__sub-title"
                                          style={{ color: "#000" }}
                                        >
                                          {" "}
                                          {transfersItem.destinationName}
                                        </span>
                                      </Link>

                                      <Link
                                        className="rlr-product-card__anchor-cat d-flex"
                                        style={{ color: "#000" }}
                                        to={`/transfers/${transfersItem.productUrl}`}
                                      >
                                        <i className="rlr-icon-font flaticon-three-o-clock-clock me-1">
                                          {" "}
                                        </i>
                                        <span
                                          className="rlr-product-card__sub-title"
                                          style={{ color: "#000" }}
                                        >
                                          {transfersItem.fullDuration}
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                </header>

                                <div className="rlr-product-card__details">
                                  <div
                                    className="rlr-product-card__prices"
                                    itemProp="offers"
                                    itemScope=""
                                    itemType="https://schema.org/Offer"
                                  >
                                    <span className="rlr-product-card__from">
                                      from{" "}
                                    </span>

                                    <span className="rlr-product-card__price">
                                      {" "}
                                      <mark itemProp="priceCurrency"> </mark>
                                      <mark itemProp="price">
                                        {transfersItem.priceCurrency}{" "}
                                        {transfersItem.priceAdult}{" "}
                                      </mark>{" "}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </article>
                          </div>
                        ))}
                        <div id="PaggingContainer" className="text-center">
                          <div className="pagination-container">
                            <ul className="pagination">
                              <li
                                className="PagedList-skipToPrevious"
                                onClick={handlePreviousPage}
                                style={{
                                  visibility:
                                    pageIndex <= transfers.totalPages &&
                                    pageIndex > 1
                                      ? "visible"
                                      : "hidden",
                                }}
                              >
                                {" "}
                                <Link rel="prev">«</Link>
                              </li>
                              {Array.from(
                                { length: transfers.totalPages },
                                (_, index) => (
                                  <li
                                    className={
                                      index + 1 === pageIndex ? "active" : ""
                                    }
                                    key={index}
                                    onClick={() => {
                                      handlePageChange(index + 1);
                                    }}
                                  >
                                    <Link>{index + 1}</Link>
                                  </li>
                                )
                              )}

                              <li
                                className="PagedList-skipToNext"
                                onClick={handleNextPage}
                                style={{
                                  visibility:
                                    pageIndex === transfers.totalPages
                                      ? "hidden"
                                      : "visible",
                                }}
                              >
                                <Link rel="next">»</Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </>
                    ) : (
                      <p className="SiteFontP text-travco">
                        No Products Matches Your Search!
                      </p>
                    )}
                  </Suspense>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TransfersSearchWidget;
