import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { fetchNews, setPageIndex } from "../../data/news/newsSlice";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
// import LoaderInternal from "../../LoaderInternal";
import ScrollToTop from "../../ScrollToTop";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";

const News = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const [scroll, setScroll] = useState(false);
  const language = useSelector((state) => state.language.language);
  // search
  const [searchTerm, setSearchTerm] = useState("");
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const { news, pageIndex, loading, error } = useSelector(
    (state) => state.news
  );

  const handleNextPage = () => {
    if (pageIndex < news?.totalPages) {
      dispatch(setPageIndex(pageIndex + 1));
    }
  };
  const handlePreviousPage = () => {
    if (pageIndex > 1) {
      dispatch(setPageIndex(pageIndex - 1));
    }
  };
  const handlePageChange = (page) => {
    dispatch(setPageIndex(page));
    setScroll(true);
  };

  useEffect(() => {
    dispatch(fetchNews({ searchTerm, pageIndex, language }));
    if (searchTerm) {
      setScroll(false);
    }
  }, [searchTerm, dispatch, pageIndex, language]);

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  return (
    <>
      {" "}
      <Helmet>
        <title>{news.pageDetails?.pageMetatagTitle || "News"}</title>
        <link
          rel="canonical"
          href={`https://travco.com/${language}/travco-news`}
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content={news.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={news.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={news.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={news.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={news.pageDetails?.metatagDescriptionTwitter}
        />
        <link rel="stylesheet" href="css/news.css" />
      </Helmet>
      <section>
        {" "}
        <div className="outter">
          <div
            id="rlr_banner_slider"
            className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
          >
            <div className="splide__track rlr-banner-splide__track">
              <ul className="splide__list height-internal-pages">
                <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                  <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                    <div className="overlay-banner"></div>
                    <Suspense fallback={<div>Loading...</div>}>
                      {" "}
                      <img
                        className="rlr-banner-splide__banner-img"
                        src={
                          isMobile
                            ? news.pageDetails?.pageBannerMobile
                            : isTablet
                            ? news.pageDetails?.pageBannerTablet
                            : news.pageDetails?.pageBannerPc
                        }
                        alt={news.pageDetails?.pageTitle}
                        loading="lazy"
                      />
                    </Suspense>
                  </div>
                  <article className="rlr-banner-splide__content-wrapper container">
                    <div className="row justify-content-start">
                      <div className="col-xl-9">
                        <div className="rlr-banner-splide__header">
                          <h2
                            className="rlr-banner-splide__slogan rlr-section__title--main"
                            style={{ textShadow: "1px 1px 2px #000" }}
                          >
                            {news.pageDetails?.pageTitle}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </article>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="rlr-search-results-page__breadcrumb-section">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb rlr-breadcrumb__items">
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/">Home </Link>
              </li>
              <li
                className="breadcrumb-item rlr-breadcrumb__item active"
                aria-current="page"
              >
                {news.pageDetails?.pageTitle}
              </li>
            </ol>
          </nav>
          <div className="rlr-icon-text">
            <i className="rlr-icon-font flaticon-phone-receiver-silhouette">
              {" "}
            </i>{" "}
            <Link
              to="tel:(+202) 3854 1010"
              className="rlr-search-results-page__phone"
            >
              Questions? (+202) 3854 1010{" "}
            </Link>
          </div>
        </div>
      </div>
      {loading && scroll ? (
        <>
          <ScrollToTop />
        </>
      ) : (
        <section className="blog-standard-section py-5 news-list">
          <div className="container">
            <div className="row my-5">
              <div className="search-wrapper" style={{ position: "relative" }}>
                <SearchIconWrapper>
                  <SearchIcon sx={{ color: "#e60000" }} />
                </SearchIconWrapper>
                <input
                  className="form-control ps-5"
                  placeholder="Search…"
                  value={searchTerm}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            {news.items?.length > 0 ? (
              <>
                {" "}
                <div className="row mb-2">
                  {news.items?.map((newsItem, index) => (
                    <div className="col-md-6 col-xl-4 pb-30" key={index}>
                      <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                        <div className="col-auto d-lg-block">
                          <img
                            src={newsItem.newsPhoto}
                            alt={newsItem.newsTitleSys}
                            loading="lazy"
                          />
                        </div>
                        <div
                          className="col p-4 d-flex flex-column position-static"
                          style={{ minHeight: "180px" }}
                        >
                          <h3
                            className="mb-0   rlr-section__title--main"
                            style={{ fontWeight: "900", fontSize: "1.4rem" }}
                          >
                            {newsItem.newsTitleSys}
                          </h3>
                          <div className="mb-1 text-muted pt-5">
                            <i className="rlr-icon-font flaticon-calendar"> </i>{" "}
                            {newsItem.newsDateTime}
                          </div>
                          <Link
                            to={`/travco-news/${newsItem.newsUrl}`}
                            className="stretched-link"
                          >
                            Continue reading
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div id="PaggingContainer" className="text-center">
                  <div className="pagination-container">
                    <ul className="pagination">
                      <li
                        className="PagedList-skipToPrevious"
                        onClick={handlePreviousPage}
                        style={{
                          visibility:
                            pageIndex <= news.totalPages && pageIndex > 1
                              ? "visible"
                              : "hidden",
                        }}
                      >
                        {" "}
                        <Link rel="prev">«</Link>
                      </li>
                      {Array.from({ length: news.totalPages }, (_, index) => (
                        <li
                          className={index + 1 === pageIndex ? "active" : ""}
                          key={index}
                          onClick={() => {
                            handlePageChange(index + 1);
                          }}
                        >
                          <Link>{index + 1}</Link>
                        </li>
                      ))}

                      <li
                        className="PagedList-skipToNext"
                        onClick={handleNextPage}
                        style={{
                          visibility:
                            pageIndex === news.totalPages
                              ? "hidden"
                              : "visible",
                        }}
                      >
                        <Link rel="next">»</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            ) : (
              <p className="SiteFontP text-travco">
                No News Matches Your Search!
              </p>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default News;
