import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { fetchOrderDetails } from "../../data/tours/orderSlice";
import Loader from "../../Loader";

const ThanksTour = () => {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const language = useSelector((state) => state.language.language);
  const { orderDetails, loading, error } = useSelector((state) => state.order);

  useEffect(() => {
    if (orderId) {
      dispatch(fetchOrderDetails({ orderId, language }));
    }
  }, [orderId, dispatch, language]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#e60000", padding: "30vh 0" }}
          className="d-flex flex-column justify-content-center align-items-center  mx-auto"
        >
          <h1 className="text-center text-light">{error}</h1>
          <Link to="/" className="btn btn-light text-center  mt-4 text-travco">
            Back To Home
          </Link>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{orderDetails.pageDetails?.pageMetatagTitle}</title>
            <link
              rel="canonical"
              href={`https://travco.com/${language}/ThanksTourConfirmation/${orderId}`}
            />
            <meta name="robots" content="index, follow" />
            <meta
              name="description"
              content={orderDetails.pageDetails?.pageMetatagDescription}
            />
            <meta
              property="og:title"
              content={orderDetails.pageDetails?.metatagTitleFacebook}
            />
            <meta
              property="og:description"
              content={orderDetails.pageDetails?.metatagDescriptionFacebook}
            />
            <meta
              name="twitter:title"
              content={orderDetails.pageDetails?.metatagTitleTwitter}
            />
            <meta
              name="twitter:description"
              content={orderDetails.pageDetails?.metatagDescriptionTwitter}
            />
            <style type="text/css">
              {`

          .form-control{
          height:50px;
          border-color:#0000003b;
          }
             .form-v8-content .form-row .form-row-inner .label {
        position: absolute;
        top: -25px;
      
    }
    .form-v8-content .form-detail .form-row .input-text:focus + .label, .form-v8-content .form-detail .form-row .input-text:valid + .label {
        -webkit-transform: translateY(-10px) scale(1);
    }
    #register, #login {
        display: flex;
        justify-content: space-between !important;
        flex-wrap: wrap !important;
    }
        .react-datepicker-wrapper{
        position:relative;
        width:100%;
        }
        .input-group-prepend span{
        position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
        }
    .react-datepicker-popper{
    z-index: 100}
   @media screen and (min-width:767px){
    .w-45 {
        width: 45% !important;
    }}
        .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
        border-color:#e60000 !important;
        }

   

          `}
            </style>
            <link
              href="TITSolutions/WS/assets/login-booking/css/sourcesanspro-font.css"
              rel="stylesheet"
            />
            <link
              href="TITSolutions/WS/assets/login-booking/css/style.css"
              rel="stylesheet"
            />
            <link
              href="TITSolutions/WS/assets/line-awesome-3.css"
              rel="stylesheet"
            />
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-RS6ZVQS9YL"
            ></script>
            <script>
              {`
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());

            gtag('config', 'G-RS6ZVQS9YL');

            window.addEventListener('load', function () {
                gtag('event', 'purchase', {
                    'transaction_id': '${orderDetails.gtm?.transaction_id}',  
                    'affiliation': '${orderDetails.gtm?.affiliation}',  
                    'value':  ${orderDetails.gtm?.value},  
                    'currency': '${orderDetails.gtm?.currency}',  
                    'tax': ${orderDetails.gtm?.tax}, 
                    'shipping':  ${orderDetails.gtm?.shipping},  
                    'items': [
                        {
                            'item_id': '${orderDetails.gtm?.items?.map(
                              (i) => i.item_id
                            )}',  
                            'item_name':' ${orderDetails.gtm?.items?.map(
                              (i) => i.item_name
                            )}',  
                            'item_brand': '${orderDetails.gtm?.items?.map(
                              (i) => i.item_brand
                            )}', 
                            'item_category':'${orderDetails.gtm?.items?.map(
                              (i) => i.item_category
                            )}',  
                            'item_variant': '${orderDetails.gtm?.items?.map(
                              (i) => i.item_variant
                            )}',  
                            'affiliation': '${orderDetails.gtm?.items?.map(
                              (i) => i.affiliation
                            )}', 
                            'discount': ${orderDetails.gtm?.items?.map(
                              (i) => i.discount
                            )}, 
                            'quantity': ${orderDetails.gtm?.items?.map(
                              (i) => i.quantity
                            )},  
                            'price': ${orderDetails.gtm?.items?.map(
                              (i) => i.price
                            )}  
                        }
                    ]
                });
            });
          `}
            </script>
          </Helmet>
          <section className="outter">
            <div
              id="rlr_banner_slider"
              className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
            >
              <div className="splide__track rlr-banner-splide__track">
                <ul className="splide__list height-internal-pages">
                  <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                    <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                      <div className="overlay-banner"></div>
                      <Suspense fallback={<div>Loading...</div>}>
                        <img
                          src={
                            isMobile
                              ? orderDetails.pageDetails?.pageBannerMobile
                              : isTablet
                              ? orderDetails.pageDetails?.pageBannerTablet
                              : orderDetails.pageDetails?.pageBannerPc
                          }
                          alt={orderDetails.pageDetails?.pageTitle}
                          loading="lazy"
                        />
                      </Suspense>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <div class="container mt-5 pt-5">
            <div class="row">
              <div class="col-lg-12">
                <div class="form-box payment-received-wrap mb-0">
                  <div class="form-title-wrap">
                    <div class="step-bar-wrap text-center mt-5">
                      <ul
                        class="step-bar-list d-flex align-items-center justify-content-around"
                        style={{ listStyle: "none" }}
                      >
                        <li class="step-bar flex-grow-1 step-bar-active ">
                          <span class="icon-element">
                            {" "}
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <p class="pt-2 color-text-2">Choose Tour </p>
                        </li>

                        <li class="step-bar flex-grow-1 step-bar-active ">
                          <span class="icon-element">
                            {" "}
                            <FontAwesomeIcon icon={faCheck} />{" "}
                          </span>
                          <p class="pt-2 color-text-2">Checkout </p>
                        </li>
                        <li class="step-bar flex-grow-1  step-bar-active ">
                          <span class="icon-element">
                            {" "}
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <p class="pt-2 color-text-2">Booking Completed! </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="rlr-section">
            <div className="container-xxl">
              <div className="entry-content">
                <div className="d-flex align-items-lg-center">
                  <div className="woocommerce-info ps-0">
                    <div className="rlr-page-title">
                      <span className="rlr-page-title__icon">
                        {" "}
                        <i className="rlr-icon-font flaticon-seen"> </i>{" "}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-5  ">
                    <h2 className="type-h3 rlr-page-title__title text-success ">
                      Payment Successfully Paid
                    </h2>
                    <span className="rlr-page-title__desc">
                      Thank you for your payment. Your transaction has been
                      successfully completed. You will receive a confirmation
                      email shortly with the details of your order.
                    </span>
                  </div>
                </div>
                <div className="mb-2 ">
                  <h6 className="type-h5 text-travco ">
                    <span className="text-dark">Confirmation number:</span>{" "}
                    {orderDetails?.orderDetails?.orderNumber}
                  </h6>
                </div>
                <div className="rlr-content">
                  <div className="woocommerce">
                    <div className="woocommerce-order row justify-content-between align-items-baseline">
                      <div className="woocommerce-order-details col-lg-4 mx-0">
                        <div className="woocommerce-customer-details">
                          <h2 className="woocommerce-column__title pb-5 mb-0">
                            Your Details
                          </h2>

                          <ul className="woocommerce-details__items px-3 mb-0">
                            <li className="woocommerce-details__item">
                              <span className="woocommerce-details__icon">
                                <i className="rlr-icon-font flaticon-carbon-user">
                                  {" "}
                                </i>
                              </span>
                              <div>
                                <p className="woocommerce-details__title">
                                  {" "}
                                  Name
                                </p>
                                <span className="woocommerce-details__desc">
                                  {orderDetails.orderDetails?.customerFirstName}{" "}
                                  {orderDetails.orderDetails?.customerLastName}
                                </span>
                              </div>
                            </li>

                            <li className="woocommerce-details__item">
                              <span className="woocommerce-details__icon">
                                <i className="rlr-icon-font flaticon-email">
                                  {" "}
                                </i>
                              </span>
                              <div>
                                <p className="woocommerce-details__title">
                                  Email
                                </p>
                                <span className="woocommerce-details__desc">
                                  {" "}
                                  {
                                    orderDetails.orderDetails
                                      ?.customerEmailAddress
                                  }{" "}
                                </span>
                              </div>
                            </li>
                            <li className="woocommerce-details__item">
                              <span className="woocommerce-details__icon">
                                <i className="rlr-icon-font flaticon-telephone">
                                  {" "}
                                </i>
                              </span>
                              <div>
                                <p className="woocommerce-details__title">
                                  Mobile No.
                                </p>
                                <span className="woocommerce-details__desc">
                                  {orderDetails.orderDetails?.customerMobile}
                                </span>
                              </div>
                            </li>

                            {/* <li className="woocommerce-details__item">
                            <span className="woocommerce-details__icon">
                              <i className="rlr-icon-font flaticon-map-marker">
                                {" "}
                              </i>
                            </span>
                            <div>
                              <p className="woocommerce-details__title">
                                Country
                              </p>
                              <span className="woocommerce-details__desc">
                                {orderDetails.orderDetails?.countryName}
                              </span>
                            </div>
                          </li> */}

                            <li className="woocommerce-details__item mb-0">
                              <span className="woocommerce-details__icon">
                                <i className="rlr-icon-font flaticon-right-arrows">
                                  {" "}
                                </i>
                              </span>
                              <div>
                                <p className="woocommerce-details__title">
                                  Nationality
                                </p>
                                <span className="woocommerce-details__desc">
                                  {orderDetails.orderDetails?.nationalityName}
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="woocommerce-order-details col-lg-4 mx-0">
                        <h2 className="woocommerce-column__title pb-2 mb-0">
                          Travel Information
                        </h2>

                        <ul className="woocommerce-details__items mb-0">
                          <li className="woocommerce-details__item d-block mb-3">
                            <div>
                              <p className="woocommerce-details__title">
                                {" "}
                                Accommodation Hotel Name:
                              </p>
                              <span className="woocommerce-details__desc   ">
                                {orderDetails?.orderDetails?.hotelName}
                              </span>
                            </div>
                          </li>

                          <li className="woocommerce-details__item d-block  mb-0">
                            <div>
                              <p className="woocommerce-details__title">
                                {" "}
                                Payment Reference:
                              </p>
                              <span className="woocommerce-details__desc">
                                {orderDetails?.orderDetails?.payTabTranRef}
                              </span>
                              <br />
                              <small>
                                {
                                  orderDetails?.orderDetails
                                    ?.payTabPaymentInfoCardScheme
                                }{" "}
                                {
                                  orderDetails?.orderDetails
                                    ?.payTabPaymentInfoCardType
                                }{" "}
                                (
                                {
                                  orderDetails?.orderDetails
                                    ?.payTabPaymentInfoPaymentDescription
                                }
                                )
                              </small>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="woocommerce-order-details col-lg-3 mx-0">
                        <h2 className="woocommerce-order-details__title">
                          Tour Information
                        </h2>
                        <table className="woocommerce-table woocommerce-table--order-details shop_table order_details">
                          <thead>
                            <tr>
                              <th className="woocommerce-table__product-name product-name">
                                Tour
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <ul className="order-detail-card">
                                  <li className="order-detail-card__header">
                                    <div>
                                      <img
                                        className="order-detail-card__img"
                                        src={
                                          orderDetails.orderDetails
                                            ?.productPhoto
                                        }
                                        alt={
                                          orderDetails.orderDetails?.productName
                                        }
                                      />
                                    </div>
                                    <div>
                                      <p className="order-detail-card__title">
                                        {orderDetails.orderDetails?.productName}
                                      </p>
                                      <p className="order-detail-card__date pt-2">
                                        <span>Date </span>
                                        <span>
                                          {
                                            orderDetails.orderDetails
                                              ?.orderTripDate
                                          }
                                        </span>
                                      </p>
                                    </div>
                                  </li>
                                  <li className="order-detail-card__item">
                                    Adults No:{" "}
                                    {orderDetails.orderDetails?.adultNo}
                                  </li>
                                  {orderDetails.orderDetails?.childNo2 > 0 && (
                                    <li className="order-detail-card__item">
                                      Children Age 2-5.99 :{" "}
                                      {orderDetails.orderDetails?.childNo2}
                                    </li>
                                  )}
                                  {orderDetails.orderDetails?.childNo6 > 0 && (
                                    <li className="order-detail-card__item">
                                      Children Age 6-11.99 :{" "}
                                      {orderDetails.orderDetails?.childNo6}
                                    </li>
                                  )}
                                  <li className="order-detail-card__item mb-0">
                                    <strong>Total :</strong>{" "}
                                    <b className="text-travco">
                                      {
                                        orderDetails.orderDetails
                                          ?.orderTotalAmount
                                      }{" "}
                                      <span className="woocommerce-Price-currencySymbol">
                                        {
                                          orderDetails.orderDetails
                                            ?.payTabCartCurrency
                                        }
                                      </span>{" "}
                                    </b>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default ThanksTour;
