import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { fetchDestinationGuide } from "../../data/destination-guide/destinationGuideSlice";
import { useMediaQuery } from "@mui/material";

const DestinationGuide = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const { destinationGuide, loading, error } = useSelector(
    (state) => state.destinationGuide
  );

  useEffect(() => {
    dispatch(fetchDestinationGuide(language));
  }, [dispatch, language]);
  return (
    <>
      <Helmet>
        <title>
          {destinationGuide.pageDetails?.pageMetatagTitle ||
            "destination guide"}
        </title>
        <link
          rel="canonical"
          href={`https://travco.com/${language}/destination-guide`}
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content={destinationGuide.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={destinationGuide.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={destinationGuide.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={destinationGuide.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={destinationGuide.pageDetails?.metatagDescriptionTwitter}
        />
      </Helmet>{" "}
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>Loading...</div>}>
                    {" "}
                    <img
                      src={
                        isMobile
                          ? destinationGuide.pageDetails?.pageBannerMobile
                          : isTablet
                          ? destinationGuide.pageDetails?.pageBannerTablet
                          : destinationGuide.pageDetails?.pageBannerPc
                      }
                      alt={destinationGuide.pageDetails?.pageTitle}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className="rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {destinationGuide.pageDetails?.pageTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="rlr-search-results-page__breadcrumb-section">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb rlr-breadcrumb__items">
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/">Home </Link>
              </li>
              <li
                className="breadcrumb-item rlr-breadcrumb__item active"
                aria-current="page"
              >
                {destinationGuide.pageDetails?.pageTitle}
              </li>
            </ol>
          </nav>
          <div className="rlr-icon-text">
            <i className="rlr-icon-font flaticon-phone-receiver-silhouette">
              {" "}
            </i>{" "}
            <Link
              to="tel:(+202) 3854 1010"
              className="rlr-search-results-page__phone"
            >
              Questions? (+202) 3854 1010{" "}
            </Link>
          </div>
        </div>
      </div>
      <section className="blog-standard-section pt-100 pb-70">
        <div className="container">
          <div className="row mb-2">
            {destinationGuide.destinations?.map((destination, index) => (
              <div className="col-md-4 pb-30" key={index}>
                <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                  <div className="col-auto  d-lg-block">
                    <img
                      src={destination.destinationMainPhoto}
                      alt={destination.destinationName}
                    />
                  </div>
                  <div className="col p-4 d-flex flex-column position-static">
                    <Link
                      to={`/destination-guide/${destination.destinationUrl}`}
                      className="stretched-link"
                    >
                      <h3
                        className="mb-0   rlr-section__title--main"
                        style={{ fontWeight: "900", fontSize: "1.4rem" }}
                      >
                        {destination.destinationName}
                      </h3>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default DestinationGuide;
