import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHotelsAvailability } from "../../data/juniper-hotels/hotelAvailabilitySlice";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleUp,
  faMagnifyingGlass,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../Loader";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Button } from "react-bootstrap";

import LoaderInternal from "../../LoaderInternal";
import { Link, useNavigate } from "react-router-dom";
import BookingStepTwo from "../../components/booking-steps/BookingStepTwo";
import LoaderHotels from "../../LoaderHotels";

SwiperCore.use([Navigation, Pagination, Autoplay]);
const HotelAvailability = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const [view, setView] = useState(false);
  const [viewSearchBooking, setViewSearchBooking] = useState(false);
  const { hotelsAvailability, loading, error, isError } = useSelector(
    (state) => state.hotelsAvailability
  );
  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };
  const language = useSelector((state) => state.language.language);
  const destinationIdLocalStorage = localStorage.getItem("destinationId");
  const [destinationId, setDestinationId] = useState(destinationIdLocalStorage);
  const nationalityLocalStorage = localStorage.getItem("nationality");
  const [nationality, setNationality] = useState(nationalityLocalStorage);
  const startDateHotelsLocalStorage = localStorage.getItem("startDateHotels");
  const [startDateHotels, setStartDateHotels] = useState(
    new Date(startDateHotelsLocalStorage)
  );
  const endDateHotelsLocalStorage = localStorage.getItem("endDateHotels");
  const [endDateHotels, setEndDateHotels] = useState(
    new Date(endDateHotelsLocalStorage)
  );
  useEffect(() => {
    if (startDateHotels) {
      const nextDay = new Date(startDateHotels);
      nextDay.setDate(nextDay.getDate() + 1);
      setEndDateHotels(nextDay);
    }
  }, [startDateHotels]);

  const roomsHotelsLocalStorage = localStorage.getItem("roomsHotels");
  const [roomsHotels, setRoomsHotels] = useState(roomsHotelsLocalStorage);
  const adultsHotels1RLocalStorage = localStorage.getItem("adultsHotels1R");
  const [adultsHotels1R, setAdultsHotels1R] = useState(
    adultsHotels1RLocalStorage
  );
  const adultsHotels2RLocalStorage = localStorage.getItem("adultsHotels2R");
  const [adultsHotels2R, setAdultsHotels2R] = useState(
    adultsHotels2RLocalStorage
  );
  const adultsHotels3RLocalStorage = localStorage.getItem("adultsHotels3R");
  const [adultsHotels3R, setAdultsHotels3R] = useState(
    adultsHotels3RLocalStorage
  );
  const childrenHotels1RLocalStorage = localStorage.getItem("childrenHotels1R");
  const [childrenHotels1R, setChildrenHotels1R] = useState(
    childrenHotels1RLocalStorage
  );
  const childrenHotels2RLocalStorage = localStorage.getItem("childrenHotels2R");
  const [childrenHotels2R, setChildrenHotels2R] = useState(
    childrenHotels2RLocalStorage
  );
  const childrenHotels3RLocalStorage = localStorage.getItem("childrenHotels3R");
  const [childrenHotels3R, setChildrenHotels3R] = useState(
    childrenHotels3RLocalStorage
  );
  const childrenAge1R1LocalStorage = localStorage.getItem("childrenAge1R1");
  const [childrenAge1R1, setChildrenAge1R1] = useState(
    childrenAge1R1LocalStorage
  );
  const childrenAge1R2LocalStorage = localStorage.getItem("childrenAge1R2");
  const [childrenAge1R2, setChildrenAge1R2] = useState(
    childrenAge1R2LocalStorage
  );
  const childrenAge2R1LocalStorage = localStorage.getItem("childrenAge2R1");
  const [childrenAge2R1, setChildrenAge2R1] = useState(
    childrenAge2R1LocalStorage
  );
  const childrenAge2R2LocalStorage = localStorage.getItem("childrenAge2R2");
  const [childrenAge2R2, setChildrenAge2R2] = useState(
    childrenAge2R2LocalStorage
  );
  const childrenAge3R1LocalStorage = localStorage.getItem("childrenAge3R1");
  const [childrenAge3R1, setChildrenAge3R1] = useState(
    childrenAge3R1LocalStorage
  );
  const childrenAge3R2LocalStorage = localStorage.getItem("childrenAge3R2");
  const [childrenAge3R2, setChildrenAge3R2] = useState(
    childrenAge3R2LocalStorage
  );
  useEffect(() => {
    if (roomsHotels == 1) {
      setAdultsHotels2R(0);
      setAdultsHotels3R(0);
      setChildrenHotels2R(0);
      setChildrenHotels3R(0);
      if (childrenHotels1R == 1) {
        setChildrenAge1R1(childrenAge1R1 || "2");
      } else if (childrenHotels1R == 2) {
        setChildrenAge1R1(childrenAge1R1 || "2");
        setChildrenAge1R2(childrenAge1R2 || "2");
      }
    } else if (roomsHotels == 2) {
      setAdultsHotels2R(adultsHotels2R || "2");
      setAdultsHotels3R(0);
      setChildrenHotels2R(childrenHotels2R || "0");
      setChildrenHotels3R(0);
      if (childrenHotels2R != 0 || childrenHotels1R != 0) {
        if (childrenHotels1R == 1) {
          setChildrenAge1R1(childrenAge1R1 || "2");
        } else if (childrenHotels1R == 2) {
          setChildrenAge1R1(childrenAge1R1 || "2");
          setChildrenAge1R2(childrenAge1R2 || "2");
        }
        if (childrenHotels2R == 1) {
          setChildrenAge2R1(childrenAge2R1 || "2");
        } else if (childrenHotels2R == 2) {
          setChildrenAge2R1(childrenAge2R1 || "2");
          setChildrenAge2R2(childrenAge2R2 || "2");
        }
      }
    } else {
      setAdultsHotels2R(adultsHotels2R || "2");
      setAdultsHotels3R(adultsHotels3R || "2");
      setChildrenHotels2R(childrenHotels2R || "0");
      setChildrenHotels3R(childrenHotels3R || "0");
      if (
        childrenHotels3R != 0 ||
        childrenHotels2R != 0 ||
        childrenHotels1R != 0
      ) {
        if (childrenHotels1R == 1) {
          setChildrenAge1R1(childrenAge1R1 || "2");
        } else if (childrenHotels1R == 2) {
          setChildrenAge1R1(childrenAge1R1 || "2");
          setChildrenAge1R2(childrenAge1R2 || "2");
        }
        if (childrenHotels2R == 1) {
          setChildrenAge2R1(childrenAge2R1 || "2");
        } else if (childrenHotels2R == 2) {
          setChildrenAge2R1(childrenAge2R1 || "2");
          setChildrenAge2R2(childrenAge2R2 || "2");
        }
      }
      if (childrenHotels3R == 1) {
        setChildrenAge3R1(childrenAge3R1 || "2");
      } else if (childrenHotels3R == 2) {
        setChildrenAge3R1(childrenAge3R1 || "2");
        setChildrenAge3R2(childrenAge3R2 || "2");
      }
    }
  }, [
    adultsHotels2R,
    adultsHotels3R,
    childrenAge1R1,
    childrenAge1R2,
    childrenAge2R1,
    childrenAge2R2,
    childrenAge3R1,
    childrenAge3R2,
    childrenHotels1R,
    childrenHotels2R,
    childrenHotels3R,
    roomsHotels,
  ]);

  const currency = useSelector((state) => state.currency.currency);
  // State variables for checkboxes
  const [chk3Stars, setChk3Stars] = useState(false);
  const [chk4Stars, setChk4Stars] = useState(false);
  const [chk5Stars, setChk5Stars] = useState(false);
  const [other, setOther] = useState(false);
  const [AllInclusive, setAllInclusive] = useState(false);
  const [BedAndBreakfast, setBedAndBreakfast] = useState(false);
  const [fullboard, setFullboard] = useState(false);
  const [halfboard, setHalfboard] = useState(false);
  const [RoomOnly, setRoomOnly] = useState(false);
  const [nomeal, setNomeal] = useState(false);
  const renderStars = (rating) => {
    const filledStar = <i className="rlr-icon-font flaticon-star-1"></i>;
    const emptyStar = <i className="rlr-icon-font flaticon-star"></i>;
    const stars = [];

    for (let i = 0; i < rating; i++) {
      stars.push(filledStar);
    }
    for (let i = rating; i < 5; i++) {
      stars.push(emptyStar);
    }

    return stars;
  };

  useEffect(() => {
    const hotelData = {
      AllInclusive: AllInclusive ? "All Inclusive" : "",
      BedAndBreakfast: BedAndBreakfast ? "Bed & Breakfast" : "",
      fullboard: fullboard ? "full-board" : "",
      halfboard: halfboard ? "half-board" : "",
      RoomOnly: RoomOnly ? "Room Only" : "",

      NoMeal: nomeal ? "nomeal" : "",
      chk_3Stars: chk3Stars ? "3 Stars" : "",
      chk_4Stars: chk4Stars ? "4 Stars" : "",
      chk_5Stars: chk5Stars ? "5 Stars" : "",
      DestinationId: destinationId,
      StartDate: startDateHotels.toISOString().split("T")[0],
      EndDate: endDateHotels.toISOString().split("T")[0],
      nationality: nationality,
      ddl_RoomsNo: roomsHotels,
      ddl_R1ANO: adultsHotels1R,
      ddl_R1CNO: childrenHotels1R,
      ddl_R1CA1: childrenAge1R1,
      ddl_R1CA2: childrenAge1R2,
      ddl_R2ANO: adultsHotels2R,
      ddl_R2CNO: childrenHotels2R,
      ddl_R2CA1: childrenAge2R1,
      ddl_R2CA2: childrenAge2R2,
      ddl_R3ANO: adultsHotels3R,
      ddl_R3CNO: childrenHotels3R,
      ddl_R3CA1: childrenAge3R1,
      ddl_R3CA2: childrenAge3R2,
      currency: currency,
    };
    // const formData = {
    //   AllInclusive: AllInclusive ? "All Inclusive" : "",
    //   BedAndBreakfast: BedAndBreakfast ? "Bed & Breakfast" : "",
    //   fullboard: fullboard ? "full-board" : "",
    //   halfboard: halfboard ? "half-board" : "",
    //   RoomOnly: RoomOnly ? "Room Only" : "",

    //   NoMeal: nomeal ? "nomeal" : "",
    //   chk_3Stars: chk3Stars ? "3 Stars" : "",
    //   chk_4Stars: chk4Stars ? "4 Stars" : "",
    //   chk_5Stars: chk5Stars ? "5 Stars" : "",
    //   DestinationId: destinationId,
    //   StartDate: startDateHotels.toISOString().split("T")[0],
    //   EndDate: endDateHotels.toISOString().split("T")[0],
    //   nationality: nationality,
    //   ddl_RoomsNo: roomsHotels,
    //   ddl_R1ANO: adultsHotels1R,
    //   ddl_R1CNO: childrenHotels1R,
    //   ddl_R1CA1: childrenAge1R1,
    //   ddl_R1CA2: childrenAge1R2,
    //   ddl_R2ANO: adultsHotels2R,
    //   ddl_R2CNO: childrenHotels2R,
    //   ddl_R2CA1: childrenAge2R1,
    //   ddl_R2CA2: childrenAge2R2,
    //   ddl_R3ANO: adultsHotels3R,
    //   ddl_R3CNO: childrenHotels3R,
    //   ddl_R3CA1: childrenAge3R1,
    //   ddl_R3CA2: childrenAge3R2,
    //   currency: currency,
    // };
    dispatch(fetchHotelsAvailability(hotelData));
  }, [
    AllInclusive,
    BedAndBreakfast,
    fullboard,
    halfboard,
    RoomOnly,
    nomeal,
    chk3Stars,
    chk4Stars,
    chk5Stars,
    currency,
  ]);

  // search
  const [searchTerm, setSearchTerm] = useState("");
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredItems = hotelsAvailability.Hotels?.map((hotel) => hotel).filter(
    (item) => item.HotelName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const hotelData = {
      AllInclusive: AllInclusive ? "All Inclusive" : "",
      BedAndBreakfast: BedAndBreakfast ? "Bed & Breakfast" : "",
      fullboard: fullboard ? "full-board" : "",
      halfboard: halfboard ? "half-board" : "",
      RoomOnly: RoomOnly ? "Room Only" : "",
      NoMeal: nomeal ? "nomeal" : "",
      chk_3Stars: chk3Stars ? "3 Stars" : "",
      chk_4Stars: chk4Stars ? "4 Stars" : "",
      chk_5Stars: chk5Stars ? "5 Stars" : "",
      DestinationId: destinationId,
      StartDate: startDateHotels.toISOString().split("T")[0],
      EndDate: endDateHotels.toISOString().split("T")[0],
      nationality: nationality,
      ddl_RoomsNo: roomsHotels,
      ddl_R1ANO: adultsHotels1R,
      ddl_R1CNO: childrenHotels1R,
      ddl_R1CA1: childrenAge1R1,
      ddl_R1CA2: childrenAge1R2,
      ddl_R2ANO: adultsHotels2R,
      ddl_R2CNO: childrenHotels2R,
      ddl_R2CA1: childrenAge2R1,
      ddl_R2CA2: childrenAge2R2,
      ddl_R3ANO: adultsHotels3R,
      ddl_R3CNO: childrenHotels3R,
      ddl_R3CA1: childrenAge3R1,
      ddl_R3CA2: childrenAge3R2,
      currency: currency,
    };

    dispatch(fetchHotelsAvailability(hotelData));
    if (!loading && !isError) {
      localStorage.setItem("destinationId", destinationId);
      localStorage.setItem(
        "startDateHotels",
        startDateHotels.toISOString().split("T")[0]
      );
      localStorage.setItem(
        "endDateHotels",
        endDateHotels.toISOString().split("T")[0]
      );
      localStorage.setItem("nationality", nationality);
      localStorage.setItem("roomsHotels", roomsHotels);
      localStorage.setItem("adultsHotels1R", adultsHotels1R);
      localStorage.setItem("adultsHotels2R", adultsHotels2R);
      localStorage.setItem("adultsHotels3R", adultsHotels3R);
      localStorage.setItem("childrenHotels1R", childrenHotels1R);
      localStorage.setItem("childrenAge1R1", childrenAge1R1);
      localStorage.setItem("childrenAge1R2", childrenAge1R2);
      localStorage.setItem("childrenHotels2R", childrenHotels2R);
      localStorage.setItem("childrenAge2R1", childrenAge2R1);
      localStorage.setItem("childrenAge2R2", childrenAge2R2);
      localStorage.setItem("childrenHotels3R", childrenHotels3R);
      localStorage.setItem("childrenAge3R1", childrenAge3R1);
      localStorage.setItem("childrenAge3R2", childrenAge3R2);
      navigate("/hotels-availability");
    }
  };

  return (
    <>
      <Helmet>
        <title>{hotelsAvailability.pageDetails?.pageMetatagTitle}</title>
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href={`https://travco.com/${language}/hotels-availability`}
          data-react-helmet="true"
        />
        <meta
          name="description"
          content={hotelsAvailability.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={hotelsAvailability.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={hotelsAvailability.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={hotelsAvailability.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={hotelsAvailability.pageDetails?.metatagDescriptionTwitter}
        />
        <link
          href="TITSolutions/WS/assets/hotelsAvailability.css"
          rel="stylesheet"
          type="text/css"
        />{" "}
        <link
          href="TITSolutions/WS/assets/hotel-form.css"
          rel="stylesheet"
          type="text/css"
        />
      </Helmet>
      <aside className="rlr-hero--half-mast ">
        <div className="outter">
          <div
            id="rlr_banner_slider"
            className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
          >
            <div className="splide__track rlr-banner-splide__track">
              <ul className="splide__list height-internal-pages">
                <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                  <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                    <div className="overlay-banner"></div>

                    <img
                      className="rlr-banner-splide__banner-img"
                      src={
                        isMobile
                          ? `images/uploads/1.jpg`
                          : isTablet
                          ? `images/uploads/2.jpg`
                          : `images/uploads/Banner-Hotel-Booking.jpg`
                      }
                      alt={
                        hotelsAvailability.PageDetails?.PageTitle || "Hotels"
                      }
                      loading="lazy"
                    />
                  </div>
                  <article className="rlr-banner-splide__content-wrapper container">
                    <div className="row justify-content-start">
                      <div className="col-xl-9">
                        <div className="rlr-banner-splide__header">
                          <h2
                            className="rlr-banner-splide__slogan rlr-section__title--main"
                            style={{ textShadow: "1px 1px 2px #000" }}
                          >
                            {hotelsAvailability.PageDetails?.PageTitle ||
                              "Hotels"}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </article>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </aside>

      <BookingStepTwo />

      <section className="border-bottom container py-lg-5">
        <div className="d-flex flex-column flex-lg-row justify-content-between gap-3 ">
          <div className="col-lg-12 col-xl-12">
            <div className="tab-content hero-tab-pane d-flex flex-wrap justify-content-between gap-0">
              <aside className="col-12 col-xl-3 col-xxxl-3 d-xl-block offset-xxxl-1 mt-5 mt-lg-0">
                <div className="mb-4">
                  {isMobile && (
                    <Button
                      className="btn btn-danger p-2 text-center w-100 d-flex justify-content-center align-items-baseline"
                      onClick={() => setViewSearchBooking(!viewSearchBooking)}
                    >
                      <FontAwesomeIcon
                        icon={
                          viewSearchBooking
                            ? faMagnifyingGlassMinus
                            : faMagnifyingGlassPlus
                        }
                        className="me-2 mb-2"
                      />
                      {viewSearchBooking ? "" : "Adjust Your Search"}{" "}
                    </Button>
                  )}
                  {viewSearchBooking && isMobile ? (
                    <div className="border border-color-7 rounded mb-5">
                      <div className="border-bottom">
                        <div className="p-4">
                          <span className="font-size-22 fs-3">Search</span>
                          <div
                            className="rlr-authforms__inputgroup"
                            style={{ paddingTop: "10px" }}
                          >
                            <input
                              type="text"
                              placeholder="Search by name..."
                              className="form-control border-1"
                              value={searchTerm}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      <form onSubmit={handleSubmit} className="js-validate">
                        <div className="row d-block nav-select d-lg-flex mb-lg-3 px-2 px-lg-3 align-items-end flex-column">
                          <div className="col-xs-12 col-sm-12 col-md-12 ">
                            <div className="row searcher-row flex-column">
                              {/*  Destinations */}
                              <div className="col-sm-12  mb-lg-0 pt-3">
                                <span className="d-block text-gray-1 font-weight-normal text-left mb-0">
                                  Destinations
                                </span>

                                <div className="d-flex align-items-center ">
                                  <i
                                    className="icon  flaticon-map-marker"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" "}
                                  </i>
                                  <select
                                    className="form-control js-auto-form-param js-autotemplate js-enhanced-select mt-0"
                                    style={{
                                      borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.1)",
                                      borderTop: "0px",
                                      borderRight: "0px",
                                      borderLeft: "0px",
                                    }}
                                    title="Where are you going?"
                                    value={destinationId}
                                    onChange={(e) => {
                                      setDestinationId(e.target.value);
                                      localStorage.setItem(
                                        "destinationId",
                                        destinationId
                                      );
                                    }}
                                  >
                                    <option value="1">Alexandria</option>
                                    <option value="2">Aswan</option>
                                    <option value="3">Cairo</option>
                                    <option value="4">
                                      Coraya Bay, Marsa Alam
                                    </option>
                                    <option value="5">Dahab</option>
                                    <option value="6">
                                      El Gouna, Hurghada
                                    </option>
                                    <option value="7">El Quseir</option>
                                    <option value="8">Hurghada</option>
                                    <option value="9">Luxor</option>
                                    <option value="10">
                                      Makadi Bay, Hurghada
                                    </option>
                                    <option value="11">Marsa Alam </option>
                                    <option value="12">Marsa Matruh</option>
                                    <option value="13">Safaga</option>
                                    <option value="14">
                                      Sahl Hasheeh, Hurghada
                                    </option>
                                    <option value="15">Sharm El Sheikh</option>
                                    <option value="16">Taba</option>
                                  </select>
                                </div>
                              </div>
                              {/* Check-in Date  */}
                              <div className="col-sm-12  mb-lg-0 pt-2">
                                <span className="d-block text-gray-1 text-left font-weight-normal mb-0">
                                  Check-in Date
                                </span>
                                <div className="border-bottom border-width-2 border-color-1">
                                  <div
                                    className="u-datepicker input-group"
                                    style={{
                                      display: "flex",
                                      justifyContent: "left",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <div className="input-group-prepend">
                                      <span className="d-flex align-items-center mr-1">
                                        <i className="flaticon-calendar text-primary font-weight-semi-bold"></i>
                                      </span>
                                    </div>
                                    <DatePicker
                                      selected={startDateHotels}
                                      onChange={(date) => {
                                        setStartDateHotels(date);
                                        localStorage.setItem(
                                          "startDateHotels",
                                          startDateHotels
                                        );
                                      }}
                                      className="ps-3  border-0 form-control"
                                      dateFormat="MM/dd/yyyy"
                                      id="HotelDateID"
                                      required
                                      minDate={addDays(new Date(), 0)}
                                    />
                                  </div>
                                </div>
                              </div>
                              {/* Check-out Date  */}
                              <div className="col-sm-12  mb-lg-0 pt-3">
                                <span className="d-block text-gray-1 text-left font-weight-normal mb-0">
                                  Check-out Date
                                </span>
                                <div className="border-bottom border-width-2 border-color-1">
                                  <div
                                    className="u-datepicker input-group"
                                    style={{
                                      display: "flex",
                                      justifyContent: "left",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <div className="input-group-prepend">
                                      <span className="d-flex align-items-center mr-1">
                                        <i className="flaticon-calendar text-primary font-weight-semi-bold"></i>
                                      </span>
                                    </div>
                                    <DatePicker
                                      selected={endDateHotels}
                                      onChange={(date) => {
                                        setEndDateHotels(date);
                                        localStorage.setItem(
                                          "endDateHotels",
                                          endDateHotels
                                        );
                                      }}
                                      className="ps-3  border-0 form-control"
                                      dateFormat="MM/dd/yyyy"
                                      id="HotelDateID2"
                                      required
                                      minDate={
                                        startDateHotels
                                          ? new Date(
                                              new Date(startDateHotels).setDate(
                                                startDateHotels.getDate() + 1
                                              )
                                            )
                                          : new Date()
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              {/* Rooms  */}
                              <div
                                className="col-xs-12 col-sm-12 col-md-12 pt-3"
                                style={{ marginTop: "0px" }}
                              >
                                <div
                                  className="room-selector js-auto-form-param "
                                  data-room-selector-data='[{"adults":[{"age":30,"isHandicapped":false},{"age":30,"isHandicapped":false}],"children":[],"babies":[]}]'
                                  data-room-selector-options='{"maxRooms":5,"maxPaxes":2147483647,"maxPaxesGroup":40,"minPaxesGroup":20,"maxAdults":12,"minAdults":1,"maxHandicapped":8,"maxChildren":2,"maxBabies":2,"maxBabiesPerAdult":-1,"maxBabyAge":1,"minAdultPaxes":1,"minChildren":0,"minChildrenAge":0,"maxChildrenAge":17,"maxAdultAge":90,"defaultRooms":1,"defaultAdults":2,"defaultAdultAge":30,"defaultChildren":0,"defaultChildrenAge":5,"defaultBabies":0,"defaultBabiesAge":0,"showBabySelector":false,"showAdultAges":false,"showAdultPaxes":true,"showLabelsInsideOptions":false,"roomTitle":"Rooms","roomLabel":"Room","adultLabel":"Adults","childrenLabel":"Children","babiesLabel":"Babies","ageLabel":"Ages","yearsLabel":"years","floatingMode":true,"showRoomPrincipalBottom":true,"showMealSupplement":false,"showPaxClub":false,"showSharedRoom":false,"showHandicap":false,"mealSupplementLabel":"Half board","paxClubLabel":"Club passenger","sharedRoomLabel":"Shared room","handicapLabel":"","childrenRoomMessage":"","showCartaFreccia":false,"cartaFrecciaLabel":"","isCartaFrecciaChecked":false,"showSubTypes":false}'
                                >
                                  <span
                                    className="d-block text-gray-1 text-left font-weight-normal mb-0"
                                    style={{
                                      marginTop: "-5px",
                                      paddingBottom: "10px",
                                    }}
                                  >
                                    Rooms
                                  </span>
                                  <button
                                    type="button"
                                    className="btn btn-secondary room-selector-button mt-0"
                                    onClick={() => setView(!view)}
                                  >
                                    <span className="counter room-counter">
                                      <i className="fa fa-mytravel-home text-primary fs-5 "></i>
                                      <span
                                        className="amount text-dark me-2"
                                        id="roomNum"
                                      >
                                        {roomsHotels}
                                      </span>
                                    </span>
                                    <span className="counter pax-counter">
                                      <i className="rlr-icon-font flaticon-add-user  text-primary fs-5"></i>
                                      <span
                                        className="amount text-dark"
                                        id="adultAndChildNum"
                                      >
                                        {roomsHotels == 1
                                          ? Number(adultsHotels1R) +
                                            Number(childrenHotels1R)
                                          : roomsHotels == 2
                                          ? Number(adultsHotels1R) +
                                            Number(adultsHotels2R) +
                                            Number(childrenHotels1R) +
                                            Number(childrenHotels2R)
                                          : Number(adultsHotels1R) +
                                            Number(adultsHotels2R) +
                                            Number(adultsHotels3R) +
                                            Number(childrenHotels1R) +
                                            Number(childrenHotels2R) +
                                            Number(childrenHotels3R)}
                                      </span>
                                    </span>
                                  </button>
                                  <div className="room-selector-box ">
                                    <div
                                      id="view"
                                      className={`${
                                        view ? "hidden bottom in" : ""
                                      }`}
                                    >
                                      <div className="rooms-container w-100 d-block">
                                        {roomsHotels == 1 ? (
                                          <>
                                            <div className="room d-flex align-items-center flex-wrap">
                                              <div className="rooms-container ">
                                                <label className="form-label">
                                                  Rooms
                                                </label>
                                                <select
                                                  onChange={(e) => {
                                                    setRoomsHotels(
                                                      e.target.value
                                                    );
                                                    localStorage.setItem(
                                                      "roomsHotels",
                                                      roomsHotels
                                                    );
                                                  }}
                                                  value={roomsHotels}
                                                  className="form-control mx-1 border-1"
                                                >
                                                  <option defaultValue={1}>
                                                    1
                                                  </option>
                                                  <option value={2}>2</option>
                                                  <option value={3}>3</option>
                                                </select>
                                              </div>
                                              <div className="adults-container">
                                                <label className="form-label">
                                                  Adults (12+)
                                                </label>
                                                <select
                                                  onChange={(e) => {
                                                    setAdultsHotels1R(
                                                      e.target.value
                                                    );
                                                    localStorage.setItem(
                                                      "adultsHotels1R",
                                                      adultsHotels1R
                                                    );
                                                  }}
                                                  value={adultsHotels1R}
                                                  className="form-control mx-1 border-1"
                                                >
                                                  <option value="1">1</option>
                                                  <option defaultValue="2">
                                                    2
                                                  </option>
                                                  <option value="3">3</option>
                                                </select>
                                              </div>
                                              <div className="children-container">
                                                <label className="form-label">
                                                  Children (2-11.99)
                                                </label>
                                                <select
                                                  onChange={(e) => {
                                                    setChildrenHotels1R(
                                                      e.target.value
                                                    );
                                                    localStorage.setItem(
                                                      "childrenHotels1R",
                                                      childrenHotels1R
                                                    );
                                                  }}
                                                  value={childrenHotels1R}
                                                  className="form-control mx-1 border-1"
                                                >
                                                  <option value="0">0</option>
                                                  <option value="1">1</option>
                                                  <option value="2">2</option>
                                                </select>
                                              </div>
                                              {childrenHotels1R > 1 ? (
                                                <div className="ages children-option-group">
                                                  <label className="form-label">
                                                    Ages
                                                  </label>
                                                  <div className="d-flex w-100 justify-content-between align-items-center ">
                                                    {" "}
                                                    <select
                                                      className="form-control mx-1 border-1"
                                                      onChange={(e) => {
                                                        setChildrenAge1R1(
                                                          e.target.value
                                                        );
                                                        localStorage.setItem(
                                                          "childrenAge1R1",
                                                          childrenAge1R1
                                                        );
                                                      }}
                                                      value={childrenAge1R1}
                                                    >
                                                      <option defaultValue="2">
                                                        2
                                                      </option>
                                                      <option value="3">
                                                        3
                                                      </option>
                                                      <option value="4">
                                                        4
                                                      </option>
                                                      <option value="5">
                                                        5
                                                      </option>
                                                      <option value="6">
                                                        6
                                                      </option>
                                                      <option value="7">
                                                        7
                                                      </option>
                                                      <option value="8">
                                                        8
                                                      </option>
                                                      <option value="9">
                                                        9
                                                      </option>
                                                      <option value="10">
                                                        10
                                                      </option>
                                                      <option value="11">
                                                        11
                                                      </option>
                                                      <option value="12">
                                                        12
                                                      </option>
                                                    </select>
                                                    <select
                                                      className="form-control mx-1 border-1"
                                                      onChange={(e) => {
                                                        setChildrenAge1R2(
                                                          e.target.value
                                                        );
                                                        localStorage.setItem(
                                                          "childrenAge1R2",
                                                          childrenAge1R2
                                                        );
                                                      }}
                                                      value={childrenAge1R2}
                                                    >
                                                      <option defaultValue="2">
                                                        2
                                                      </option>
                                                      <option value="3">
                                                        3
                                                      </option>
                                                      <option value="4">
                                                        4
                                                      </option>
                                                      <option value="5">
                                                        5
                                                      </option>
                                                      <option value="6">
                                                        6
                                                      </option>
                                                      <option value="7">
                                                        7
                                                      </option>
                                                      <option value="8">
                                                        8
                                                      </option>
                                                      <option value="9">
                                                        9
                                                      </option>
                                                      <option value="10">
                                                        10
                                                      </option>
                                                      <option value="11">
                                                        11
                                                      </option>
                                                      <option value="12">
                                                        12
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                              ) : childrenHotels1R >= 1 ? (
                                                <>
                                                  <div className="ages children-option-group">
                                                    <label className="form-label">
                                                      Ages
                                                    </label>
                                                    <div className="d-flex w-100 justify-content-between align-items-center ">
                                                      {" "}
                                                      <select
                                                        className="form-control mx-1 border-1"
                                                        onChange={(e) => {
                                                          setChildrenAge1R1(
                                                            e.target.value
                                                          );
                                                          localStorage.setItem(
                                                            "childrenAge1R1",
                                                            childrenAge1R1
                                                          );
                                                        }}
                                                        value={childrenAge1R1}
                                                      >
                                                        <option defaultValue="2">
                                                          2
                                                        </option>
                                                        <option value="3">
                                                          3
                                                        </option>
                                                        <option value="4">
                                                          4
                                                        </option>
                                                        <option value="5">
                                                          5
                                                        </option>
                                                        <option value="6">
                                                          6
                                                        </option>
                                                        <option value="7">
                                                          7
                                                        </option>
                                                        <option value="8">
                                                          8
                                                        </option>
                                                        <option value="9">
                                                          9
                                                        </option>
                                                        <option value="10">
                                                          10
                                                        </option>
                                                        <option value="11">
                                                          11
                                                        </option>
                                                        <option value="12">
                                                          12
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </>
                                        ) : roomsHotels == 2 ? (
                                          <>
                                            <div className="room d-flex align-items-center flex-wrap">
                                              <div className="rooms-container">
                                                <label className="form-label">
                                                  Rooms
                                                </label>
                                                <select
                                                  onChange={(e) => {
                                                    setRoomsHotels(
                                                      e.target.value
                                                    );
                                                    localStorage.setItem(
                                                      "roomsHotels",
                                                      roomsHotels
                                                    );
                                                  }}
                                                  value={roomsHotels}
                                                  className="form-control mx-1 border-1"
                                                >
                                                  <option value={1}>1</option>
                                                  <option value={2}>2</option>
                                                  <option value={3}>3</option>
                                                </select>
                                              </div>
                                              <div className="adults-container">
                                                <label className="form-label">
                                                  Adults (12+)
                                                </label>
                                                <select
                                                  onChange={(e) => {
                                                    setAdultsHotels1R(
                                                      e.target.value
                                                    );
                                                    localStorage.setItem(
                                                      "adultsHotels1R",
                                                      adultsHotels1R
                                                    );
                                                  }}
                                                  value={adultsHotels1R}
                                                  className="form-control mx-1 border-1"
                                                >
                                                  <option value="1">1</option>
                                                  <option defaultValue="2">
                                                    2
                                                  </option>
                                                  <option value="3">3</option>
                                                </select>
                                              </div>
                                              <div className="children-container">
                                                <label className="form-label">
                                                  Children (2-11.99)
                                                </label>
                                                <select
                                                  onChange={(e) => {
                                                    setChildrenHotels1R(
                                                      e.target.value
                                                    );
                                                    localStorage.setItem(
                                                      "childrenHotels1R",
                                                      childrenHotels1R
                                                    );
                                                  }}
                                                  value={childrenHotels1R}
                                                  className="form-control mx-1 border-1"
                                                >
                                                  <option value="0">0</option>
                                                  <option value="1">1</option>
                                                  <option value="2">2</option>
                                                </select>
                                              </div>
                                              {childrenHotels1R > 1 ? (
                                                <div className="ages children-option-group">
                                                  <label className="form-label">
                                                    Ages
                                                  </label>
                                                  <div className="d-flex w-100 justify-content-between align-items-center ">
                                                    {" "}
                                                    <select
                                                      className="form-control mx-1 border-1"
                                                      onChange={(e) => {
                                                        setChildrenAge1R1(
                                                          e.target.value
                                                        );
                                                        localStorage.setItem(
                                                          "childrenAge1R1",
                                                          childrenAge1R1
                                                        );
                                                      }}
                                                      value={childrenAge1R1}
                                                    >
                                                      <option defaultValue="2">
                                                        2
                                                      </option>
                                                      <option value="3">
                                                        3
                                                      </option>
                                                      <option value="4">
                                                        4
                                                      </option>
                                                      <option value="5">
                                                        5
                                                      </option>
                                                      <option value="6">
                                                        6
                                                      </option>
                                                      <option value="7">
                                                        7
                                                      </option>
                                                      <option value="8">
                                                        8
                                                      </option>
                                                      <option value="9">
                                                        9
                                                      </option>
                                                      <option value="10">
                                                        10
                                                      </option>
                                                      <option value="11">
                                                        11
                                                      </option>
                                                      <option value="12">
                                                        12
                                                      </option>
                                                    </select>
                                                    <select
                                                      className="form-control mx-1 border-1"
                                                      onChange={(e) => {
                                                        setChildrenAge1R2(
                                                          e.target.value
                                                        );
                                                        localStorage.setItem(
                                                          "childrenAge1R2",
                                                          childrenAge1R2
                                                        );
                                                      }}
                                                      value={childrenAge1R2}
                                                    >
                                                      <option defaultValue="2">
                                                        2
                                                      </option>
                                                      <option value="3">
                                                        3
                                                      </option>
                                                      <option value="4">
                                                        4
                                                      </option>
                                                      <option value="5">
                                                        5
                                                      </option>
                                                      <option value="6">
                                                        6
                                                      </option>
                                                      <option value="7">
                                                        7
                                                      </option>
                                                      <option value="8">
                                                        8
                                                      </option>
                                                      <option value="9">
                                                        9
                                                      </option>
                                                      <option value="10">
                                                        10
                                                      </option>
                                                      <option value="11">
                                                        11
                                                      </option>
                                                      <option value="12">
                                                        12
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                              ) : childrenHotels1R >= 1 ? (
                                                <>
                                                  <div className="ages children-option-group">
                                                    <label className="form-label">
                                                      Ages
                                                    </label>
                                                    <div className="d-flex w-100 justify-content-between align-items-center ">
                                                      {" "}
                                                      <select
                                                        className="form-control mx-1 border-1"
                                                        onChange={(e) => {
                                                          setChildrenAge1R1(
                                                            e.target.value
                                                          );
                                                          localStorage.setItem(
                                                            "childrenAge1R1",
                                                            childrenAge1R1
                                                          );
                                                        }}
                                                        value={childrenAge1R1}
                                                      >
                                                        <option defaultValue="2">
                                                          2
                                                        </option>
                                                        <option value="3">
                                                          3
                                                        </option>
                                                        <option value="4">
                                                          4
                                                        </option>
                                                        <option value="5">
                                                          5
                                                        </option>
                                                        <option value="6">
                                                          6
                                                        </option>
                                                        <option value="7">
                                                          7
                                                        </option>
                                                        <option value="8">
                                                          8
                                                        </option>
                                                        <option value="9">
                                                          9
                                                        </option>
                                                        <option value="10">
                                                          10
                                                        </option>
                                                        <option value="11">
                                                          11
                                                        </option>
                                                        <option value="12">
                                                          12
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <hr />
                                            <div className="room d-flex align-items-center flex-wrap">
                                              <div
                                                className="rooms-container"
                                                style={{
                                                  visibility: "hidden",
                                                }}
                                              >
                                                <label className="form-label">
                                                  Rooms
                                                </label>
                                                <select
                                                  onChange={(e) => {
                                                    setRoomsHotels(
                                                      e.target.value
                                                    );
                                                    localStorage.setItem(
                                                      "roomsHotels",
                                                      roomsHotels
                                                    );
                                                  }}
                                                  className="form-control mx-1 border-1"
                                                >
                                                  <option value={1}>1</option>
                                                  <option value={2}>2</option>
                                                  <option value={3}>3</option>
                                                </select>
                                              </div>
                                              <div className="adults-container">
                                                <label className="form-label">
                                                  Adults (12+)
                                                </label>
                                                <select
                                                  onChange={(e) => {
                                                    setAdultsHotels2R(
                                                      e.target.value
                                                    );
                                                    localStorage.setItem(
                                                      "adultsHotels2R",
                                                      adultsHotels2R
                                                    );
                                                  }}
                                                  value={adultsHotels2R}
                                                  className="form-control mx-1 border-1"
                                                >
                                                  <option value="1">1</option>
                                                  <option defaultValue="2">
                                                    2
                                                  </option>
                                                  <option value="3">3</option>
                                                </select>
                                              </div>
                                              <div className="children-container">
                                                <label className="form-label">
                                                  Children (2-11.99)
                                                </label>
                                                <select
                                                  onChange={(e) => {
                                                    setChildrenHotels2R(
                                                      e.target.value
                                                    );
                                                    localStorage.setItem(
                                                      "childrenHotelsR",
                                                      childrenHotels2R
                                                    );
                                                  }}
                                                  value={childrenHotels2R}
                                                  className="form-control mx-1 border-1"
                                                >
                                                  <option value="0">0</option>
                                                  <option value="1">1</option>
                                                  <option value="2">2</option>
                                                </select>
                                              </div>
                                              {childrenHotels2R > 1 ? (
                                                <div className="ages children-option-group">
                                                  <label className="form-label">
                                                    Ages
                                                  </label>
                                                  <div className="d-flex w-100 justify-content-between align-items-center ">
                                                    {" "}
                                                    <select
                                                      className="form-control mx-1 border-1"
                                                      onChange={(e) => {
                                                        setChildrenAge2R1(
                                                          e.target.value
                                                        );
                                                        localStorage.setItem(
                                                          "childrenAge2R1",
                                                          childrenAge2R1
                                                        );
                                                      }}
                                                      value={childrenAge2R1}
                                                    >
                                                      <option defaultValue="2">
                                                        2
                                                      </option>
                                                      <option value="3">
                                                        3
                                                      </option>
                                                      <option value="4">
                                                        4
                                                      </option>
                                                      <option value="5">
                                                        5
                                                      </option>
                                                      <option value="6">
                                                        6
                                                      </option>
                                                      <option value="7">
                                                        7
                                                      </option>
                                                      <option value="8">
                                                        8
                                                      </option>
                                                      <option value="9">
                                                        9
                                                      </option>
                                                      <option value="10">
                                                        10
                                                      </option>
                                                      <option value="11">
                                                        11
                                                      </option>
                                                      <option value="12">
                                                        12
                                                      </option>
                                                    </select>
                                                    <select
                                                      className="form-control mx-1 border-1"
                                                      onChange={(e) => {
                                                        setChildrenAge2R2(
                                                          e.target.value
                                                        );
                                                        localStorage.setItem(
                                                          "childrenAge2R2",
                                                          childrenAge2R2
                                                        );
                                                      }}
                                                      value={childrenAge2R2}
                                                    >
                                                      <option defaultValue="2">
                                                        2
                                                      </option>
                                                      <option value="3">
                                                        3
                                                      </option>
                                                      <option value="4">
                                                        4
                                                      </option>
                                                      <option value="5">
                                                        5
                                                      </option>
                                                      <option value="6">
                                                        6
                                                      </option>
                                                      <option value="7">
                                                        7
                                                      </option>
                                                      <option value="8">
                                                        8
                                                      </option>
                                                      <option value="9">
                                                        9
                                                      </option>
                                                      <option value="10">
                                                        10
                                                      </option>
                                                      <option value="11">
                                                        11
                                                      </option>
                                                      <option value="12">
                                                        12
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                              ) : childrenHotels2R >= 1 ? (
                                                <>
                                                  <div className="ages children-option-group">
                                                    <label className="form-label">
                                                      Ages
                                                    </label>
                                                    <div className="d-flex w-100 justify-content-between align-items-center ">
                                                      {" "}
                                                      <select
                                                        className="form-control mx-1 border-1"
                                                        onChange={(e) => {
                                                          setChildrenAge2R1(
                                                            e.target.value
                                                          );
                                                          localStorage.setItem(
                                                            "childrenAge2R1",
                                                            childrenAge2R1
                                                          );
                                                        }}
                                                        value={childrenAge2R1}
                                                      >
                                                        <option defaultValue="2">
                                                          2
                                                        </option>
                                                        <option value="3">
                                                          3
                                                        </option>
                                                        <option value="4">
                                                          4
                                                        </option>
                                                        <option value="5">
                                                          5
                                                        </option>
                                                        <option value="6">
                                                          6
                                                        </option>
                                                        <option value="7">
                                                          7
                                                        </option>
                                                        <option value="8">
                                                          8
                                                        </option>
                                                        <option value="9">
                                                          9
                                                        </option>
                                                        <option value="10">
                                                          10
                                                        </option>
                                                        <option value="11">
                                                          11
                                                        </option>
                                                        <option value="12">
                                                          12
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <div className="room d-flex align-items-center flex-wrap">
                                              <div className="rooms-container">
                                                <label className="form-label">
                                                  Rooms
                                                </label>
                                                <select
                                                  onChange={(e) => {
                                                    setRoomsHotels(
                                                      e.target.value
                                                    );
                                                    localStorage.setItem(
                                                      "roomsHotels",
                                                      roomsHotels
                                                    );
                                                  }}
                                                  value={roomsHotels}
                                                  className="form-control mx-1 border-1"
                                                >
                                                  <option value={1}>1</option>
                                                  <option value={2}>2</option>
                                                  <option value={3}>3</option>
                                                </select>
                                              </div>
                                              <div className="adults-container">
                                                <label className="form-label">
                                                  Adults (12+)
                                                </label>
                                                <select
                                                  onChange={(e) =>
                                                    setAdultsHotels1R(
                                                      e.target.value
                                                    )
                                                  }
                                                  value={adultsHotels1R}
                                                  className="form-control mx-1 border-1"
                                                >
                                                  <option value="1">1</option>
                                                  <option defaultValue="2">
                                                    2
                                                  </option>
                                                  <option value="3">3</option>
                                                </select>
                                              </div>
                                              <div className="children-container">
                                                <label className="form-label">
                                                  Children (2-11.99)
                                                </label>
                                                <select
                                                  onChange={(e) =>
                                                    setChildrenHotels1R(
                                                      e.target.value
                                                    )
                                                  }
                                                  value={childrenHotels1R}
                                                  className="form-control mx-1 border-1"
                                                >
                                                  <option value="0">0</option>
                                                  <option value="1">1</option>
                                                  <option value="2">2</option>
                                                </select>
                                              </div>
                                              {childrenHotels1R > 1 ? (
                                                <div className="ages children-option-group">
                                                  <label className="form-label">
                                                    Ages
                                                  </label>
                                                  <div className="d-flex w-100 justify-content-between align-items-center ">
                                                    {" "}
                                                    <select
                                                      className="form-control mx-1 border-1"
                                                      onChange={(e) => {
                                                        setChildrenAge1R1(
                                                          e.target.value
                                                        );
                                                        localStorage.setItem(
                                                          "childrenAge1R1",
                                                          childrenAge1R1
                                                        );
                                                      }}
                                                      value={childrenAge1R1}
                                                    >
                                                      <option defaultValue="2">
                                                        2
                                                      </option>
                                                      <option value="3">
                                                        3
                                                      </option>
                                                      <option value="4">
                                                        4
                                                      </option>
                                                      <option value="5">
                                                        5
                                                      </option>
                                                      <option value="6">
                                                        6
                                                      </option>
                                                      <option value="7">
                                                        7
                                                      </option>
                                                      <option value="8">
                                                        8
                                                      </option>
                                                      <option value="9">
                                                        9
                                                      </option>
                                                      <option value="10">
                                                        10
                                                      </option>
                                                      <option value="11">
                                                        11
                                                      </option>
                                                      <option value="12">
                                                        12
                                                      </option>
                                                    </select>
                                                    <select
                                                      className="form-control mx-1 border-1"
                                                      onChange={(e) => {
                                                        setChildrenAge1R2(
                                                          e.target.value
                                                        );
                                                        localStorage.setItem(
                                                          "childrenAge1R2",
                                                          childrenAge1R2
                                                        );
                                                      }}
                                                      value={childrenAge1R2}
                                                    >
                                                      <option defaultValue="2">
                                                        2
                                                      </option>
                                                      <option value="3">
                                                        3
                                                      </option>
                                                      <option value="4">
                                                        4
                                                      </option>
                                                      <option value="5">
                                                        5
                                                      </option>
                                                      <option value="6">
                                                        6
                                                      </option>
                                                      <option value="7">
                                                        7
                                                      </option>
                                                      <option value="8">
                                                        8
                                                      </option>
                                                      <option value="9">
                                                        9
                                                      </option>
                                                      <option value="10">
                                                        10
                                                      </option>
                                                      <option value="11">
                                                        11
                                                      </option>
                                                      <option value="12">
                                                        12
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                              ) : childrenHotels1R >= 1 ? (
                                                <>
                                                  <div className="ages children-option-group">
                                                    <label className="form-label">
                                                      Ages
                                                    </label>
                                                    <div className="d-flex w-100 justify-content-between align-items-center ">
                                                      {" "}
                                                      <select
                                                        className="form-control mx-1 border-1"
                                                        onChange={(e) => {
                                                          setChildrenAge1R1(
                                                            e.target.value
                                                          );
                                                          localStorage.setItem(
                                                            "childrenAge1R1",
                                                            childrenAge1R1
                                                          );
                                                        }}
                                                        value={childrenAge1R1}
                                                      >
                                                        <option defaultValue="2">
                                                          2
                                                        </option>
                                                        <option value="3">
                                                          3
                                                        </option>
                                                        <option value="4">
                                                          4
                                                        </option>
                                                        <option value="5">
                                                          5
                                                        </option>
                                                        <option value="6">
                                                          6
                                                        </option>
                                                        <option value="7">
                                                          7
                                                        </option>
                                                        <option value="8">
                                                          8
                                                        </option>
                                                        <option value="9">
                                                          9
                                                        </option>
                                                        <option value="10">
                                                          10
                                                        </option>
                                                        <option value="11">
                                                          11
                                                        </option>
                                                        <option value="12">
                                                          12
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <hr />
                                            <div className="room d-flex align-items-center flex-wrap">
                                              <div
                                                className="rooms-container"
                                                style={{
                                                  visibility: "hidden",
                                                }}
                                              >
                                                <label className="form-label">
                                                  Rooms
                                                </label>
                                                <select
                                                  onChange={(e) => {
                                                    setRoomsHotels(
                                                      e.target.value
                                                    );
                                                    localStorage.setItem(
                                                      "roomsHotels",
                                                      roomsHotels
                                                    );
                                                  }}
                                                  className="form-control mx-1 border-1"
                                                >
                                                  <option value={1}>1</option>
                                                  <option value={2}>2</option>
                                                  <option value={3}>3</option>
                                                </select>
                                              </div>
                                              <div className="adults-container">
                                                <label className="form-label">
                                                  Adults (12+)
                                                </label>
                                                <select
                                                  onChange={(e) =>
                                                    setAdultsHotels2R(
                                                      e.target.value
                                                    )
                                                  }
                                                  value={adultsHotels2R}
                                                  className="form-control mx-1 border-1"
                                                >
                                                  <option value="1">1</option>
                                                  <option defaultValue="2">
                                                    2
                                                  </option>
                                                  <option value="3">3</option>
                                                </select>
                                              </div>
                                              <div className="children-container">
                                                <label className="form-label">
                                                  Children (2-11.99)
                                                </label>
                                                <select
                                                  onChange={(e) =>
                                                    setChildrenHotels2R(
                                                      e.target.value
                                                    )
                                                  }
                                                  value={childrenHotels2R}
                                                  className="form-control mx-1 border-1"
                                                >
                                                  <option value="0">0</option>
                                                  <option value="1">1</option>
                                                  <option value="2">2</option>
                                                </select>
                                              </div>
                                              {childrenHotels2R > 1 ? (
                                                <div className="ages children-option-group">
                                                  <label className="form-label">
                                                    Ages
                                                  </label>
                                                  <div className="d-flex w-100 justify-content-between align-items-center ">
                                                    {" "}
                                                    <select
                                                      className="form-control mx-1 border-1"
                                                      onChange={(e) => {
                                                        setChildrenAge2R1(
                                                          e.target.value
                                                        );
                                                        localStorage.setItem(
                                                          "childrenAge2R1",
                                                          childrenAge2R1
                                                        );
                                                      }}
                                                      value={childrenAge2R1}
                                                    >
                                                      <option defaultValue="2">
                                                        2
                                                      </option>
                                                      <option value="3">
                                                        3
                                                      </option>
                                                      <option value="4">
                                                        4
                                                      </option>
                                                      <option value="5">
                                                        5
                                                      </option>
                                                      <option value="6">
                                                        6
                                                      </option>
                                                      <option value="7">
                                                        7
                                                      </option>
                                                      <option value="8">
                                                        8
                                                      </option>
                                                      <option value="9">
                                                        9
                                                      </option>
                                                      <option value="10">
                                                        10
                                                      </option>
                                                      <option value="11">
                                                        11
                                                      </option>
                                                      <option value="12">
                                                        12
                                                      </option>
                                                    </select>
                                                    <select
                                                      className="form-control mx-1 border-1"
                                                      onChange={(e) => {
                                                        setChildrenAge2R2(
                                                          e.target.value
                                                        );
                                                        localStorage.setItem(
                                                          "childrenAge2R2",
                                                          childrenAge2R2
                                                        );
                                                      }}
                                                      value={childrenAge2R2}
                                                    >
                                                      <option defaultValue="2">
                                                        2
                                                      </option>
                                                      <option value="3">
                                                        3
                                                      </option>
                                                      <option value="4">
                                                        4
                                                      </option>
                                                      <option value="5">
                                                        5
                                                      </option>
                                                      <option value="6">
                                                        6
                                                      </option>
                                                      <option value="7">
                                                        7
                                                      </option>
                                                      <option value="8">
                                                        8
                                                      </option>
                                                      <option value="9">
                                                        9
                                                      </option>
                                                      <option value="10">
                                                        10
                                                      </option>
                                                      <option value="11">
                                                        11
                                                      </option>
                                                      <option value="12">
                                                        12
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                              ) : childrenHotels2R >= 1 ? (
                                                <>
                                                  <div className="ages children-option-group">
                                                    <label className="form-label">
                                                      Ages
                                                    </label>
                                                    <div className="d-flex w-100 justify-content-between align-items-center ">
                                                      {" "}
                                                      <select
                                                        className="form-control mx-1 border-1"
                                                        onChange={(e) => {
                                                          setChildrenAge2R1(
                                                            e.target.value
                                                          );
                                                          localStorage.setItem(
                                                            "childrenAge2R1",
                                                            childrenAge2R1
                                                          );
                                                        }}
                                                        value={childrenAge2R1}
                                                      >
                                                        <option defaultValue="2">
                                                          2
                                                        </option>
                                                        <option value="3">
                                                          3
                                                        </option>
                                                        <option value="4">
                                                          4
                                                        </option>
                                                        <option value="5">
                                                          5
                                                        </option>
                                                        <option value="6">
                                                          6
                                                        </option>
                                                        <option value="7">
                                                          7
                                                        </option>
                                                        <option value="8">
                                                          8
                                                        </option>
                                                        <option value="9">
                                                          9
                                                        </option>
                                                        <option value="10">
                                                          10
                                                        </option>
                                                        <option value="11">
                                                          11
                                                        </option>
                                                        <option value="12">
                                                          12
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <hr />
                                            <div className="room d-flex align-items-center flex-wrap">
                                              <div
                                                className="rooms-container"
                                                style={{
                                                  visibility: "hidden",
                                                }}
                                              >
                                                <label className="form-label">
                                                  Rooms
                                                </label>
                                                <select
                                                  onChange={(e) => {
                                                    setRoomsHotels(
                                                      e.target.value
                                                    );
                                                    localStorage.setItem(
                                                      "roomsHotels",
                                                      roomsHotels
                                                    );
                                                  }}
                                                  value={roomsHotels}
                                                  className="form-control mx-1 border-1"
                                                >
                                                  <option value={1}>1</option>
                                                  <option value={2}>2</option>
                                                  <option value={3}>3</option>
                                                </select>
                                              </div>
                                              <div className="adults-container">
                                                <label className="form-label">
                                                  Adults (12+)
                                                </label>
                                                <select
                                                  onChange={(e) => {
                                                    setAdultsHotels3R(
                                                      e.target.value
                                                    );
                                                    localStorage.setItem(
                                                      "adultsHotels3R",
                                                      adultsHotels3R
                                                    );
                                                  }}
                                                  value={adultsHotels3R}
                                                  className="form-control mx-1 border-1"
                                                >
                                                  <option value="1">1</option>
                                                  <option defaultValue="2">
                                                    2
                                                  </option>
                                                  <option value="3">3</option>
                                                </select>
                                              </div>
                                              <div className="children-container">
                                                <label className="form-label">
                                                  Children (2-11.99)
                                                </label>
                                                <select
                                                  onChange={(e) => {
                                                    setChildrenHotels3R(
                                                      e.target.value
                                                    );
                                                    localStorage.setItem(
                                                      "childrenHotels3R",
                                                      childrenHotels3R
                                                    );
                                                  }}
                                                  value={childrenHotels3R}
                                                  className="form-control mx-1 border-1"
                                                >
                                                  <option value="0">0</option>
                                                  <option value="1">1</option>
                                                  <option value="2">2</option>
                                                </select>
                                              </div>
                                              {childrenHotels3R > 1 ? (
                                                <div className="ages children-option-group">
                                                  <label className="form-label">
                                                    Ages
                                                  </label>
                                                  <div className="d-flex w-100 justify-content-between align-items-center ">
                                                    {" "}
                                                    <select
                                                      className="form-control mx-1 border-1"
                                                      onChange={(e) => {
                                                        setChildrenAge3R1(
                                                          e.target.value
                                                        );
                                                        localStorage.setItem(
                                                          "childrenAge3R1",
                                                          childrenAge3R1
                                                        );
                                                      }}
                                                      value={childrenAge3R1}
                                                    >
                                                      <option defaultValue="2">
                                                        2
                                                      </option>
                                                      <option value="3">
                                                        3
                                                      </option>
                                                      <option value="4">
                                                        4
                                                      </option>
                                                      <option value="5">
                                                        5
                                                      </option>
                                                      <option value="6">
                                                        6
                                                      </option>
                                                      <option value="7">
                                                        7
                                                      </option>
                                                      <option value="8">
                                                        8
                                                      </option>
                                                      <option value="9">
                                                        9
                                                      </option>
                                                      <option value="10">
                                                        10
                                                      </option>
                                                      <option value="11">
                                                        11
                                                      </option>
                                                      <option value="12">
                                                        12
                                                      </option>
                                                    </select>
                                                    <select
                                                      className="form-control mx-1 border-1"
                                                      onChange={(e) =>
                                                        setChildrenAge3R2(
                                                          e.target.value
                                                        )
                                                      }
                                                      value={childrenAge3R2}
                                                    >
                                                      <option defaultValue="2">
                                                        2
                                                      </option>
                                                      <option value="3">
                                                        3
                                                      </option>
                                                      <option value="4">
                                                        4
                                                      </option>
                                                      <option value="5">
                                                        5
                                                      </option>
                                                      <option value="6">
                                                        6
                                                      </option>
                                                      <option value="7">
                                                        7
                                                      </option>
                                                      <option value="8">
                                                        8
                                                      </option>
                                                      <option value="9">
                                                        9
                                                      </option>
                                                      <option value="10">
                                                        10
                                                      </option>
                                                      <option value="11">
                                                        11
                                                      </option>
                                                      <option value="12">
                                                        12
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                              ) : childrenHotels3R >= 1 ? (
                                                <>
                                                  <div className="ages children-option-group">
                                                    <label className="form-label">
                                                      Ages
                                                    </label>
                                                    <div className="d-flex w-100 justify-content-between align-items-center ">
                                                      {" "}
                                                      <select
                                                        className="form-control mx-1 border-1"
                                                        onChange={(e) => {
                                                          setChildrenAge3R1(
                                                            e.target.value
                                                          );
                                                          localStorage.setItem(
                                                            "childrenAge3R1",
                                                            childrenAge3R1
                                                          );
                                                        }}
                                                        value={childrenAge3R1}
                                                      >
                                                        <option defaultValue="2">
                                                          2
                                                        </option>
                                                        <option value="3">
                                                          3
                                                        </option>
                                                        <option value="4">
                                                          4
                                                        </option>
                                                        <option value="5">
                                                          5
                                                        </option>
                                                        <option value="6">
                                                          6
                                                        </option>
                                                        <option value="7">
                                                          7
                                                        </option>
                                                        <option value="8">
                                                          8
                                                        </option>
                                                        <option value="9">
                                                          9
                                                        </option>
                                                        <option value="10">
                                                          10
                                                        </option>
                                                        <option value="11">
                                                          11
                                                        </option>
                                                        <option value="12">
                                                          12
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <hr />
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Nationality  */}
                              <div
                                className="col-xs-12 col-sm-2 pt-3 col-md-12"
                                style={{ marginTop: "-5px" }}
                              >
                                <span
                                  className="d-block text-gray-1 text-left font-weight-normal mb-0"
                                  style={{ paddingBottom: "13px" }}
                                >
                                  Nationality
                                </span>
                                <select
                                  id="nationality"
                                  style={{
                                    borderBottom:
                                      "1px solid rgba(0, 0, 0, 0.1)",
                                    borderTop: "0px",
                                    borderRight: "0px",
                                    borderLeft: "0px",
                                    paddingLeft: "0px",
                                  }}
                                  name="nationality"
                                  className="form-control"
                                  value={nationality}
                                  onChange={(e) => {
                                    setNationality(e.target.value);
                                    localStorage.setItem(
                                      "nationality",
                                      nationality
                                    );
                                  }}
                                >
                                  <option value="AF">Afghanistan</option>
                                  <option value="AL">Albania</option>
                                  <option value="DZ">Algeria</option>
                                  <option value="AD">Andorra</option>
                                  <option value="AO">Angola</option>
                                  <option value="AG">
                                    Antigua and Barbuda
                                  </option>
                                  <option value="AR">Argentina</option>
                                  <option value="AM">Armenia</option>
                                  <option value="AU">Australia</option>
                                  <option value="AT">Austria</option>
                                  <option value="AZ">Azerbaijan</option>
                                  <option value="BS">Bahamas</option>
                                  <option value="BH">Bahrain</option>
                                  <option value="BD">Bangladesh</option>
                                  <option value="BB">Barbados</option>
                                  <option value="BY">Belarus</option>
                                  <option value="BE">Belgium</option>
                                  <option value="BZ">Belize</option>
                                  <option value="BJ">Benin</option>
                                  <option value="BT">Bhutan</option>
                                  <option value="BO">Bolivia</option>
                                  <option value="BA">
                                    Bosnia and Herzegovina
                                  </option>
                                  <option value="BW">Botswana</option>
                                  <option value="BR">Brazil</option>
                                  <option value="BN">Brunei</option>
                                  <option value="BG">Bulgaria</option>
                                  <option value="BF">Burkina Faso</option>
                                  <option value="BI">Burundi</option>
                                  <option value="CI">Côte d'Ivoire</option>
                                  <option value="CV">Cabo Verde</option>
                                  <option value="KH">Cambodia</option>
                                  <option value="CM">Cameroon</option>
                                  <option value="CA">Canada</option>
                                  <option value="CF">
                                    Central African Republic
                                  </option>
                                  <option value="TD">Chad</option>
                                  <option value="CL">Chile</option>
                                  <option value="CN">China</option>
                                  <option value="CO">Colombia</option>
                                  <option value="KM">Comoros</option>
                                  <option value="CG">
                                    Congo (Congo-Brazzaville)
                                  </option>
                                  <option value="CR">Costa Rica</option>
                                  <option value="HR">Croatia</option>
                                  <option value="CU">Cuba</option>
                                  <option value="CY">Cyprus</option>
                                  <option value="CZ">
                                    Czechia (Czech Republic)
                                  </option>
                                  <option value="CD">
                                    Democratic Republic of the Congo
                                  </option>
                                  <option value="DK">Denmark</option>
                                  <option value="DJ">Djibouti</option>
                                  <option value="DM">Dominica</option>
                                  <option value="DO">Dominican Republic</option>
                                  <option value="EC">Ecuador</option>
                                  <option value="EG">Egypt</option>
                                  <option value="SV">El Salvador</option>
                                  <option value="GQ">Equatorial Guinea</option>
                                  <option value="ER">Eritrea</option>
                                  <option value="EE">Estonia</option>
                                  <option value="SZ">
                                    Eswatini (fmr. "Swaziland")
                                  </option>
                                  <option value="ET">Ethiopia</option>
                                  <option value="FJ">Fiji</option>
                                  <option value="FI">Finland</option>
                                  <option value="FR">France</option>
                                  <option value="GA">Gabon</option>
                                  <option value="GM">Gambia</option>
                                  <option value="GE">Georgia</option>
                                  <option value="DE">Germany</option>
                                  <option value="GH">Ghana</option>
                                  <option value="GR">Greece</option>
                                  <option value="GD">Grenada</option>
                                  <option value="GT">Guatemala</option>
                                  <option value="GN">Guinea</option>
                                  <option value="GW">Guinea-Bissau</option>
                                  <option value="GY">Guyana</option>
                                  <option value="HT">Haiti</option>
                                  <option value="VA">Holy See</option>
                                  <option value="HN">Honduras</option>
                                  <option value="HU">Hungary</option>
                                  <option value="IS">Iceland</option>
                                  <option value="IN">India</option>
                                  <option value="ID">Indonesia</option>
                                  <option value="IR">Iran</option>
                                  <option value="IQ">Iraq</option>
                                  <option value="IE">Ireland</option>
                                  <option value="IL">Israel</option>
                                  <option value="IT">Italy</option>
                                  <option value="JM">Jamaica</option>
                                  <option value="JP">Japan</option>
                                  <option value="JO">Jordan</option>
                                  <option value="KZ">Kazakhstan</option>
                                  <option value="KE">Kenya</option>
                                  <option value="KI">Kiribati</option>
                                  <option value="KW">Kuwait</option>
                                  <option value="KG">Kyrgyzstan</option>
                                  <option value="LA">Laos</option>
                                  <option value="LV">Latvia</option>
                                  <option value="LB">Lebanon</option>
                                  <option value="LS">Lesotho</option>
                                  <option value="LR">Liberia</option>
                                  <option value="LY">Libya</option>
                                  <option value="LI">Liechtenstein</option>
                                  <option value="LT">Lithuania</option>
                                  <option value="LU">Luxembourg</option>
                                  <option value="MG">Madagascar</option>
                                  <option value="MW">Malawi</option>
                                  <option value="MY">Malaysia</option>
                                  <option value="MV">Maldives</option>
                                  <option value="ML">Mali</option>
                                  <option value="MT">Malta</option>
                                  <option value="MH">Marshall Islands</option>
                                  <option value="MR">Mauritania</option>
                                  <option value="MU">Mauritius</option>
                                  <option value="MX">Mexico</option>
                                  <option value="FM">Micronesia</option>
                                  <option value="MD">Moldova</option>
                                  <option value="MC">Monaco</option>
                                  <option value="MN">Mongolia</option>
                                  <option value="ME">Montenegro</option>
                                  <option value="MA">Morocco</option>
                                  <option value="MZ">Mozambique</option>
                                  <option value="MM">
                                    Myanmar (formerly Burma)
                                  </option>
                                  <option value="NA">Namibia</option>
                                  <option value="NR">Nauru</option>
                                  <option value="NP">Nepal</option>
                                  <option value="NL">Netherlands</option>
                                  <option value="NZ">New Zealand</option>
                                  <option value="NI">Nicaragua</option>
                                  <option value="NE">Niger</option>
                                  <option value="NG">Nigeria</option>
                                  <option value="KP">North Korea</option>
                                  <option value="MK">North Macedonia</option>
                                  <option value="NO">Norway</option>
                                  <option value="OM">Oman</option>
                                  <option value="PK">Pakistan</option>
                                  <option value="PW">Palau</option>
                                  <option value="PS">Palestine State</option>
                                  <option value="PA">Panama</option>
                                  <option value="PG">Papua New Guinea</option>
                                  <option value="PY">Paraguay</option>
                                  <option value="PE">Peru</option>
                                  <option value="PH">Philippines</option>
                                  <option value="PL">Poland</option>
                                  <option value="PT">Portugal</option>
                                  <option value="QA">Qatar</option>
                                  <option value="RO">Romania</option>
                                  <option value="RU">Russia</option>
                                  <option value="RW">Rwanda</option>
                                  <option value="KN">
                                    Saint Kitts and Nevis
                                  </option>
                                  <option value="LC">Saint Lucia</option>
                                  <option value="VC">
                                    Saint Vincent and the Grenadines
                                  </option>
                                  <option value="WS">Samoa</option>
                                  <option value="SM">San Marino</option>
                                  <option value="ST">
                                    Sao Tome and Principe
                                  </option>
                                  <option value="SA">Saudi Arabia</option>
                                  <option value="SN">Senegal</option>
                                  <option value="RS">Serbia</option>
                                  <option value="SC">Seychelles</option>
                                  <option value="SL">Sierra Leone</option>
                                  <option value="SG">Singapore</option>
                                  <option value="SK">Slovakia</option>
                                  <option value="SI">Slovenia</option>
                                  <option value="SB">Solomon Islands</option>
                                  <option value="SO">Somalia</option>
                                  <option value="ZA">South Africa</option>
                                  <option value="KR">South Korea</option>
                                  <option value="SS">South Sudan</option>
                                  <option value="ES">Spain</option>
                                  <option value="LK">Sri Lanka</option>
                                  <option value="SD">Sudan</option>
                                  <option value="SR">Suriname</option>
                                  <option value="SE">Sweden</option>
                                  <option value="CH">Switzerland</option>
                                  <option value="SY">Syria</option>
                                  <option value="TJ">Tajikistan</option>
                                  <option value="TZ">Tanzania</option>
                                  <option value="TH">Thailand</option>
                                  <option value="TL">Timor-Leste</option>
                                  <option value="TG">Togo</option>
                                  <option value="TO">Tonga</option>
                                  <option value="TT">
                                    Trinidad and Tobago
                                  </option>
                                  <option value="TN">Tunisia</option>
                                  <option value="TR">Turkey</option>
                                  <option value="TM">Turkmenistan</option>
                                  <option value="TV">Tuvalu</option>
                                  <option value="UG">Uganda</option>
                                  <option value="UA">Ukraine</option>
                                  <option value="AE">
                                    United Arab Emirates
                                  </option>
                                  <option value="GB">United Kingdom</option>
                                  <option value="US">
                                    United States of America
                                  </option>
                                  <option value="UY">Uruguay</option>
                                  <option value="UZ">Uzbekistan</option>
                                  <option value="UV">Vanuatu</option>
                                  <option value="VE">Venezuela</option>
                                  <option value="VN">Vietnam</option>
                                  <option value="YE">Yemen</option>
                                  <option value="ZM">Zambia</option>
                                  <option value="ZW">Zimbabwe</option>
                                  <option value="UN">Unkown</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-12 col-xl-12 align-self-lg-end text-md-right mt-3">
                            <button
                              type="submit"
                              className="btn btn-primary w-100 border-radius-3 mb-xl-0 mb-lg-1 transition-3d-hover"
                              onClick={() => navigate("#loader")}
                            >
                              {" "}
                              <FontAwesomeIcon
                                icon={faMagnifyingGlass}
                                className="mr-2"
                              />
                              Search
                            </button>
                          </div>
                        </div>
                      </form>

                      <Button
                        onClick={() => setViewSearchBooking(false)}
                        className="btn btn-danger d-flex justify-content-end mt-5 mx-auto"
                      >
                        <FontAwesomeIcon icon={faAngleDoubleUp} />
                      </Button>
                    </div>
                  ) : (
                    !isMobile && (
                      <div className="border border-color-7 rounded mb-5">
                        <div className="border-bottom">
                          <div className="p-4">
                            <span className="font-size-22 fs-3">Search</span>
                            <div
                              className="rlr-authforms__inputgroup"
                              style={{ paddingTop: "10px" }}
                            >
                              <input
                                type="text"
                                placeholder="Search by name..."
                                className="form-control border-1"
                                value={searchTerm}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <form onSubmit={handleSubmit} className="js-validate">
                          <div className="row d-block nav-select d-lg-flex mb-lg-3 px-2 px-lg-3 align-items-end flex-column">
                            <div className="col-xs-12 col-sm-12 col-md-12 ">
                              <div className="row searcher-row flex-column">
                                {/*  Destinations */}
                                <div className="col-sm-12  mb-lg-0 pt-3">
                                  <span className="d-block text-gray-1 font-weight-normal text-left mb-0">
                                    Destinations
                                  </span>

                                  <div className="d-flex align-items-center ">
                                    <i
                                      className="icon  flaticon-map-marker"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" "}
                                    </i>
                                    <select
                                      className="form-control js-auto-form-param js-autotemplate js-enhanced-select mt-0"
                                      style={{
                                        borderBottom:
                                          "1px solid rgba(0, 0, 0, 0.1)",
                                        borderTop: "0px",
                                        borderRight: "0px",
                                        borderLeft: "0px",
                                      }}
                                      title="Where are you going?"
                                      value={destinationId}
                                      onChange={(e) => {
                                        setDestinationId(e.target.value);
                                        localStorage.setItem(
                                          "destinationId",
                                          destinationId
                                        );
                                      }}
                                    >
                                      <option value="1">Alexandria</option>
                                      <option value="2">Aswan</option>
                                      <option value="3">Cairo</option>
                                      <option value="4">
                                        Coraya Bay, Marsa Alam
                                      </option>
                                      <option value="5">Dahab</option>
                                      <option value="6">
                                        El Gouna, Hurghada
                                      </option>
                                      <option value="7">El Quseir</option>
                                      <option value="8">Hurghada</option>
                                      <option value="9">Luxor</option>
                                      <option value="10">
                                        Makadi Bay, Hurghada
                                      </option>
                                      <option value="11">Marsa Alam </option>
                                      <option value="12">Marsa Matruh</option>
                                      <option value="13">Safaga</option>
                                      <option value="14">
                                        Sahl Hasheeh, Hurghada
                                      </option>
                                      <option value="15">
                                        Sharm El Sheikh
                                      </option>
                                      <option value="16">Taba</option>
                                    </select>
                                  </div>
                                </div>
                                {/* Check-in Date  */}
                                <div className="col-sm-12  mb-lg-0 pt-2">
                                  <span className="d-block text-gray-1 text-left font-weight-normal mb-0">
                                    Check-in Date
                                  </span>
                                  <div className="border-bottom border-width-2 border-color-1">
                                    <div
                                      className="u-datepicker input-group"
                                      style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                        width: "100%",
                                      }}
                                    >
                                      <div className="input-group-prepend">
                                        <span className="d-flex align-items-center mr-1">
                                          <i className="flaticon-calendar text-primary font-weight-semi-bold"></i>
                                        </span>
                                      </div>
                                      <DatePicker
                                        selected={startDateHotels}
                                        onChange={(date) => {
                                          setStartDateHotels(date);
                                          localStorage.setItem(
                                            "startDateHotels",
                                            startDateHotels
                                          );
                                        }}
                                        className="ps-3  border-0 form-control"
                                        dateFormat="MM/dd/yyyy"
                                        id="HotelDateID"
                                        required
                                        minDate={addDays(new Date(), 0)}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* Check-out Date  */}
                                <div className="col-sm-12  mb-lg-0 pt-3">
                                  <span className="d-block text-gray-1 text-left font-weight-normal mb-0">
                                    Check-out Date
                                  </span>
                                  <div className="border-bottom border-width-2 border-color-1">
                                    <div
                                      className="u-datepicker input-group"
                                      style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                        width: "100%",
                                      }}
                                    >
                                      <div className="input-group-prepend">
                                        <span className="d-flex align-items-center mr-1">
                                          <i className="flaticon-calendar text-primary font-weight-semi-bold"></i>
                                        </span>
                                      </div>
                                      <DatePicker
                                        selected={endDateHotels}
                                        onChange={(date) => {
                                          setEndDateHotels(date);
                                          localStorage.setItem(
                                            "endDateHotels",
                                            endDateHotels
                                          );
                                        }}
                                        className="ps-3  border-0 form-control"
                                        dateFormat="MM/dd/yyyy"
                                        id="HotelDateID2"
                                        required
                                        minDate={
                                          startDateHotels
                                            ? new Date(
                                                new Date(
                                                  startDateHotels
                                                ).setDate(
                                                  startDateHotels.getDate() + 1
                                                )
                                              )
                                            : new Date()
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* Rooms  */}
                                <div
                                  className="col-xs-12 col-sm-12 col-md-12 pt-3"
                                  style={{ marginTop: "0px" }}
                                >
                                  <div
                                    className="room-selector js-auto-form-param "
                                    data-room-selector-data='[{"adults":[{"age":30,"isHandicapped":false},{"age":30,"isHandicapped":false}],"children":[],"babies":[]}]'
                                    data-room-selector-options='{"maxRooms":5,"maxPaxes":2147483647,"maxPaxesGroup":40,"minPaxesGroup":20,"maxAdults":12,"minAdults":1,"maxHandicapped":8,"maxChildren":2,"maxBabies":2,"maxBabiesPerAdult":-1,"maxBabyAge":1,"minAdultPaxes":1,"minChildren":0,"minChildrenAge":0,"maxChildrenAge":17,"maxAdultAge":90,"defaultRooms":1,"defaultAdults":2,"defaultAdultAge":30,"defaultChildren":0,"defaultChildrenAge":5,"defaultBabies":0,"defaultBabiesAge":0,"showBabySelector":false,"showAdultAges":false,"showAdultPaxes":true,"showLabelsInsideOptions":false,"roomTitle":"Rooms","roomLabel":"Room","adultLabel":"Adults","childrenLabel":"Children","babiesLabel":"Babies","ageLabel":"Ages","yearsLabel":"years","floatingMode":true,"showRoomPrincipalBottom":true,"showMealSupplement":false,"showPaxClub":false,"showSharedRoom":false,"showHandicap":false,"mealSupplementLabel":"Half board","paxClubLabel":"Club passenger","sharedRoomLabel":"Shared room","handicapLabel":"","childrenRoomMessage":"","showCartaFreccia":false,"cartaFrecciaLabel":"","isCartaFrecciaChecked":false,"showSubTypes":false}'
                                  >
                                    <span
                                      className="d-block text-gray-1 text-left font-weight-normal mb-0"
                                      style={{
                                        marginTop: "-5px",
                                        paddingBottom: "10px",
                                      }}
                                    >
                                      Rooms
                                    </span>
                                    <button
                                      type="button"
                                      className="btn btn-secondary room-selector-button mt-0"
                                      onClick={() => setView(!view)}
                                    >
                                      <span className="counter room-counter">
                                        <i className="fa fa-mytravel-home text-primary fs-5 "></i>
                                        <span
                                          className="amount text-dark me-2"
                                          id="roomNum"
                                        >
                                          {roomsHotels}
                                        </span>
                                      </span>
                                      <span className="counter pax-counter">
                                        <i className="rlr-icon-font flaticon-add-user  text-primary fs-5"></i>
                                        <span
                                          className="amount text-dark"
                                          id="adultAndChildNum"
                                        >
                                          {roomsHotels == 1
                                            ? Number(adultsHotels1R) +
                                              Number(childrenHotels1R)
                                            : roomsHotels == 2
                                            ? Number(adultsHotels1R) +
                                              Number(adultsHotels2R) +
                                              Number(childrenHotels1R) +
                                              Number(childrenHotels2R)
                                            : Number(adultsHotels1R) +
                                              Number(adultsHotels2R) +
                                              Number(adultsHotels3R) +
                                              Number(childrenHotels1R) +
                                              Number(childrenHotels2R) +
                                              Number(childrenHotels3R)}
                                        </span>
                                      </span>
                                    </button>
                                    <div className="room-selector-box ">
                                      <div
                                        id="view"
                                        className={`${
                                          view ? "hidden bottom in" : ""
                                        }`}
                                      >
                                        <div className="rooms-container w-100 d-block">
                                          {roomsHotels == 1 ? (
                                            <>
                                              <div className="room d-flex align-items-center flex-wrap">
                                                <div className="rooms-container ">
                                                  <label className="form-label">
                                                    Rooms
                                                  </label>
                                                  <select
                                                    onChange={(e) => {
                                                      setRoomsHotels(
                                                        e.target.value
                                                      );
                                                      localStorage.setItem(
                                                        "roomsHotels",
                                                        roomsHotels
                                                      );
                                                    }}
                                                    value={roomsHotels}
                                                    className="form-control mx-1 border-1"
                                                  >
                                                    <option defaultValue={1}>
                                                      1
                                                    </option>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                  </select>
                                                </div>
                                                <div className="adults-container">
                                                  <label className="form-label">
                                                    Adults (12+)
                                                  </label>
                                                  <select
                                                    onChange={(e) => {
                                                      setAdultsHotels1R(
                                                        e.target.value
                                                      );
                                                      localStorage.setItem(
                                                        "adultsHotels1R",
                                                        adultsHotels1R
                                                      );
                                                    }}
                                                    value={adultsHotels1R}
                                                    className="form-control mx-1 border-1"
                                                  >
                                                    <option value="1">1</option>
                                                    <option defaultValue="2">
                                                      2
                                                    </option>
                                                    <option value="3">3</option>
                                                  </select>
                                                </div>
                                                <div className="children-container">
                                                  <label className="form-label">
                                                    Children (2-11.99)
                                                  </label>
                                                  <select
                                                    onChange={(e) => {
                                                      setChildrenHotels1R(
                                                        e.target.value
                                                      );
                                                      localStorage.setItem(
                                                        "childrenHotels1R",
                                                        childrenHotels1R
                                                      );
                                                    }}
                                                    value={childrenHotels1R}
                                                    className="form-control mx-1 border-1"
                                                  >
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                  </select>
                                                </div>
                                                {childrenHotels1R > 1 ? (
                                                  <div className="ages children-option-group">
                                                    <label className="form-label">
                                                      Ages
                                                    </label>
                                                    <div className="d-flex w-100 justify-content-between align-items-center ">
                                                      {" "}
                                                      <select
                                                        className="form-control mx-1 border-1"
                                                        onChange={(e) => {
                                                          setChildrenAge1R1(
                                                            e.target.value
                                                          );
                                                          localStorage.setItem(
                                                            "childrenAge1R1",
                                                            childrenAge1R1
                                                          );
                                                        }}
                                                        value={childrenAge1R1}
                                                      >
                                                        <option defaultValue="2">
                                                          2
                                                        </option>
                                                        <option value="3">
                                                          3
                                                        </option>
                                                        <option value="4">
                                                          4
                                                        </option>
                                                        <option value="5">
                                                          5
                                                        </option>
                                                        <option value="6">
                                                          6
                                                        </option>
                                                        <option value="7">
                                                          7
                                                        </option>
                                                        <option value="8">
                                                          8
                                                        </option>
                                                        <option value="9">
                                                          9
                                                        </option>
                                                        <option value="10">
                                                          10
                                                        </option>
                                                        <option value="11">
                                                          11
                                                        </option>
                                                        <option value="12">
                                                          12
                                                        </option>
                                                      </select>
                                                      <select
                                                        className="form-control mx-1 border-1"
                                                        onChange={(e) => {
                                                          setChildrenAge1R2(
                                                            e.target.value
                                                          );
                                                          localStorage.setItem(
                                                            "childrenAge1R2",
                                                            childrenAge1R2
                                                          );
                                                        }}
                                                        value={childrenAge1R2}
                                                      >
                                                        <option defaultValue="2">
                                                          2
                                                        </option>
                                                        <option value="3">
                                                          3
                                                        </option>
                                                        <option value="4">
                                                          4
                                                        </option>
                                                        <option value="5">
                                                          5
                                                        </option>
                                                        <option value="6">
                                                          6
                                                        </option>
                                                        <option value="7">
                                                          7
                                                        </option>
                                                        <option value="8">
                                                          8
                                                        </option>
                                                        <option value="9">
                                                          9
                                                        </option>
                                                        <option value="10">
                                                          10
                                                        </option>
                                                        <option value="11">
                                                          11
                                                        </option>
                                                        <option value="12">
                                                          12
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                ) : childrenHotels1R >= 1 ? (
                                                  <>
                                                    <div className="ages children-option-group">
                                                      <label className="form-label">
                                                        Ages
                                                      </label>
                                                      <div className="d-flex w-100 justify-content-between align-items-center ">
                                                        {" "}
                                                        <select
                                                          className="form-control mx-1 border-1"
                                                          onChange={(e) => {
                                                            setChildrenAge1R1(
                                                              e.target.value
                                                            );
                                                            localStorage.setItem(
                                                              "childrenAge1R1",
                                                              childrenAge1R1
                                                            );
                                                          }}
                                                          value={childrenAge1R1}
                                                        >
                                                          <option defaultValue="2">
                                                            2
                                                          </option>
                                                          <option value="3">
                                                            3
                                                          </option>
                                                          <option value="4">
                                                            4
                                                          </option>
                                                          <option value="5">
                                                            5
                                                          </option>
                                                          <option value="6">
                                                            6
                                                          </option>
                                                          <option value="7">
                                                            7
                                                          </option>
                                                          <option value="8">
                                                            8
                                                          </option>
                                                          <option value="9">
                                                            9
                                                          </option>
                                                          <option value="10">
                                                            10
                                                          </option>
                                                          <option value="11">
                                                            11
                                                          </option>
                                                          <option value="12">
                                                            12
                                                          </option>
                                                        </select>
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </>
                                          ) : roomsHotels == 2 ? (
                                            <>
                                              <div className="room d-flex align-items-center flex-wrap">
                                                <div className="rooms-container">
                                                  <label className="form-label">
                                                    Rooms
                                                  </label>
                                                  <select
                                                    onChange={(e) => {
                                                      setRoomsHotels(
                                                        e.target.value
                                                      );
                                                      localStorage.setItem(
                                                        "roomsHotels",
                                                        roomsHotels
                                                      );
                                                    }}
                                                    value={roomsHotels}
                                                    className="form-control mx-1 border-1"
                                                  >
                                                    <option value={1}>1</option>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                  </select>
                                                </div>
                                                <div className="adults-container">
                                                  <label className="form-label">
                                                    Adults (12+)
                                                  </label>
                                                  <select
                                                    onChange={(e) => {
                                                      setAdultsHotels1R(
                                                        e.target.value
                                                      );
                                                      localStorage.setItem(
                                                        "adultsHotels1R",
                                                        adultsHotels1R
                                                      );
                                                    }}
                                                    value={adultsHotels1R}
                                                    className="form-control mx-1 border-1"
                                                  >
                                                    <option value="1">1</option>
                                                    <option defaultValue="2">
                                                      2
                                                    </option>
                                                    <option value="3">3</option>
                                                  </select>
                                                </div>
                                                <div className="children-container">
                                                  <label className="form-label">
                                                    Children (2-11.99)
                                                  </label>
                                                  <select
                                                    onChange={(e) => {
                                                      setChildrenHotels1R(
                                                        e.target.value
                                                      );
                                                      localStorage.setItem(
                                                        "childrenHotels1R",
                                                        childrenHotels1R
                                                      );
                                                    }}
                                                    value={childrenHotels1R}
                                                    className="form-control mx-1 border-1"
                                                  >
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                  </select>
                                                </div>
                                                {childrenHotels1R > 1 ? (
                                                  <div className="ages children-option-group">
                                                    <label className="form-label">
                                                      Ages
                                                    </label>
                                                    <div className="d-flex w-100 justify-content-between align-items-center ">
                                                      {" "}
                                                      <select
                                                        className="form-control mx-1 border-1"
                                                        onChange={(e) => {
                                                          setChildrenAge1R1(
                                                            e.target.value
                                                          );
                                                          localStorage.setItem(
                                                            "childrenAge1R1",
                                                            childrenAge1R1
                                                          );
                                                        }}
                                                        value={childrenAge1R1}
                                                      >
                                                        <option defaultValue="2">
                                                          2
                                                        </option>
                                                        <option value="3">
                                                          3
                                                        </option>
                                                        <option value="4">
                                                          4
                                                        </option>
                                                        <option value="5">
                                                          5
                                                        </option>
                                                        <option value="6">
                                                          6
                                                        </option>
                                                        <option value="7">
                                                          7
                                                        </option>
                                                        <option value="8">
                                                          8
                                                        </option>
                                                        <option value="9">
                                                          9
                                                        </option>
                                                        <option value="10">
                                                          10
                                                        </option>
                                                        <option value="11">
                                                          11
                                                        </option>
                                                        <option value="12">
                                                          12
                                                        </option>
                                                      </select>
                                                      <select
                                                        className="form-control mx-1 border-1"
                                                        onChange={(e) => {
                                                          setChildrenAge1R2(
                                                            e.target.value
                                                          );
                                                          localStorage.setItem(
                                                            "childrenAge1R2",
                                                            childrenAge1R2
                                                          );
                                                        }}
                                                        value={childrenAge1R2}
                                                      >
                                                        <option defaultValue="2">
                                                          2
                                                        </option>
                                                        <option value="3">
                                                          3
                                                        </option>
                                                        <option value="4">
                                                          4
                                                        </option>
                                                        <option value="5">
                                                          5
                                                        </option>
                                                        <option value="6">
                                                          6
                                                        </option>
                                                        <option value="7">
                                                          7
                                                        </option>
                                                        <option value="8">
                                                          8
                                                        </option>
                                                        <option value="9">
                                                          9
                                                        </option>
                                                        <option value="10">
                                                          10
                                                        </option>
                                                        <option value="11">
                                                          11
                                                        </option>
                                                        <option value="12">
                                                          12
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                ) : childrenHotels1R >= 1 ? (
                                                  <>
                                                    <div className="ages children-option-group">
                                                      <label className="form-label">
                                                        Ages
                                                      </label>
                                                      <div className="d-flex w-100 justify-content-between align-items-center ">
                                                        {" "}
                                                        <select
                                                          className="form-control mx-1 border-1"
                                                          onChange={(e) => {
                                                            setChildrenAge1R1(
                                                              e.target.value
                                                            );
                                                            localStorage.setItem(
                                                              "childrenAge1R1",
                                                              childrenAge1R1
                                                            );
                                                          }}
                                                          value={childrenAge1R1}
                                                        >
                                                          <option defaultValue="2">
                                                            2
                                                          </option>
                                                          <option value="3">
                                                            3
                                                          </option>
                                                          <option value="4">
                                                            4
                                                          </option>
                                                          <option value="5">
                                                            5
                                                          </option>
                                                          <option value="6">
                                                            6
                                                          </option>
                                                          <option value="7">
                                                            7
                                                          </option>
                                                          <option value="8">
                                                            8
                                                          </option>
                                                          <option value="9">
                                                            9
                                                          </option>
                                                          <option value="10">
                                                            10
                                                          </option>
                                                          <option value="11">
                                                            11
                                                          </option>
                                                          <option value="12">
                                                            12
                                                          </option>
                                                        </select>
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                              <hr />
                                              <div className="room d-flex align-items-center flex-wrap">
                                                <div
                                                  className="rooms-container"
                                                  style={{
                                                    visibility: "hidden",
                                                  }}
                                                >
                                                  <label className="form-label">
                                                    Rooms
                                                  </label>
                                                  <select
                                                    onChange={(e) => {
                                                      setRoomsHotels(
                                                        e.target.value
                                                      );
                                                      localStorage.setItem(
                                                        "roomsHotels",
                                                        roomsHotels
                                                      );
                                                    }}
                                                    className="form-control mx-1 border-1"
                                                  >
                                                    <option value={1}>1</option>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                  </select>
                                                </div>
                                                <div className="adults-container">
                                                  <label className="form-label">
                                                    Adults (12+)
                                                  </label>
                                                  <select
                                                    onChange={(e) => {
                                                      setAdultsHotels2R(
                                                        e.target.value
                                                      );
                                                      localStorage.setItem(
                                                        "adultsHotels2R",
                                                        adultsHotels2R
                                                      );
                                                    }}
                                                    value={adultsHotels2R}
                                                    className="form-control mx-1 border-1"
                                                  >
                                                    <option value="1">1</option>
                                                    <option defaultValue="2">
                                                      2
                                                    </option>
                                                    <option value="3">3</option>
                                                  </select>
                                                </div>
                                                <div className="children-container">
                                                  <label className="form-label">
                                                    Children (2-11.99)
                                                  </label>
                                                  <select
                                                    onChange={(e) => {
                                                      setChildrenHotels2R(
                                                        e.target.value
                                                      );
                                                      localStorage.setItem(
                                                        "childrenHotelsR",
                                                        childrenHotels2R
                                                      );
                                                    }}
                                                    value={childrenHotels2R}
                                                    className="form-control mx-1 border-1"
                                                  >
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                  </select>
                                                </div>
                                                {childrenHotels2R > 1 ? (
                                                  <div className="ages children-option-group">
                                                    <label className="form-label">
                                                      Ages
                                                    </label>
                                                    <div className="d-flex w-100 justify-content-between align-items-center ">
                                                      {" "}
                                                      <select
                                                        className="form-control mx-1 border-1"
                                                        onChange={(e) => {
                                                          setChildrenAge2R1(
                                                            e.target.value
                                                          );
                                                          localStorage.setItem(
                                                            "childrenAge2R1",
                                                            childrenAge2R1
                                                          );
                                                        }}
                                                        value={childrenAge2R1}
                                                      >
                                                        <option defaultValue="2">
                                                          2
                                                        </option>
                                                        <option value="3">
                                                          3
                                                        </option>
                                                        <option value="4">
                                                          4
                                                        </option>
                                                        <option value="5">
                                                          5
                                                        </option>
                                                        <option value="6">
                                                          6
                                                        </option>
                                                        <option value="7">
                                                          7
                                                        </option>
                                                        <option value="8">
                                                          8
                                                        </option>
                                                        <option value="9">
                                                          9
                                                        </option>
                                                        <option value="10">
                                                          10
                                                        </option>
                                                        <option value="11">
                                                          11
                                                        </option>
                                                        <option value="12">
                                                          12
                                                        </option>
                                                      </select>
                                                      <select
                                                        className="form-control mx-1 border-1"
                                                        onChange={(e) => {
                                                          setChildrenAge2R2(
                                                            e.target.value
                                                          );
                                                          localStorage.setItem(
                                                            "childrenAge2R2",
                                                            childrenAge2R2
                                                          );
                                                        }}
                                                        value={childrenAge2R2}
                                                      >
                                                        <option defaultValue="2">
                                                          2
                                                        </option>
                                                        <option value="3">
                                                          3
                                                        </option>
                                                        <option value="4">
                                                          4
                                                        </option>
                                                        <option value="5">
                                                          5
                                                        </option>
                                                        <option value="6">
                                                          6
                                                        </option>
                                                        <option value="7">
                                                          7
                                                        </option>
                                                        <option value="8">
                                                          8
                                                        </option>
                                                        <option value="9">
                                                          9
                                                        </option>
                                                        <option value="10">
                                                          10
                                                        </option>
                                                        <option value="11">
                                                          11
                                                        </option>
                                                        <option value="12">
                                                          12
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                ) : childrenHotels2R >= 1 ? (
                                                  <>
                                                    <div className="ages children-option-group">
                                                      <label className="form-label">
                                                        Ages
                                                      </label>
                                                      <div className="d-flex w-100 justify-content-between align-items-center ">
                                                        {" "}
                                                        <select
                                                          className="form-control mx-1 border-1"
                                                          onChange={(e) => {
                                                            setChildrenAge2R1(
                                                              e.target.value
                                                            );
                                                            localStorage.setItem(
                                                              "childrenAge2R1",
                                                              childrenAge2R1
                                                            );
                                                          }}
                                                          value={childrenAge2R1}
                                                        >
                                                          <option defaultValue="2">
                                                            2
                                                          </option>
                                                          <option value="3">
                                                            3
                                                          </option>
                                                          <option value="4">
                                                            4
                                                          </option>
                                                          <option value="5">
                                                            5
                                                          </option>
                                                          <option value="6">
                                                            6
                                                          </option>
                                                          <option value="7">
                                                            7
                                                          </option>
                                                          <option value="8">
                                                            8
                                                          </option>
                                                          <option value="9">
                                                            9
                                                          </option>
                                                          <option value="10">
                                                            10
                                                          </option>
                                                          <option value="11">
                                                            11
                                                          </option>
                                                          <option value="12">
                                                            12
                                                          </option>
                                                        </select>
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <div className="room d-flex align-items-center flex-wrap">
                                                <div className="rooms-container">
                                                  <label className="form-label">
                                                    Rooms
                                                  </label>
                                                  <select
                                                    onChange={(e) => {
                                                      setRoomsHotels(
                                                        e.target.value
                                                      );
                                                      localStorage.setItem(
                                                        "roomsHotels",
                                                        roomsHotels
                                                      );
                                                    }}
                                                    value={roomsHotels}
                                                    className="form-control mx-1 border-1"
                                                  >
                                                    <option value={1}>1</option>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                  </select>
                                                </div>
                                                <div className="adults-container">
                                                  <label className="form-label">
                                                    Adults (12+)
                                                  </label>
                                                  <select
                                                    onChange={(e) =>
                                                      setAdultsHotels1R(
                                                        e.target.value
                                                      )
                                                    }
                                                    value={adultsHotels1R}
                                                    className="form-control mx-1 border-1"
                                                  >
                                                    <option value="1">1</option>
                                                    <option defaultValue="2">
                                                      2
                                                    </option>
                                                    <option value="3">3</option>
                                                  </select>
                                                </div>
                                                <div className="children-container">
                                                  <label className="form-label">
                                                    Children (2-11.99)
                                                  </label>
                                                  <select
                                                    onChange={(e) =>
                                                      setChildrenHotels1R(
                                                        e.target.value
                                                      )
                                                    }
                                                    value={childrenHotels1R}
                                                    className="form-control mx-1 border-1"
                                                  >
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                  </select>
                                                </div>
                                                {childrenHotels1R > 1 ? (
                                                  <div className="ages children-option-group">
                                                    <label className="form-label">
                                                      Ages
                                                    </label>
                                                    <div className="d-flex w-100 justify-content-between align-items-center ">
                                                      {" "}
                                                      <select
                                                        className="form-control mx-1 border-1"
                                                        onChange={(e) => {
                                                          setChildrenAge1R1(
                                                            e.target.value
                                                          );
                                                          localStorage.setItem(
                                                            "childrenAge1R1",
                                                            childrenAge1R1
                                                          );
                                                        }}
                                                        value={childrenAge1R1}
                                                      >
                                                        <option defaultValue="2">
                                                          2
                                                        </option>
                                                        <option value="3">
                                                          3
                                                        </option>
                                                        <option value="4">
                                                          4
                                                        </option>
                                                        <option value="5">
                                                          5
                                                        </option>
                                                        <option value="6">
                                                          6
                                                        </option>
                                                        <option value="7">
                                                          7
                                                        </option>
                                                        <option value="8">
                                                          8
                                                        </option>
                                                        <option value="9">
                                                          9
                                                        </option>
                                                        <option value="10">
                                                          10
                                                        </option>
                                                        <option value="11">
                                                          11
                                                        </option>
                                                        <option value="12">
                                                          12
                                                        </option>
                                                      </select>
                                                      <select
                                                        className="form-control mx-1 border-1"
                                                        onChange={(e) => {
                                                          setChildrenAge1R2(
                                                            e.target.value
                                                          );
                                                          localStorage.setItem(
                                                            "childrenAge1R2",
                                                            childrenAge1R2
                                                          );
                                                        }}
                                                        value={childrenAge1R2}
                                                      >
                                                        <option defaultValue="2">
                                                          2
                                                        </option>
                                                        <option value="3">
                                                          3
                                                        </option>
                                                        <option value="4">
                                                          4
                                                        </option>
                                                        <option value="5">
                                                          5
                                                        </option>
                                                        <option value="6">
                                                          6
                                                        </option>
                                                        <option value="7">
                                                          7
                                                        </option>
                                                        <option value="8">
                                                          8
                                                        </option>
                                                        <option value="9">
                                                          9
                                                        </option>
                                                        <option value="10">
                                                          10
                                                        </option>
                                                        <option value="11">
                                                          11
                                                        </option>
                                                        <option value="12">
                                                          12
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                ) : childrenHotels1R >= 1 ? (
                                                  <>
                                                    <div className="ages children-option-group">
                                                      <label className="form-label">
                                                        Ages
                                                      </label>
                                                      <div className="d-flex w-100 justify-content-between align-items-center ">
                                                        {" "}
                                                        <select
                                                          className="form-control mx-1 border-1"
                                                          onChange={(e) => {
                                                            setChildrenAge1R1(
                                                              e.target.value
                                                            );
                                                            localStorage.setItem(
                                                              "childrenAge1R1",
                                                              childrenAge1R1
                                                            );
                                                          }}
                                                          value={childrenAge1R1}
                                                        >
                                                          <option defaultValue="2">
                                                            2
                                                          </option>
                                                          <option value="3">
                                                            3
                                                          </option>
                                                          <option value="4">
                                                            4
                                                          </option>
                                                          <option value="5">
                                                            5
                                                          </option>
                                                          <option value="6">
                                                            6
                                                          </option>
                                                          <option value="7">
                                                            7
                                                          </option>
                                                          <option value="8">
                                                            8
                                                          </option>
                                                          <option value="9">
                                                            9
                                                          </option>
                                                          <option value="10">
                                                            10
                                                          </option>
                                                          <option value="11">
                                                            11
                                                          </option>
                                                          <option value="12">
                                                            12
                                                          </option>
                                                        </select>
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                              <hr />
                                              <div className="room d-flex align-items-center flex-wrap">
                                                <div
                                                  className="rooms-container"
                                                  style={{
                                                    visibility: "hidden",
                                                  }}
                                                >
                                                  <label className="form-label">
                                                    Rooms
                                                  </label>
                                                  <select
                                                    onChange={(e) => {
                                                      setRoomsHotels(
                                                        e.target.value
                                                      );
                                                      localStorage.setItem(
                                                        "roomsHotels",
                                                        roomsHotels
                                                      );
                                                    }}
                                                    className="form-control mx-1 border-1"
                                                  >
                                                    <option value={1}>1</option>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                  </select>
                                                </div>
                                                <div className="adults-container">
                                                  <label className="form-label">
                                                    Adults (12+)
                                                  </label>
                                                  <select
                                                    onChange={(e) =>
                                                      setAdultsHotels2R(
                                                        e.target.value
                                                      )
                                                    }
                                                    value={adultsHotels2R}
                                                    className="form-control mx-1 border-1"
                                                  >
                                                    <option value="1">1</option>
                                                    <option defaultValue="2">
                                                      2
                                                    </option>
                                                    <option value="3">3</option>
                                                  </select>
                                                </div>
                                                <div className="children-container">
                                                  <label className="form-label">
                                                    Children (2-11.99)
                                                  </label>
                                                  <select
                                                    onChange={(e) =>
                                                      setChildrenHotels2R(
                                                        e.target.value
                                                      )
                                                    }
                                                    value={childrenHotels2R}
                                                    className="form-control mx-1 border-1"
                                                  >
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                  </select>
                                                </div>
                                                {childrenHotels2R > 1 ? (
                                                  <div className="ages children-option-group">
                                                    <label className="form-label">
                                                      Ages
                                                    </label>
                                                    <div className="d-flex w-100 justify-content-between align-items-center ">
                                                      {" "}
                                                      <select
                                                        className="form-control mx-1 border-1"
                                                        onChange={(e) => {
                                                          setChildrenAge2R1(
                                                            e.target.value
                                                          );
                                                          localStorage.setItem(
                                                            "childrenAge2R1",
                                                            childrenAge2R1
                                                          );
                                                        }}
                                                        value={childrenAge2R1}
                                                      >
                                                        <option defaultValue="2">
                                                          2
                                                        </option>
                                                        <option value="3">
                                                          3
                                                        </option>
                                                        <option value="4">
                                                          4
                                                        </option>
                                                        <option value="5">
                                                          5
                                                        </option>
                                                        <option value="6">
                                                          6
                                                        </option>
                                                        <option value="7">
                                                          7
                                                        </option>
                                                        <option value="8">
                                                          8
                                                        </option>
                                                        <option value="9">
                                                          9
                                                        </option>
                                                        <option value="10">
                                                          10
                                                        </option>
                                                        <option value="11">
                                                          11
                                                        </option>
                                                        <option value="12">
                                                          12
                                                        </option>
                                                      </select>
                                                      <select
                                                        className="form-control mx-1 border-1"
                                                        onChange={(e) => {
                                                          setChildrenAge2R2(
                                                            e.target.value
                                                          );
                                                          localStorage.setItem(
                                                            "childrenAge2R2",
                                                            childrenAge2R2
                                                          );
                                                        }}
                                                        value={childrenAge2R2}
                                                      >
                                                        <option defaultValue="2">
                                                          2
                                                        </option>
                                                        <option value="3">
                                                          3
                                                        </option>
                                                        <option value="4">
                                                          4
                                                        </option>
                                                        <option value="5">
                                                          5
                                                        </option>
                                                        <option value="6">
                                                          6
                                                        </option>
                                                        <option value="7">
                                                          7
                                                        </option>
                                                        <option value="8">
                                                          8
                                                        </option>
                                                        <option value="9">
                                                          9
                                                        </option>
                                                        <option value="10">
                                                          10
                                                        </option>
                                                        <option value="11">
                                                          11
                                                        </option>
                                                        <option value="12">
                                                          12
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                ) : childrenHotels2R >= 1 ? (
                                                  <>
                                                    <div className="ages children-option-group">
                                                      <label className="form-label">
                                                        Ages
                                                      </label>
                                                      <div className="d-flex w-100 justify-content-between align-items-center ">
                                                        {" "}
                                                        <select
                                                          className="form-control mx-1 border-1"
                                                          onChange={(e) => {
                                                            setChildrenAge2R1(
                                                              e.target.value
                                                            );
                                                            localStorage.setItem(
                                                              "childrenAge2R1",
                                                              childrenAge2R1
                                                            );
                                                          }}
                                                          value={childrenAge2R1}
                                                        >
                                                          <option defaultValue="2">
                                                            2
                                                          </option>
                                                          <option value="3">
                                                            3
                                                          </option>
                                                          <option value="4">
                                                            4
                                                          </option>
                                                          <option value="5">
                                                            5
                                                          </option>
                                                          <option value="6">
                                                            6
                                                          </option>
                                                          <option value="7">
                                                            7
                                                          </option>
                                                          <option value="8">
                                                            8
                                                          </option>
                                                          <option value="9">
                                                            9
                                                          </option>
                                                          <option value="10">
                                                            10
                                                          </option>
                                                          <option value="11">
                                                            11
                                                          </option>
                                                          <option value="12">
                                                            12
                                                          </option>
                                                        </select>
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                              <hr />
                                              <div className="room d-flex align-items-center flex-wrap">
                                                <div
                                                  className="rooms-container"
                                                  style={{
                                                    visibility: "hidden",
                                                  }}
                                                >
                                                  <label className="form-label">
                                                    Rooms
                                                  </label>
                                                  <select
                                                    onChange={(e) => {
                                                      setRoomsHotels(
                                                        e.target.value
                                                      );
                                                      localStorage.setItem(
                                                        "roomsHotels",
                                                        roomsHotels
                                                      );
                                                    }}
                                                    value={roomsHotels}
                                                    className="form-control mx-1 border-1"
                                                  >
                                                    <option value={1}>1</option>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                  </select>
                                                </div>
                                                <div className="adults-container">
                                                  <label className="form-label">
                                                    Adults (12+)
                                                  </label>
                                                  <select
                                                    onChange={(e) => {
                                                      setAdultsHotels3R(
                                                        e.target.value
                                                      );
                                                      localStorage.setItem(
                                                        "adultsHotels3R",
                                                        adultsHotels3R
                                                      );
                                                    }}
                                                    value={adultsHotels3R}
                                                    className="form-control mx-1 border-1"
                                                  >
                                                    <option value="1">1</option>
                                                    <option defaultValue="2">
                                                      2
                                                    </option>
                                                    <option value="3">3</option>
                                                  </select>
                                                </div>
                                                <div className="children-container">
                                                  <label className="form-label">
                                                    Children (2-11.99)
                                                  </label>
                                                  <select
                                                    onChange={(e) => {
                                                      setChildrenHotels3R(
                                                        e.target.value
                                                      );
                                                      localStorage.setItem(
                                                        "childrenHotels3R",
                                                        childrenHotels3R
                                                      );
                                                    }}
                                                    value={childrenHotels3R}
                                                    className="form-control mx-1 border-1"
                                                  >
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                  </select>
                                                </div>
                                                {childrenHotels3R > 1 ? (
                                                  <div className="ages children-option-group">
                                                    <label className="form-label">
                                                      Ages
                                                    </label>
                                                    <div className="d-flex w-100 justify-content-between align-items-center ">
                                                      {" "}
                                                      <select
                                                        className="form-control mx-1 border-1"
                                                        onChange={(e) => {
                                                          setChildrenAge3R1(
                                                            e.target.value
                                                          );
                                                          localStorage.setItem(
                                                            "childrenAge3R1",
                                                            childrenAge3R1
                                                          );
                                                        }}
                                                        value={childrenAge3R1}
                                                      >
                                                        <option defaultValue="2">
                                                          2
                                                        </option>
                                                        <option value="3">
                                                          3
                                                        </option>
                                                        <option value="4">
                                                          4
                                                        </option>
                                                        <option value="5">
                                                          5
                                                        </option>
                                                        <option value="6">
                                                          6
                                                        </option>
                                                        <option value="7">
                                                          7
                                                        </option>
                                                        <option value="8">
                                                          8
                                                        </option>
                                                        <option value="9">
                                                          9
                                                        </option>
                                                        <option value="10">
                                                          10
                                                        </option>
                                                        <option value="11">
                                                          11
                                                        </option>
                                                        <option value="12">
                                                          12
                                                        </option>
                                                      </select>
                                                      <select
                                                        className="form-control mx-1 border-1"
                                                        onChange={(e) =>
                                                          setChildrenAge3R2(
                                                            e.target.value
                                                          )
                                                        }
                                                        value={childrenAge3R2}
                                                      >
                                                        <option defaultValue="2">
                                                          2
                                                        </option>
                                                        <option value="3">
                                                          3
                                                        </option>
                                                        <option value="4">
                                                          4
                                                        </option>
                                                        <option value="5">
                                                          5
                                                        </option>
                                                        <option value="6">
                                                          6
                                                        </option>
                                                        <option value="7">
                                                          7
                                                        </option>
                                                        <option value="8">
                                                          8
                                                        </option>
                                                        <option value="9">
                                                          9
                                                        </option>
                                                        <option value="10">
                                                          10
                                                        </option>
                                                        <option value="11">
                                                          11
                                                        </option>
                                                        <option value="12">
                                                          12
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                ) : childrenHotels3R >= 1 ? (
                                                  <>
                                                    <div className="ages children-option-group">
                                                      <label className="form-label">
                                                        Ages
                                                      </label>
                                                      <div className="d-flex w-100 justify-content-between align-items-center ">
                                                        {" "}
                                                        <select
                                                          className="form-control mx-1 border-1"
                                                          onChange={(e) => {
                                                            setChildrenAge3R1(
                                                              e.target.value
                                                            );
                                                            localStorage.setItem(
                                                              "childrenAge3R1",
                                                              childrenAge3R1
                                                            );
                                                          }}
                                                          value={childrenAge3R1}
                                                        >
                                                          <option defaultValue="2">
                                                            2
                                                          </option>
                                                          <option value="3">
                                                            3
                                                          </option>
                                                          <option value="4">
                                                            4
                                                          </option>
                                                          <option value="5">
                                                            5
                                                          </option>
                                                          <option value="6">
                                                            6
                                                          </option>
                                                          <option value="7">
                                                            7
                                                          </option>
                                                          <option value="8">
                                                            8
                                                          </option>
                                                          <option value="9">
                                                            9
                                                          </option>
                                                          <option value="10">
                                                            10
                                                          </option>
                                                          <option value="11">
                                                            11
                                                          </option>
                                                          <option value="12">
                                                            12
                                                          </option>
                                                        </select>
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                              <hr />
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* Nationality  */}
                                <div
                                  className="col-xs-12 col-sm-2 pt-3 col-md-12"
                                  style={{ marginTop: "-5px" }}
                                >
                                  <span
                                    className="d-block text-gray-1 text-left font-weight-normal mb-0"
                                    style={{ paddingBottom: "13px" }}
                                  >
                                    Nationality
                                  </span>
                                  <select
                                    id="nationality"
                                    style={{
                                      borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.1)",
                                      borderTop: "0px",
                                      borderRight: "0px",
                                      borderLeft: "0px",
                                      paddingLeft: "0px",
                                    }}
                                    name="nationality"
                                    className="form-control"
                                    value={nationality}
                                    onChange={(e) => {
                                      setNationality(e.target.value);
                                      localStorage.setItem(
                                        "nationality",
                                        nationality
                                      );
                                    }}
                                  >
                                    <option value="AF">Afghanistan</option>
                                    <option value="AL">Albania</option>
                                    <option value="DZ">Algeria</option>
                                    <option value="AD">Andorra</option>
                                    <option value="AO">Angola</option>
                                    <option value="AG">
                                      Antigua and Barbuda
                                    </option>
                                    <option value="AR">Argentina</option>
                                    <option value="AM">Armenia</option>
                                    <option value="AU">Australia</option>
                                    <option value="AT">Austria</option>
                                    <option value="AZ">Azerbaijan</option>
                                    <option value="BS">Bahamas</option>
                                    <option value="BH">Bahrain</option>
                                    <option value="BD">Bangladesh</option>
                                    <option value="BB">Barbados</option>
                                    <option value="BY">Belarus</option>
                                    <option value="BE">Belgium</option>
                                    <option value="BZ">Belize</option>
                                    <option value="BJ">Benin</option>
                                    <option value="BT">Bhutan</option>
                                    <option value="BO">Bolivia</option>
                                    <option value="BA">
                                      Bosnia and Herzegovina
                                    </option>
                                    <option value="BW">Botswana</option>
                                    <option value="BR">Brazil</option>
                                    <option value="BN">Brunei</option>
                                    <option value="BG">Bulgaria</option>
                                    <option value="BF">Burkina Faso</option>
                                    <option value="BI">Burundi</option>
                                    <option value="CI">Côte d'Ivoire</option>
                                    <option value="CV">Cabo Verde</option>
                                    <option value="KH">Cambodia</option>
                                    <option value="CM">Cameroon</option>
                                    <option value="CA">Canada</option>
                                    <option value="CF">
                                      Central African Republic
                                    </option>
                                    <option value="TD">Chad</option>
                                    <option value="CL">Chile</option>
                                    <option value="CN">China</option>
                                    <option value="CO">Colombia</option>
                                    <option value="KM">Comoros</option>
                                    <option value="CG">
                                      Congo (Congo-Brazzaville)
                                    </option>
                                    <option value="CR">Costa Rica</option>
                                    <option value="HR">Croatia</option>
                                    <option value="CU">Cuba</option>
                                    <option value="CY">Cyprus</option>
                                    <option value="CZ">
                                      Czechia (Czech Republic)
                                    </option>
                                    <option value="CD">
                                      Democratic Republic of the Congo
                                    </option>
                                    <option value="DK">Denmark</option>
                                    <option value="DJ">Djibouti</option>
                                    <option value="DM">Dominica</option>
                                    <option value="DO">
                                      Dominican Republic
                                    </option>
                                    <option value="EC">Ecuador</option>
                                    <option value="EG">Egypt</option>
                                    <option value="SV">El Salvador</option>
                                    <option value="GQ">
                                      Equatorial Guinea
                                    </option>
                                    <option value="ER">Eritrea</option>
                                    <option value="EE">Estonia</option>
                                    <option value="SZ">
                                      Eswatini (fmr. "Swaziland")
                                    </option>
                                    <option value="ET">Ethiopia</option>
                                    <option value="FJ">Fiji</option>
                                    <option value="FI">Finland</option>
                                    <option value="FR">France</option>
                                    <option value="GA">Gabon</option>
                                    <option value="GM">Gambia</option>
                                    <option value="GE">Georgia</option>
                                    <option value="DE">Germany</option>
                                    <option value="GH">Ghana</option>
                                    <option value="GR">Greece</option>
                                    <option value="GD">Grenada</option>
                                    <option value="GT">Guatemala</option>
                                    <option value="GN">Guinea</option>
                                    <option value="GW">Guinea-Bissau</option>
                                    <option value="GY">Guyana</option>
                                    <option value="HT">Haiti</option>
                                    <option value="VA">Holy See</option>
                                    <option value="HN">Honduras</option>
                                    <option value="HU">Hungary</option>
                                    <option value="IS">Iceland</option>
                                    <option value="IN">India</option>
                                    <option value="ID">Indonesia</option>
                                    <option value="IR">Iran</option>
                                    <option value="IQ">Iraq</option>
                                    <option value="IE">Ireland</option>
                                    <option value="IL">Israel</option>
                                    <option value="IT">Italy</option>
                                    <option value="JM">Jamaica</option>
                                    <option value="JP">Japan</option>
                                    <option value="JO">Jordan</option>
                                    <option value="KZ">Kazakhstan</option>
                                    <option value="KE">Kenya</option>
                                    <option value="KI">Kiribati</option>
                                    <option value="KW">Kuwait</option>
                                    <option value="KG">Kyrgyzstan</option>
                                    <option value="LA">Laos</option>
                                    <option value="LV">Latvia</option>
                                    <option value="LB">Lebanon</option>
                                    <option value="LS">Lesotho</option>
                                    <option value="LR">Liberia</option>
                                    <option value="LY">Libya</option>
                                    <option value="LI">Liechtenstein</option>
                                    <option value="LT">Lithuania</option>
                                    <option value="LU">Luxembourg</option>
                                    <option value="MG">Madagascar</option>
                                    <option value="MW">Malawi</option>
                                    <option value="MY">Malaysia</option>
                                    <option value="MV">Maldives</option>
                                    <option value="ML">Mali</option>
                                    <option value="MT">Malta</option>
                                    <option value="MH">Marshall Islands</option>
                                    <option value="MR">Mauritania</option>
                                    <option value="MU">Mauritius</option>
                                    <option value="MX">Mexico</option>
                                    <option value="FM">Micronesia</option>
                                    <option value="MD">Moldova</option>
                                    <option value="MC">Monaco</option>
                                    <option value="MN">Mongolia</option>
                                    <option value="ME">Montenegro</option>
                                    <option value="MA">Morocco</option>
                                    <option value="MZ">Mozambique</option>
                                    <option value="MM">
                                      Myanmar (formerly Burma)
                                    </option>
                                    <option value="NA">Namibia</option>
                                    <option value="NR">Nauru</option>
                                    <option value="NP">Nepal</option>
                                    <option value="NL">Netherlands</option>
                                    <option value="NZ">New Zealand</option>
                                    <option value="NI">Nicaragua</option>
                                    <option value="NE">Niger</option>
                                    <option value="NG">Nigeria</option>
                                    <option value="KP">North Korea</option>
                                    <option value="MK">North Macedonia</option>
                                    <option value="NO">Norway</option>
                                    <option value="OM">Oman</option>
                                    <option value="PK">Pakistan</option>
                                    <option value="PW">Palau</option>
                                    <option value="PS">Palestine State</option>
                                    <option value="PA">Panama</option>
                                    <option value="PG">Papua New Guinea</option>
                                    <option value="PY">Paraguay</option>
                                    <option value="PE">Peru</option>
                                    <option value="PH">Philippines</option>
                                    <option value="PL">Poland</option>
                                    <option value="PT">Portugal</option>
                                    <option value="QA">Qatar</option>
                                    <option value="RO">Romania</option>
                                    <option value="RU">Russia</option>
                                    <option value="RW">Rwanda</option>
                                    <option value="KN">
                                      Saint Kitts and Nevis
                                    </option>
                                    <option value="LC">Saint Lucia</option>
                                    <option value="VC">
                                      Saint Vincent and the Grenadines
                                    </option>
                                    <option value="WS">Samoa</option>
                                    <option value="SM">San Marino</option>
                                    <option value="ST">
                                      Sao Tome and Principe
                                    </option>
                                    <option value="SA">Saudi Arabia</option>
                                    <option value="SN">Senegal</option>
                                    <option value="RS">Serbia</option>
                                    <option value="SC">Seychelles</option>
                                    <option value="SL">Sierra Leone</option>
                                    <option value="SG">Singapore</option>
                                    <option value="SK">Slovakia</option>
                                    <option value="SI">Slovenia</option>
                                    <option value="SB">Solomon Islands</option>
                                    <option value="SO">Somalia</option>
                                    <option value="ZA">South Africa</option>
                                    <option value="KR">South Korea</option>
                                    <option value="SS">South Sudan</option>
                                    <option value="ES">Spain</option>
                                    <option value="LK">Sri Lanka</option>
                                    <option value="SD">Sudan</option>
                                    <option value="SR">Suriname</option>
                                    <option value="SE">Sweden</option>
                                    <option value="CH">Switzerland</option>
                                    <option value="SY">Syria</option>
                                    <option value="TJ">Tajikistan</option>
                                    <option value="TZ">Tanzania</option>
                                    <option value="TH">Thailand</option>
                                    <option value="TL">Timor-Leste</option>
                                    <option value="TG">Togo</option>
                                    <option value="TO">Tonga</option>
                                    <option value="TT">
                                      Trinidad and Tobago
                                    </option>
                                    <option value="TN">Tunisia</option>
                                    <option value="TR">Turkey</option>
                                    <option value="TM">Turkmenistan</option>
                                    <option value="TV">Tuvalu</option>
                                    <option value="UG">Uganda</option>
                                    <option value="UA">Ukraine</option>
                                    <option value="AE">
                                      United Arab Emirates
                                    </option>
                                    <option value="GB">United Kingdom</option>
                                    <option value="US">
                                      United States of America
                                    </option>
                                    <option value="UY">Uruguay</option>
                                    <option value="UZ">Uzbekistan</option>
                                    <option value="UV">Vanuatu</option>
                                    <option value="VE">Venezuela</option>
                                    <option value="VN">Vietnam</option>
                                    <option value="YE">Yemen</option>
                                    <option value="ZM">Zambia</option>
                                    <option value="ZW">Zimbabwe</option>
                                    <option value="UN">Unkown</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-12 col-xl-12 align-self-lg-end text-md-right mt-3">
                              <button
                                type="submit"
                                className="btn btn-primary w-100 border-radius-3 mb-xl-0 mb-lg-1 transition-3d-hover"
                                onClick={() => navigate("#loader")}
                              >
                                {" "}
                                <FontAwesomeIcon
                                  icon={faMagnifyingGlass}
                                  className="mr-2"
                                />
                                Search
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    )
                  )}
                </div>
                <div
                  className="rlr-product-filters__filters-wrapper mb-5"
                  style={{ borderRadius: ".25rem" }}
                >
                  <div className="rlr-product-filters__filter">
                    <div className="d-flex align-items-center pb-2">
                      <i
                        className="rlr-icon-font flaticon-star"
                        style={{ color: "#ef0000" }}
                      >
                        {" "}
                      </i>
                      <label
                        className="rlr-form-label rlr-form-label-- rlr-product-filters_label rlr-section_title--main"
                        style={{
                          marginBottom: "0",
                          paddingLeft: "10px",
                          fontSize: "22px",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        Category{" "}
                      </label>
                    </div>
                    <ul className="rlr-checkboxes">
                      <li
                        className="form-check form-check-block"
                        onClick={() => navigate("#loader")}
                      >
                        <input
                          className="form-check-input rlr-form-check-input rlr-product-filters__checkbox optionChk2"
                          type="checkbox"
                          id="chk_5Stars"
                          value="5 Stars"
                          checked={chk5Stars}
                          onChange={(e) => setChk5Stars(e.target.checked)}
                        />
                        <label
                          className="rlr-form-label rlr-form-label--checkbox rlr-product-filters__checkbox-label"
                          htmlFor="chk_5Stars"
                          style={{ fontSize: "14px" }}
                        >
                          <div
                            className="rlr-review-stars"
                            itemProp="ratingValue"
                            itemScope=""
                            itemType="https://schema.org/Product"
                          >
                            <i className="rlr-icon-font flaticon-star-1"> </i>{" "}
                            <i className="rlr-icon-font flaticon-star-1"> </i>{" "}
                            <i className="rlr-icon-font flaticon-star-1"> </i>{" "}
                            <i className="rlr-icon-font flaticon-star-1"> </i>{" "}
                            <i className="rlr-icon-font flaticon-star-1"> </i>
                          </div>
                        </label>
                      </li>
                      <li
                        className="form-check form-check-block"
                        onClick={() => navigate("#loader")}
                      >
                        <input
                          className="form-check-input rlr-form-check-input rlr-product-filters__checkbox optionChk2"
                          type="checkbox"
                          id="chk_4Stars"
                          value="4 Stars"
                          checked={chk4Stars}
                          onChange={(e) => setChk4Stars(e.target.checked)}
                        />
                        <label
                          className="rlr-form-label rlr-form-label--checkbox rlr-product-filters__checkbox-label"
                          htmlFor="chk_4Stars"
                          style={{ fontSize: "14px" }}
                        >
                          <div
                            className="rlr-review-stars"
                            itemProp="ratingValue"
                            itemScope=""
                            itemType="https://schema.org/Product"
                          >
                            <i className="rlr-icon-font flaticon-star-1"> </i>{" "}
                            <i className="rlr-icon-font flaticon-star-1"> </i>{" "}
                            <i className="rlr-icon-font flaticon-star-1"> </i>{" "}
                            <i className="rlr-icon-font flaticon-star-1"> </i>
                          </div>
                        </label>
                      </li>
                      <li
                        className="form-check form-check-block"
                        onClick={() => navigate("#loader")}
                      >
                        <input
                          className="form-check-input rlr-form-check-input rlr-product-filters__checkbox optionChk2"
                          type="checkbox"
                          id="chk_3Stars"
                          value="3 Stars"
                          checked={chk3Stars}
                          onChange={(e) => setChk3Stars(e.target.checked)}
                        />
                        <label
                          className="rlr-form-label rlr-form-label--checkbox rlr-product-filters__checkbox-label"
                          htmlFor="chk_3Stars"
                          style={{ fontSize: "14px" }}
                        >
                          <div
                            className="rlr-review-stars"
                            itemProp="ratingValue"
                            itemScope=""
                            itemType="https://schema.org/Product"
                          >
                            <i className="rlr-icon-font flaticon-star-1"> </i>{" "}
                            <i className="rlr-icon-font flaticon-star-1"> </i>{" "}
                            <i className="rlr-icon-font flaticon-star-1"> </i>
                          </div>
                        </label>
                      </li>
                      <li
                        className="form-check form-check-block"
                        onClick={() => {
                          navigate("#loader");
                        }}
                      >
                        <input
                          className="form-check-input rlr-form-check-input rlr-product-filters__checkbox optionChk2"
                          id="rlr-filter-duration-1ProductType 1"
                          type="checkbox"
                          name="IN_ProductTypeIDs"
                          value="1"
                          checked={other}
                          onChange={(e) => setOther(e.target.checked)}
                        />
                        <label
                          className="rlr-form-label rlr-form-label--checkbox rlr-product-filters__checkbox-label"
                          htmlFor="rlr-filter-duration-1ProductType 1"
                          style={{ fontSize: "14px" }}
                        >
                          Others
                        </label>
                      </li>
                    </ul>
                  </div>

                  <div className="rlr-product-filters__filter ">
                    <div className="d-flex align-items-center pb-2">
                      <i
                        className="rlr-icon-font rlr-icon-font flaticon-right text-primary"
                        style={{ color: "#ef0000" }}
                      >
                        {" "}
                      </i>
                      <label
                        className="rlr-form-label rlr-form-label-- rlr-product-filters_label rlr-section_title--main"
                        style={{
                          marginBottom: "0",
                          paddingLeft: "10px",
                          fontSize: "22px",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        Board{" "}
                      </label>
                    </div>
                    <ul className="rlr-checkboxes">
                      <li
                        className="form-check form-check-block"
                        onClick={() => navigate("#loader")}
                      >
                        <input
                          className="form-check-input rlr-form-check-input rlr-product-filters__checkbox optionChk22"
                          id="rlr-filter-duration-1GroupSize 14"
                          type="checkbox"
                          name="AllInclusive"
                          value="All Inclusive"
                          checked={AllInclusive}
                          onChange={(e) => setAllInclusive(e.target.checked)}
                        />
                        <label
                          className="rlr-form-label rlr-form-label--checkbox rlr-product-filters__checkbox-label"
                          htmlFor="rlr-filter-duration-1GroupSize 14"
                          style={{ fontSize: "14px" }}
                        >
                          All Inclusive Plus
                        </label>
                      </li>

                      <li
                        className="form-check form-check-block"
                        onClick={() => navigate("#loader")}
                      >
                        <input
                          className="form-check-input rlr-form-check-input rlr-product-filters__checkbox optionChk22"
                          id="rlr-filter-duration-1GroupSize 15"
                          type="checkbox"
                          name="BedAndBreakfast"
                          value="Bed & Breakfast"
                          checked={BedAndBreakfast}
                          onChange={(e) => setBedAndBreakfast(e.target.checked)}
                        />
                        <label
                          className="rlr-form-label rlr-form-label--checkbox rlr-product-filters__checkbox-label"
                          htmlFor="rlr-filter-duration-1GroupSize 15"
                          style={{ fontSize: "14px" }}
                        >
                          {" "}
                          Bed and Breakfast{" "}
                        </label>
                      </li>
                      <li
                        className="form-check form-check-block"
                        onClick={() => navigate("#loader")}
                      >
                        <input
                          className="form-check-input rlr-form-check-input rlr-product-filters__checkbox optionChk22"
                          id="rlr-filter-duration-1GroupSize 16"
                          type="checkbox"
                          name="fullboard"
                          value="full-board"
                          checked={fullboard}
                          onChange={(e) => setFullboard(e.target.checked)}
                        />
                        <label
                          className="rlr-form-label rlr-form-label--checkbox rlr-product-filters__checkbox-label"
                          htmlFor="rlr-filter-duration-1GroupSize 16"
                          style={{ fontSize: "14px" }}
                        >
                          {" "}
                          Full Board{" "}
                        </label>
                      </li>
                      <li
                        className="form-check form-check-block"
                        onClick={() => navigate("#loader")}
                      >
                        <input
                          className="form-check-input rlr-form-check-input rlr-product-filters__checkbox optionChk22"
                          id="rlr-filter-duration-1GroupSize 17"
                          type="checkbox"
                          name="halfboard"
                          value="half-board"
                          checked={halfboard}
                          onChange={(e) => setHalfboard(e.target.checked)}
                        />
                        <label
                          className="rlr-form-label rlr-form-label--checkbox rlr-product-filters__checkbox-label"
                          htmlFor="rlr-filter-duration-1GroupSize 17"
                          style={{ fontSize: "14px" }}
                        >
                          {" "}
                          Half Board{" "}
                        </label>
                      </li>
                      <li
                        className="form-check form-check-block"
                        onClick={() => navigate("#loader")}
                      >
                        <input
                          className="form-check-input rlr-form-check-input rlr-product-filters__checkbox optionChk22"
                          id="rlr-filter-duration-1GroupSize 18"
                          type="checkbox"
                          name="RoomOnly"
                          value="Room Only"
                          checked={RoomOnly}
                          onChange={(e) => setRoomOnly(e.target.checked)}
                        />
                        <label
                          className="rlr-form-label rlr-form-label--checkbox rlr-product-filters__checkbox-label"
                          htmlFor="rlr-filter-duration-1GroupSize 18"
                          style={{ fontSize: "14px" }}
                        >
                          {" "}
                          Room Only{" "}
                        </label>
                      </li>
                      <li
                        className="form-check form-check-block"
                        onClick={() => navigate("#loader")}
                      >
                        <input
                          className="form-check-input rlr-form-check-input rlr-product-filters__checkbox optionChk22"
                          id="nomeal"
                          type="checkbox"
                          name="nomeal"
                          value="nomeal"
                          checked={nomeal}
                          onChange={(e) => setNomeal(e.target.checked)}
                        />
                        <label
                          className="rlr-form-label rlr-form-label--checkbox rlr-product-filters__checkbox-label"
                          htmlFor="nomeal"
                          style={{ fontSize: "14px" }}
                        >
                          {" "}
                          No meal
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </aside>
              <div className="cruises list col-12 col-lg-8" id="loader">
                {loading ? (
                  <LoaderHotels />
                ) : (
                  <>
                    {filteredItems?.length > 0 && (
                      <h5 style={{ textAlign: "center" }}>
                        Search Result : {filteredItems?.length}
                      </h5>
                    )}
                    {filteredItems?.length > 0 ? (
                      filteredItems?.map((hotel, index) => (
                        <div
                          id="hotelList"
                          style={{ paddingLeft: "15px;" }}
                          key={index}
                        >
                          <Suspense fallback={<div>Loading...</div>}>
                            <div className="card border-color-7 my-5 overflow-hidden ">
                              <div className="product-item__outer w-100">
                                <div className="row">
                                  <article
                                    className="rlr-product-card rlr-product-card--v3"
                                    itemscope=""
                                    itemType="https://schema.org/Product"
                                  >
                                    {" "}
                                    <figure className="rlr-product-card__image-wrapper col-4 h-xl-100">
                                      <div className="rlr-js-product-multi-image-swiper h-xl-100">
                                        <img
                                          className="lazyautosizes ls-is-cached lazyloaded h-xl-100"
                                          alt={hotel.HotelName}
                                          src={hotel.var_HotelPhoto}
                                          placeholder={hotel.var_HotelPhoto}
                                          loading="lazy"
                                        />
                                      </div>
                                      {/* <Swiper
                        spaceBetween={0}
                        slidesPerView={1}
                        loop={false}
                        className="rlr-js-product-multi-image-swiper h-xl-100"
                      >
                        <SwiperSlide  >
                          <img
                          
                            className="lazyautosizes ls-is-cached lazyloaded h-xl-100"
                            alt="Happy Life Dahab"
                            src="http://www.touringon.com/images/upload/hotels/Happy_Life_Dahab/242_2.jpg"
                          />
                        </SwiperSlide>
                        
                      </Swiper> */}
                                    </figure>
                                    <div className="rlr-product-card__detail-wrapper rlr-js-detail-wrapper col-7">
                                      {" "}
                                      <header className="rlr-product-card__header">
                                        <div>
                                          <div className="d-flex align-items-center mt-3 mb-2">
                                            <div
                                              className="rlr-product-card__ratings"
                                              itemProp="aggregateRating"
                                              itemScope
                                              itemType="https://schema.org/AggregateRating"
                                            >
                                              <div
                                                className="rlr-review-stars"
                                                itemProp="ratingValue"
                                                itemScope
                                                itemType="https://schema.org/Product"
                                              >
                                                {renderStars(
                                                  parseInt(
                                                    hotel.var_HotelCategory
                                                  )
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          <Link
                                            className="rlr-product-card__anchor-title"
                                            to="#."
                                          >
                                            <h2
                                              className="rlr-product-card__title"
                                              itemProp="name"
                                            >
                                              {hotel.HotelName}
                                            </h2>
                                          </Link>
                                          <div className="d-flex">
                                            <span className="text-secondary d-flex me-2">
                                              <i className="rlr-icon-font flaticon-map-marker me-1">
                                                {" "}
                                              </i>
                                              <p>{hotel.var_HotelAddress}</p>
                                            </span>
                                          </div>
                                        </div>
                                      </header>
                                      <div className="rlr-product-card__footer">
                                        {hotel.var_HotelDescription}
                                      </div>
                                    </div>
                                    <div className="col-2 book-side">
                                      <div className="text-center  booking">
                                        <div className="mb-2 pb-1">
                                          {hotel.var_HotelStartingFrom && (
                                            <>
                                              <span className="font-size-13">
                                                From
                                              </span>
                                              <br />

                                              <span className="font-weight-bold font-size-22 ml-1">
                                                {
                                                  hotelsAvailability
                                                    .CurrencyDetails
                                                    ?.CurrencySign
                                                }{" "}
                                                {hotel.var_HotelStartingFrom}
                                              </span>
                                            </>
                                          )}

                                          <h2 className="accordion-header mt-5">
                                            <button
                                              className="btn-primary accordion-button collapsed"
                                              type="button"
                                              data-bs-toggle="collapse"
                                              data-bs-target={`#collapse_${index}`}
                                              aria-expanded="false"
                                              aria-controls={`collapse_${index}`}
                                            >
                                              Rooms
                                            </button>
                                          </h2>
                                        </div>
                                      </div>
                                    </div>
                                  </article>
                                  <div className="collaps">
                                    <div className="accordion-item">
                                      <div
                                        id={`collapse_${index}`}
                                        className="accordion-collapse collapse"
                                        data-bs-parent="#accordionExample"
                                      >
                                        {hotel.List_RateCodes?.map(
                                          (rooms, i) => (
                                            <div
                                              className="accordion-body p-0"
                                              key={i}
                                            >
                                              <div
                                                className="px-4 py-2 d-flex flex-column flex-lg-row justify-content-between align-items-center"
                                                style={{
                                                  boxShadow: "0 0 5px #ccc",
                                                  backgroundColor:
                                                    i % 2 === 0
                                                      ? "#f5f5f5"
                                                      : "#fff",
                                                }}
                                              >
                                                <div className="rooms-p">
                                                  <div className="mb-2">
                                                    {rooms.List_Rooms.map(
                                                      (r) => (
                                                        <p
                                                          style={{
                                                            paddingBottom:
                                                              "10px",
                                                            paddingTop: "10px",
                                                          }}
                                                        >
                                                          <b>{r.RoomName}</b> -{" "}
                                                          <small>
                                                            {
                                                              rooms.var_BoardName
                                                            }
                                                          </small>
                                                        </p>
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="d-flex flex-column justify-content-between">
                                                  {rooms.var_RateNet && (
                                                    <span className="rlr-product-card__price">
                                                      {rooms.var_RateNet}{" "}
                                                      {
                                                        hotelsAvailability
                                                          .CurrencyDetails
                                                          ?.CurrencySign
                                                      }
                                                    </span>
                                                  )}
                                                  <Link
                                                    className="btn btn-secondary-2 text-light text-decoration-none"
                                                    onClick={() =>
                                                      localStorage.setItem(
                                                        "RatePlanCode",
                                                        rooms.RatePlanCode
                                                      )
                                                    }
                                                    to={`/hotels-availability/${encodeURIComponent(
                                                      hotel.HotelName
                                                    )}/${encodeURIComponent(
                                                      hotel.var_HotelAddress
                                                    )}/${
                                                      hotel.var_HotelJPCode
                                                    }`}
                                                  >
                                                    Book Now
                                                  </Link>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Suspense>
                        </div>
                      ))
                    ) : (
                      <p className="SiteFontP text-center my-5">
                        Sorry, there are currently no available hotels for the
                        selected destination and date. Please try selecting a
                        different date or destination
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HotelAvailability;
