import React, { Suspense, useEffect } from "react";

// import styles from "./css/terms-and-conditions.module.css";
import { Container } from "react-bootstrap";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { fetchTerms } from "../../data/terms-and-condition/termsSlice";

const TermsAndConditions = () => {
  const dispatch = useDispatch();
  const hotelUrl = localStorage.getItem("hotelUrl");
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const language = useSelector((state) => state.language.language);

  const { terms, loading, error } = useSelector((state) => state.terms);
  useEffect(() => {
    dispatch(fetchTerms(language));
  }, [dispatch, language]);
  return (
    // <>
    //   {loading ? (
    //     <Loader />
    //   ) : error ? (
    //     <section
    //       style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
    //     >
    //       <h1 className="text-center text-light">{error}</h1>
    //     </section>
    //   ) : (
    <>
      <Helmet>
        <title>{terms?.pageMetatagTitle || "website terms"}</title>
        <link
          rel="canonical"
          href={`https://travco.com/${language}/website-terms`}
        />
        <meta name="robots" content="index, follow" />
        <meta name="description" content={terms?.pageMetatagDescription} />
        <meta property="og:title" content={terms?.metatagTitleFacebook} />
        <meta
          property="og:description"
          content={terms?.metatagDescriptionFacebook}
        />
        <meta name="twitter:title" content={terms?.metatagTitleTwitter} />
        <meta
          name="twitter:description"
          content={terms?.metatagDescriptionTwitter}
        />
      </Helmet>
      <section>
        {" "}
        <div className="outter">
          <div
            id="rlr_banner_slider"
            className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
          >
            <div className="splide__track rlr-banner-splide__track">
              <ul className="splide__list height-internal-pages">
                <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                  <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                    <div className="overlay-banner"></div>
                    <Suspense fallback={<div>Loading...</div>}>
                      {" "}
                      <img
                        className="rlr-banner-splide__banner-img"
                        src={
                          isMobile
                            ? terms?.pageBannerMobile
                            : isTablet
                            ? terms?.pageBannerTablet
                            : terms?.pageBannerPc
                        }
                        alt={terms?.pageTitle}
                        loading="lazy"
                      />
                    </Suspense>
                  </div>
                  <article className="rlr-banner-splide__content-wrapper container">
                    <div className="row justify-content-start">
                      <div className="col-xl-9">
                        <div className="rlr-banner-splide__header">
                          <h2
                            className="rlr-banner-splide__slogan rlr-section__title--main"
                            style={{ textShadow: "1px 1px 2px #000" }}
                          >
                            {terms?.pageTitle}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </article>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="rlr-search-results-page__breadcrumb-section">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb rlr-breadcrumb__items">
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/">Home </Link>
              </li>
              <li
                className="breadcrumb-item rlr-breadcrumb__item active"
                aria-current="page"
              >
                {terms?.pageTitle}
              </li>
            </ol>
          </nav>
          <div className="rlr-icon-text">
            <i className="rlr-icon-font flaticon-phone-receiver-silhouette">
              {" "}
            </i>{" "}
            <Link
              to="tel:(+202) 3854 1010"
              className="rlr-search-results-page__phone"
            >
              Questions? (+202) 3854 1010{" "}
            </Link>
          </div>
        </div>
      </div>
      <section className="first-section   " id="terms">
        <Container>
          <div className="page_content_wrap scheme_default">
            <div
              className="content_wrap   "
              dangerouslySetInnerHTML={{
                __html: `${terms?.pageText}`,
              }}
            />
          </div>
        </Container>
      </section>
    </>
    //   )}
    // </>
  );
};
export default TermsAndConditions;
