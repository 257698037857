import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";

import { useMediaQuery } from "@mui/material";

import { fetchDestinationHandbookDetails } from "../../data/destination-handbook/destinationHandbookDetailsSlice";

const DestinationHandbookDetails = () => {
  const { itemUrl } = useParams();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");

  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const { destinationHandbookDetails } = useSelector(
    (state) => state.destinationHandbookDetails
  );

  useEffect(() => {
    dispatch(fetchDestinationHandbookDetails({ itemUrl, language }));
  }, [dispatch, itemUrl, language]);
  return (
    <>
      <Helmet>
        <title>
          {destinationHandbookDetails.pageDetails?.pageMetatagTitle}
        </title>
        <link
          rel="canonical"
          href={`https://travco.com/${language}/destination-handbook/${itemUrl}`}
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content={
            destinationHandbookDetails.pageDetails?.pageMetatagDescription
          }
        />
        <meta
          property="og:title"
          content={destinationHandbookDetails.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={
            destinationHandbookDetails.pageDetails?.metatagDescriptionFacebook
          }
        />
        <meta
          name="twitter:title"
          content={destinationHandbookDetails.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={
            destinationHandbookDetails.pageDetails?.metatagDescriptionTwitter
          }
        />
      </Helmet>{" "}
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>Loading...</div>}>
                    {" "}
                    <img
                      src={
                        isMobile
                          ? destinationHandbookDetails.pageDetails
                              ?.pageBannerMobile
                          : isTablet
                          ? destinationHandbookDetails.pageDetails
                              ?.pageBannerTablet
                          : destinationHandbookDetails.pageDetails?.pageBannerPc
                      }
                      alt={destinationHandbookDetails.pageDetails?.pageTitle}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className="rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {destinationHandbookDetails.pageDetails?.pageTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="rlr-search-results-page__breadcrumb-section">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb rlr-breadcrumb__items">
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/">Home </Link>
              </li>
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/destination-handbook">Destinations HandBook </Link>
              </li>
              <li
                className="breadcrumb-item rlr-breadcrumb__item active"
                aria-current="page"
              >
                {destinationHandbookDetails.pageDetails?.pageTitle}
              </li>
            </ol>
          </nav>
          <div className="rlr-icon-text">
            <i className="rlr-icon-font flaticon-phone-receiver-silhouette">
              {" "}
            </i>{" "}
            <Link
              to="tel:(+202) 3854 1010"
              className="rlr-search-results-page__phone"
            >
              Questions? (+202) 3854 1010{" "}
            </Link>
          </div>
        </div>
      </div>
      <section className="blog-details-section pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7">
              <div className="blog-details-wrapper">
                <div className="blog-post mb-60 wow fadeInUp">
                  <div className="post-thumbnail">
                    <img
                      src={destinationHandbookDetails.blogDetails?.blogPhoto}
                      alt={destinationHandbookDetails.blogDetails?.blogTitle}
                    />
                  </div>
                  <div className="post-meta">
                    <ul>
                      <li>
                        <span>
                          <Link to="#.">
                            <i className="rlr-icon-font flaticon-calendar"></i>{" "}
                            {
                              destinationHandbookDetails.blogDetails
                                ?.blogDateTime
                            }
                          </Link>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="main-post">
                    <div className="entry-content">
                      <h3 className="title">
                        {destinationHandbookDetails.blogDetails?.blogTitle}
                      </h3>
                      <div
                        className="SiteFontP"
                        dangerouslySetInnerHTML={{
                          __html:
                            destinationHandbookDetails.blogDetails?.blogDetails,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="sidebar-widget-area pl-lg-40">
                <div className="sidebar-widget category-widget mb-30 wow fadeInUp">
                  <h5 className="widget-title">Other Posts </h5>
                  <ul className="category-nav">
                    {destinationHandbookDetails.otherBlogs?.map((post) => (
                      <li key={post.blogUrl}>
                        <Link to={`/destination-handbook/${post.blogUrl}`}>
                          {post.blogTitle}
                          <i className="far fa-arrow-right"></i>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="sidebar-widget recent-post-widget mb-40 wow fadeInUp">
                  <h5 className="widget-title">Recent News </h5>
                  <ul className="recent-post-list">
                    {destinationHandbookDetails.news?.map((news) => (
                      <li className="post-thumbnail-content" key={news.newsUrl}>
                        <img
                          src={news.newsPhoto}
                          alt={news.newsTitleSys}
                          loading="lazy"
                        />
                        <div className="post-title-date">
                          <h5>
                            <Link to={`/travco-news/${news.newsUrl}`}>
                              {news.newsTitleSys}
                            </Link>
                          </h5>
                          <span className="posted-on">
                            <i className="rlr-icon-font flaticon-calendar"></i>
                            <Link to={`/travco-news/${news.newsUrl}`}>
                              {news.newsDateTime}
                            </Link>
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DestinationHandbookDetails;
